import React, { useEffect, useState } from 'react';
import { collect } from 'react-recollect';
import Profile from './Profile';
import './TopNav.css';
import SideBar from './common/SideBar';
import { appStore } from '../store/store';
import { Button, Modal, ModalBody } from 'reactstrap';
import LoadingCircles from './LoadingCircles';

const TopNav = ({ store, getIsSideBarToggled }) => {
  const [isSideBarToggled, setIsSideBarToggled] = useState(false)
  const [showKioskRedirectModal, setShowKioskRedirectModal] = useState(false)
  const { npi_client, is_facility } = store.currentSubdomain
  const topNavStyle = { backgroundColor: npi_client ? 'var(--color-gray)' : is_facility ? 'var(--color-blue)' : 'var(--color-primary)' }
  const { clientWidth } = document.documentElement

  useEffect(() => {
    getIsSideBarToggled(isSideBarToggled)
  }, [isSideBarToggled])

  const onToggleClick = () => {
    setIsSideBarToggled(!isSideBarToggled)
  }

  const onKioskModeClick = () => {
    const locations = store.locationOptionGroups.map(loc => ({
      ...loc,
      options: loc.options.filter(opt => !opt.isHidden)
    }))
    const stringifiedLocations = JSON.stringify(locations)
    localStorage.setItem('locationOptionGroups', stringifiedLocations)
    setShowKioskRedirectModal(true)
    setTimeout(() => {
      redirectToKioskMode()
    }, 6500);
  }

  const redirectToKioskMode = async () => {
    if (localStorage?.locationOptionGroups) {
      await appStore.logout()
      window.history.replaceState(null, '', '/'); // Clear browser history
      window.location.href = '/kiosk' // Then redirect to kiosk
    }
    setShowKioskRedirectModal(false)
  }

  return (
    <div>
      <SideBar />
      <div id="navbar-wrapper">
        <nav className='navbar navbar-container py-1 px-2' style={topNavStyle}>
          <div className="container-fluid sg-navbar p-0">
            <div className="navbar-header">
              <button
                className="navbar-brand btn mr-1"
                id="sidebar-toggle"
                style={{ color: '#fff' }}
                onClick={onToggleClick}
              >
                <i className="fa fa-bars"></i>
              </button>
              {store.settings.kioskModeEnabled && (
                <Button
                  title='Kiosk Mode'
                  className='text-white ml-sm-3 ml-2'
                  color='lightblue'
                  size='sm'
                  onClick={onKioskModeClick}
                  style={{ padding: clientWidth < 550 ? '0.25rem 0.5rem' : '0.5rem 1rem' }}
                >
                  <i className='fas fa-tablet' />
                  {clientWidth > 500 ? ' Kiosk Mode' : null}
                </Button>
              )}
            </div>
            {!!store.currentUser && (
              <form className='form-inline'>
                <Profile />
              </form>
            )}
          </div>
        </nav>
      </div>
      <Modal
        size='custom-md'
        isOpen={showKioskRedirectModal}
        toggle={() => setShowKioskRedirectModal(!showKioskRedirectModal)}
        centered
      >
        <ModalBody>
          <h6>
            <div className='d-flex justify-content-between'>
              <b>Attention!</b>
              <LoadingCircles />
            </div>
            You are being redirected to kiosk mode and logged out of this session <br /> to ensure HIPAA compliance and protect patient privacy.
          </h6>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default collect(TopNav);
