import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { collect } from "react-recollect";
import { Link } from "react-router-dom";
import { appStore } from "../../store/store";
import { getLangType, subdomain } from "../../utils/utils";
import { languageOptions } from "../../language";
import "./sidebar.scss";
import { Badge } from "reactstrap";

const langType = getLangType();

export const adminOrStaging = subdomain === 'admin' || window.location.hostname.includes('sleepgladstaging');

const SideBar = ({ store, location }) => {
  const [activeRoute, setActiveRoute] = useState("");
  const SleepGlad3dLogo = "/rest/logo"
  const SleepGladLogoPRN = "/rest/logo?npi=true"

  useEffect(() => {
    if (activeRoute !== location?.pathname) {
      setActiveRoute(location?.pathname);
    }
  }, [location?.pathname]);

  const NAV_LINK = [
    {
      name: 'Patient List',
      link: "/dashboard",
      disabled: false,
      icon: "fa fa-home",
    },
    {
      name: 'Patient Alerts',
      link: "/alerts",
      disabled: !store.settings.patientAlertsEnabled,
      icon: "fa fa-exclamation-triangle",
    },
    {
      name: 'Patient Messages',
      link: "/patientMessages",
      disabled: !store.settings.patientMessagingEnabled,
      icon: "fa fa-comment-dots",
    },
    {
      name: languageOptions.analytics[langType],
      link: "/analytics",
      disabled: !store.currentUser.canSeeAnalytics,
      icon: "fa fa-chart-line",
    },
    {
      name: languageOptions.settingsAndFormulary[langType],
      link: "/settings",
      disabled: appStore.notAuthorizedForSettingPage(),
      icon: "fa fa-cogs",
    },
    {
      name: languageOptions.manageUsers[langType],
      link: "/settings/users",
      disabled: !store.currentUser.canManageUsers,
      icon: "fa fa-users",
    },
    {
      name: "My Profile",
      link: `/profile/${store.currentUser.username}`,
      disabled: false,
      icon: "fa fa-user-alt",
    },
    {
      name: languageOptions.knowledgeBase[langType],
      link: "/help",
      disabled: false,
      icon: "fa fa-question-circle",
    },
    {
      name: 'Importer',
      link: "/patient-importer",
      disabled: !store.currentUser.canManageImporter,
      icon: "fa fa-file-import",
    },
    {
      name: languageOptions.downloads[langType],
      link: "/downloads",
      icon: "fa fa-download",
    }
  ];

  if (appStore.authorizedForAdhocPage()) {
    NAV_LINK.push({
      name: languageOptions.adHoc[langType],
      link: "/ad-hoc",
      disabled: !appStore.authorizedForAdhocPage(),
      icon: "fa fa-database",
    })
  }

  if (store.currentUser.isStaff && adminOrStaging) {
    NAV_LINK.push({
      name: 'Client Onboarding',
      link: "/client-onboarding",
      icon: 'fa fa-user-plus',
    })
  }

  if (store.currentUser.isStaff && store.currentSubdomain?.customerSubdomain === 'admin') {
    NAV_LINK.push({
      name: languageOptions.admin[langType],
      link: "/billing",
      disabled: !appStore.authorizedForAdmin(),
      icon: "fa fa-dollar-sign"
    })
  }


  const shouldShowAnalyticsNotifications = () => {
    let count = 0;
    if (store.notificationInfo.magneticRefitCount > 0 && !appStore.getHasUserViewedMagnetNotifications()) {
      count += store.notificationInfo.magneticRefitCount;
    }
    if (store.notificationInfo.surveyCompletionCount > 0 && !appStore.getHasUserViewedSurveyNotifications()) {
      count += store.notificationInfo.surveyCompletionCount;
    }
    return count > 0;
  }

  const notificationCount = appStore.getKnowledgeBasePostNotificationCount();

  useEffect(() => {
    appStore.getNotificationInfo()

    const timer = setInterval(() => {
      appStore.getNotificationInfo('knowledgeBase')

    }, 1000 * 60 * 60 * 2);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (store.currentUser) appStore.getFormulary();
  }, [store.currentUser])

  const { npi_client } = store.currentSubdomain

  return (
    <aside id="sidebar-wrapper">
      <div className="sidebar-brand">
        <a className="navbar-brand" href="/dashboard">
          <img
            src={npi_client ? SleepGladLogoPRN : SleepGlad3dLogo}
            height={npi_client ? "62" : "80"}
            className="d-inline-block align-top logo-img"
            alt="sleepglad brand logo"
            style={{ borderRadius: "6px" }}
          />
        </a>
      </div>
      <div className="sg-sidebar">
        <ul className="sidebar-nav">
          {NAV_LINK.map((data, i) => (
            <li className={activeRoute === data.link ? "active" : ""} key={data.link + i}>
              <Link to={!data?.disabled ? data.link : "#"} disabled={data?.disabled}>
                <i className={data?.icon} />
                {data?.name}
                {data?.link === "/help" && notificationCount > 0 && (
                  <Badge pill color="danger" style={{ fontWeight: 'normal', display: 'inline', position: 'absolute', marginTop: '4px', marginLeft: '-1px', textIndent: '0' }}>
                    {notificationCount}
                  </Badge>
                )}
                {data?.link === "/analytics" && shouldShowAnalyticsNotifications() && (
                  <Badge pill color="danger" style={{ fontWeight: 'normal', marginLeft: '3px', display: 'inline' }}>
                    {store.notificationInfo.magneticRefitCount + store.notificationInfo.surveyCompletionCount}
                  </Badge>
                )}
                {data?.link === "/dashboard" && store?.patientNotificationCount > 0 && (
                  <Badge pill color="danger" style={{ fontWeight: 'normal', marginLeft: '3px', display: 'inline' }}>
                    {store?.patientNotificationCount}
                  </Badge>
                )}
                {data?.link === "/alerts" && store.notificationInfo.unreadAlerts > 0 && store.settings.patientAlertsEnabled && (
                  <Badge pill color="danger" style={{ fontWeight: 'normal', display: 'inline', position: 'absolute', marginTop: '4px', marginLeft: '-1px', textIndent: '0' }}>
                    {store.notificationInfo.unreadAlerts}
                  </Badge>
                )}
                {data?.link === "/patientMessages" && store.notificationInfo.unreadPtMessagesCount > 0 && store.settings.patientMessagingEnabled && (
                  <Badge pill color="danger" style={{ fontWeight: 'normal', marginLeft: '3px', display: 'inline' }}>
                    {store.notificationInfo.unreadPtMessagesCount}
                  </Badge>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="sidebar-footer">
        <div className="version-info">
          <span className="d-block">Version {localStorage.currentVersion}</span>
        </div>
      </div>
    </aside>
  );
};

const sideBarExport = collect(SideBar);
export default withRouter(sideBarExport);
