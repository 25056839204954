/* eslint-disable */
import { collect, store } from 'react-recollect';
import React, { Component } from 'react';
import {
  Button, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Label, Input, Card, CardHeader, CardBody, FormFeedback, Progress
} from 'reactstrap';
import Select from "react-select";
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { afterChange } from 'react-recollect';
import { debounce } from 'lodash';
import ReferringPhysicianLookup from './ReferringPhysicianLookup.js';
import {
  displayFormError, requiredLabel, getLangType, maskIsMagnetic, validateEmail, validatePatientID, formatDateToString, formatDate, validNumberInput
} from '../utils/utils';
import { languageOptions } from '../language';
import { appStore } from '../store/store';
import ToolTip from './ToolTip.js';
import './PatientsTable.css'
import CustomDatePicker from './CustomDatePicker.js';
import ScriptedMaskForm from './ScriptedMaskForm';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PhoneNumberInput from './PhoneNumberInput.js'
import dayjs from 'dayjs';

const masksByName = appStore.getMasksByName();

export function getMaskOptionGroups(allowed_mfrs) {
  const optionsFullFace = [];
  const optionsPillow = [];
  const optionsNasal = [];
  for (const maskName in masksByName) {
    const mask = masksByName[maskName];
    const item = {
      value: mask.name,
      label: appStore.displayNameForMask(mask.name),
    };
    // only include approved OEM options
    if (allowed_mfrs) {
      if (!Object.values(allowed_mfrs).includes(mask.company)) continue;
    }
    if (mask.mask_category == 'Full Face') {
      optionsFullFace.push(item);
    } else if (mask.mask_category == 'Nasal Pillow') {
      optionsPillow.push(item);
    } else {
      optionsNasal.push(item);
    }
  }
  return [{
    label: 'Full Face',
    options: optionsFullFace,
  }, {
    label: 'Nasal',
    options: optionsNasal,
  }, {
    label: 'Pillow',
    options: optionsPillow,
  },
  ];
}

export function getMaskOptionGroupsWithOther() {
  let maskOptionGroups = getMaskOptionGroups();
  maskOptionGroups.push({
    label: 'Other',
    options: [{
      value: 'Other',
      label: 'Other',
    }]
  })
  return maskOptionGroups;
}

export const numberErrorEnum = {
  success: 0,
  invalidNum: 1,
  notMobileNum: 2,
  emptyNum: 3,
}

const initErrors = {
  id: false,
  email: false,
  number: 0,
  notes: false,
  brands: false,
  firstName: false,
  lastName: false,
  location: false,
  birthDate: false,
  duplicateId: false
}

class NewPatientForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      refPhysModal: false,
      brands: this.getBrandOptions(),
      errors: initErrors,
      showAddPtModal: false,
      confirmationModal: false,
      showFormularyWarningModal: false,
      allowDuplicate: false,
      shouldSendInvite: this.props.shouldSendInvite,
      inviteChannel: this.props.shouldSendInvite ? 'text' : 'none',
      isRecall: false,
      isPtIDEmpty: false,
      selectedMasksVariety: [],
      phoneInputCountry: 'US',
      isValidPhoneNumber: false,
      pressureOption: [],
      selectedOption: null,
      selectedPressure: null,
      showMissingNPIWarning: false
    };
    this.maskOptionGroupsWithOther = getMaskOptionGroupsWithOther();
    this.debounceCheckPatientId = debounce(this.checkExistingPatientId, 1000);
    this.debouncedPhoneInputChange = debounce(this.debouncedPhoneInputChange, 1200);
    // If client has no child subdomains and has a single location, set that as the default location
    this.defaultLocationOption = this.isOnlyOneLocation() &&
      store.locationOptionGroups.map(loc => ({
        ...loc,
        options: loc.options.filter(opt => !opt.isHidden)
      }))[0].options
    this.msgDeliveryOptions = [
      { label: 'Send Text Message (Recommended)', value: 'text' },
      { label: 'Send Email', value: 'email' },
      { label: 'In-Person Mode', value: 'hold' },
    ]
    this.ptLanguageOptions = [
      { label: 'English (en-US)', value: 'en' },
      { label: 'Español (Spanish)', value: 'es' },
      { label: 'Português (Portuguese)', value: 'pt' }
    ]
  }

  getBrandOptions = () => {
    const selectedFormulary = this.props.store.selectedFormularyName ? this.props.store.selectedFormularyName : appStore.getDefaultFormulary();
    const availableMasks = this.props.store.formularies[selectedFormulary].enabled_masks;
    const brandOptions = {};
    for (const maskName of availableMasks) {
      const mask = masksByName[maskName];
      const maskCompany = mask?.company;
      const maskCategory = mask?.mask_category;
      brandOptions[maskCompany] = {
        label: mask?.mfr_display_name,
        value: maskCompany
      };
      brandOptions[maskCategory] = {
        label: maskCategory,
        value: maskCategory
      };
    }
    return Object.values(brandOptions);
  };

  componentDidMount() {
    afterChange((e) => {
      if (e.changedProps.includes('newPatient.id') || e.changedProps.includes('newPatient.subdomain')) {
        this.debounceCheckPatientId()
      }
    });
  }

  filterByMaskType = filters => {
    const oldSelectedMasks = this.props.store.newPatient.formularyMaskList;
    const { value, isMaskFilter, addTo } = filters;
    let newSelectedMasks = [];
    const formularyName = appStore.getDefaultFormulary();
    const initialFormulary = this.props.store.formularies[formularyName].enabled_masks

    if (!addTo) {
      for (const maskName of oldSelectedMasks) {
        const mask = masksByName[maskName];
        if (isMaskFilter) {
          if (mask.mask_category != value) {
            newSelectedMasks.push(maskName);
          }
        } else if (!isMaskFilter) {
          if (mask.company != value) {
            newSelectedMasks.push(maskName);
          }
        }
      }
    }

    else if (addTo && isMaskFilter) {
      initialFormulary.forEach((mask) => {
        if (masksByName[mask].mask_category === value) newSelectedMasks.push(mask)
      });
      newSelectedMasks = newSelectedMasks.concat(oldSelectedMasks);
    } else if (addTo && !isMaskFilter) {
      initialFormulary.forEach((mask) => {
        if (masksByName[mask].company === value) newSelectedMasks.push(mask)
      });
      newSelectedMasks = newSelectedMasks.concat(oldSelectedMasks);
    }

    this.updateMaskList(newSelectedMasks);
  }

  setMaskListToFormulary(formularyName) {
    const selectedMasks = this.props.store.formularies[formularyName].enabled_masks;
    const brands = []
    selectedMasks.forEach((mask) => {
      if (!brands.find(b => b.value === masksByName[mask]?.company)) {
        brands.push({ value: masksByName[mask]?.company, label: masksByName[mask]?.mfr_display_name })
      }
      if (!brands.find(b => b.value === masksByName[mask]?.mask_category)) {
        brands.push({ value: masksByName[mask]?.mask_category, label: masksByName[mask]?.mask_category })
      }
    })
    this.setState({ brands: brands })
    this.updateMaskList(selectedMasks);
  }

  updateMaskList(selectedMasks) {
    var maskList = [];
    var maskSelectionList = [];

    for (const maskName of selectedMasks) {
      maskSelectionList.push({
        value: maskName,
        label: appStore.displayNameForMask(maskName),
      });

      maskList.push(maskName);
    }
    appStore.setNewPatient({ formularyMaskList: maskList })
    this.props.store.selectedMaskList = maskSelectionList;
    this.setState({ selectedMasksVariety: appStore.getSelectedMasksVariety(selectedMasks) });
  }

  hasSubdomains = arr => (arr && arr.length > 1) ? true : false;

  toggleRefPhysModal = () => {
    this.setState({
      refPhysModal: !this.state.refPhysModal,
    });
  };

  // * checkExistingPatientId checks entered patient ID is already exist or not
  checkExistingPatientId = () => {
    const newPatient = this.props.store.newPatient;

    if (newPatient?.id && newPatient?.subdomain) {
      appStore
        .checkExistingPatient(newPatient)
        .then((res) => {
          const errors = { ...this.state.errors };
          if (res?.data?.is_exist) {
            errors.duplicateId = true;
          } else {
            errors.duplicateId = false;
          }
          this.setState({ errors });
        })
        .catch((error) => {
          console.error("/rest/check_existing_patient failed with error: ", error);
        });
    }
  };

  resetErrors = () => {
    this.setState({
      errors: {
        id: false,
        email: false,
        number: 0,
        notes: false,
        brands: false,
        firstName: false,
        lastName: false,
        location: false,
        birthDate: false,
        duplicateId: false
      }
    });
  }

  toggleModal = () => {
    //moved the error reset to a separate function, wasn't working in the setState callback.
    this.resetErrors();
    this.setState({
      allowDuplicate: false,
      confirmationModal: false,
      showFormularyWarningModal: false,
      masksVarietyConfirmationModal: false,
      showAddPtModal: !this.state.showAddPtModal,
      isRecall: false,
      isValidPhoneNumber: false,
      phoneInputCountry: 'US',
      inviteChannel: 'text',
      selectedPressure: null,
      shouldSendInvite: this.props.shouldSendInvite
    });
    appStore.resetNewPatient(this.state.inviteChannel);
    appStore.getAvailableMaskOptionGroups();
    this.handleOneLocation()
    if (store.settings.sharedPatientsEnabled) {
      appStore.getLocationsSharedWith();
    }
  };

  toggleConfirmationModal = () => this.setState({ confirmationModal: !this.state.confirmationModal });

  toggleWarningModal = () => this.setState({ showFormularyWarningModal: !this.state.showFormularyWarningModal });

  toggleMissingNPIWarningModal = () => this.setState({ showMissingNPIWarning: !this.state.showMissingNPIWarning });

  // * doConfirm submit form on duplicate patient confirmation
  doConfirm = () => {
    this.setState({ allowDuplicate: true },
      () => this.submitNewPtForm()
    );
  }

  warningDoConfirm = () => {
    this.props.store.masksVarietyWarning = [];
    this.setState({ showFormularyWarningModal: false },
      () => this.submitNewPtForm()
    );
  }

  handleInviteSwitch = (shouldInvite) => {
    this.setState({ shouldSendInvite: shouldInvite })
    if (shouldInvite === false) {
      this.props.store.newPatient.inviteChannel = 'none'
    }
    else {
      this.props.store.newPatient.inviteChannel = this.state.inviteChannel
    }
  }

  handleIsRecallSwitch = () => {
    this.setState({ isRecall: !this.state.isRecall }, () => {
      this.props.store.newPatient.isRecall = this.state.isRecall;
    })
  }

  handleAssignedLocChange = async (e) => {
    // IMPORTANT: If a change is made here, consider having a similar change in `handleOneLocation` function, since it is run instead of this function in one location case, to automatically select that ONE location, instead of selecting it from dropdown which will run this function.
    this.props.store.newPatient.subdomain = e.value[0];
    this.props.store.newPatient.location = e.value[1];
    // Formulary and preferences
    await appStore.getSelectedBranchPreferences(e.value[0])
    appStore.setSelectedFormularyName(appStore.getDefaultFormulary());
    this.setMaskListToFormulary(this.props.store.selectedFormularyName);
    appStore.getAvailableMaskOptionGroups();
  }

  handleMaskType = (mask, isOther = false) => {
    if (isOther) {
      this.props.store.newPatient.prescribed_mask = 'Other';
      if (mask !== 'Other' && mask.length > 2 && mask.trim() !== '') {
        this.props.store.newPatient.prescribed_mask = 'Other';
        this.props.store.newPatient.prescribed_mask_other = mask;
        this.setState({ errors: { ...this.state.errors, prescribed_mask_other: false } });
        return
      }
      this.setState({ errors: { ...this.state.errors, prescribed_mask_other: true } });
    } else {
      this.props.store.newPatient.prescribed_mask = mask;
      this.setState({ errors: { ...this.state.errors, prescribed_mask_other: false } });
    }
  };

  handleCushionSize = (e) => {
    this.props.store.newPatient.prescribed_mask_cushion_size = e ? e.value : '';
  }

  handleFrameSize = (e) => {
    this.props.store.newPatient.prescribed_mask_frame_size = e ? e.value : '';
  }

  resetPrescription = () => {
    this.props.store.newPatient.prescribed_mask = ''
    this.props.store.newPatient.prescribed_mask_other = null
    this.props.store.newPatient.prescribed_mask_cushion_size = ''
    this.props.store.newPatient.prescribed_mask_frame_size = ''
  }

  handlePhoneInputChange = (value) => {
    // Code with no delay
    store.twilioLookupLoading = true
    store.newPatient.number = value ? value : ''
    // Call the debounced version
    this.debouncedPhoneInputChange(value)
  }

  debouncedPhoneInputChange = async (value) => {
    if (!value || !isPossiblePhoneNumber(value)) {
      this.setState((prevState) => ({
        errors: { ...prevState.errors, number: numberErrorEnum.invalidNum }
      }))
      // if delivery method is not text and phone input is removed, do not show error anymore
      if (
        store.newPatient.inviteChannel !== 'text' &&
        this.state.errors.number &&
        !value
      ) {
        this.setState((prevState) => ({
          errors: { ...prevState.errors, number: numberErrorEnum.success }
        }))
      }
    }
    else if (isPossiblePhoneNumber(value)) {
      const data = await appStore.twilioLookupPhone(store.newPatient.number)
      if (data) {
        const { numberLookupStatus, numberLookupCode, numberIsSMSReachable } = data
        if (numberLookupStatus == 200 && numberIsSMSReachable) {
          store.newPatient.isMobile = 'valid';
          this.setState((prevState) => ({
            errors: { ...prevState.errors, number: numberErrorEnum.success },
            isValidPhoneNumber: true
          }))
        } else if (numberLookupStatus != 200) {
          // Invalid number
          this.setState((prevState) => ({
            errors: { ...prevState.errors, number: numberErrorEnum.invalidNum }
          }))
        } else if (!numberIsSMSReachable) {
          // SMS unreachable number
          this.setState((prevState) => ({
            errors: { ...prevState.errors, number: numberErrorEnum.notMobileNum }
          }))
        }
      }
    }
    store.twilioLookupLoading = false
  }

  handleOneLocation = async () => {
    // set location, subdomain and formulary if only one branch without having to select on dropdown as it will already have that one location as default value. 
    if (this.isOnlyOneLocation() && this.defaultLocationOption?.length) {
      const [defaultSubdomain, defaultLocationId] = this.defaultLocationOption[0].value;
      store.newPatient.subdomain = defaultSubdomain;
      store.newPatient.location = defaultLocationId;
      await appStore.getSelectedBranchPreferences(defaultSubdomain)
      if (!store.newPatient.formularyMaskList?.length) {
        appStore.getFormulary(defaultSubdomain).then(() => {
          appStore.setSelectedFormularyName(this.props.store.selectedFormularyName);
          this.setMaskListToFormulary(this.props.store.selectedFormularyName);
          store.newPatient.formularyMaskList = this.props.store.selectedMaskList.
            map(maskObj => maskObj.value)
        })
      }
    };
  }

  getRequiredFields = () => {
    // Retrieve requiredFields and requiredResupplyfields for selected branch
    // const branchData = this.props.store.currentSubdomain?.branches.filter(branch => branch.subdomain === selectedBranch)[0] 
    const branchData = this.props.store.selectedBranchPreferences
    const requiredNewFields = branchData?.required_fields || []
    const newRequiredResupplyFields = branchData?.required_resupply_fields || []

    let requiredFields = []
    if (this.props.store.newPatient.isFirstTime) {
      requiredFields = requiredNewFields
    } else {
      requiredFields = newRequiredResupplyFields
    }

    return requiredFields;
  }

  submitNewPtForm = () => {
    let submit = true;
    const { newPatient } = this.props.store
    newPatient.shouldSendInvite = this.state.shouldSendInvite;
    newPatient.formularyName = this.props.store.selectedFormularyName;
    newPatient.masksPreferences = this.props.store.formularies[newPatient.formularyName]['masks_preferences'];

    this.handleOneLocation()

    let errors = this.state.errors;
    const requiredFields = this.getRequiredFields();
    // required fields
    errors.pressure = false;
    errors.referring_name = false;
    errors.ahi = false;
    errors.bmi = false;
    errors.ethnicity = false;
    errors.facilityType = false;
    errors.sleepStudyType = false;
    errors.number = this.state.errors.number;
    errors.setUpDate = false;
    errors.patientAssignedTo = false;
    errors.maskList = false;
    if (this.state.shouldSendInvite) {
      if (requiredFields.includes('pressure') && !newPatient.pressure) errors.pressure = true;
      if (requiredFields.includes('referring_name') && !newPatient.referringPhysicianNumber && !this.props.store.currentSubdomain.npi_client) errors.referring_name = true;
      if (requiredFields.includes('ahi') && !newPatient.ahi) errors.ahi = true;
      if (requiredFields.includes('bmi') && !newPatient.bmi) errors.bmi = true;
      if (requiredFields.includes('ethnicity') && !newPatient.ethnicity) errors.ethnicity = true;
      if (requiredFields.includes('facilityType') && !newPatient.facilityType) errors.facilityType = true;
      if (requiredFields.includes('sleepStudyType') && !newPatient.sleepStudyType) errors.sleepStudyType = true;
      if (newPatient.inviteChannel === 'text' && !newPatient.number) {
        errors.number = numberErrorEnum.emptyNum;
      }
      if (newPatient.inviteChannel === 'email' && errors.number === 3) errors.number = numberErrorEnum.success; // empty phone is not an error if invite channel = email
      if (requiredFields.includes('patientAssignedTo') && !newPatient.patientAssignedTo) errors.patientAssignedTo = true;
      if (!newPatient.formularyMaskList || newPatient.formularyMaskList.length == 0) {
        errors.maskList = true;
        if (newPatient.isFirstTime && this.props.store.formularies) {
          if (this.props.store.formularies['Recommended New Patient Formulary']['enabled_masks'].length === 0) {
            toast.error('Add Masks to Recommended New Patient Formulary in Settings')
          }
        } else if (!newPatient.isFirstTime && this.props.store.formularies) {
          if (this.props.store.formularies['Recommended Resupply Optimization Formulary']['enabled_masks'].length === 0) {
            toast.error('Add Masks to Recommended Resupply Optimization Formulary in Settings')
          }
        }
      }
    }

    errors.id = false;
    if (!newPatient.id.trim() || !validatePatientID(newPatient.id)) {
      errors.id = true;
      if (!newPatient.id.trim()) this.setState({ isPtIDEmpty: true })
      if (newPatient.id.trim() && !validatePatientID(newPatient.id)) {
        this.setState({ isPtIDEmpty: false })
      }
    } else if (newPatient.id.trim() && validatePatientID(newPatient.id)) {
      errors.id = false
      this.setState({
        errors: { ...this.state.errors, id: false },
        isPtIDEmpty: false
      })
    }

    errors.location = false;
    if (newPatient.location === '' && !this.props.store.currentSubdomain.online_only_client) {
      errors.location = true;
    }

    errors.firstName = false;
    if (!newPatient.firstName) {
      errors.firstName = true;
    }

    errors.lastName = false;
    if (!newPatient.lastName) {
      errors.lastName = true;
    }

    errors.setUpDate = false;
    const isSetupDateInRange = () => {
      const setupDate = dayjs(newPatient.setUpDate);
      const currentDate = dayjs();
      const nextTwoMonths = currentDate.add(60, 'day');
      if (!setupDate.isBefore(currentDate, 'day') && !setupDate.isAfter(nextTwoMonths)) {
        return true;
      } return false;
    }
    // If it is a required field, then check if date is entered and it is within the range
    if (requiredFields.includes('setUpDate')) {
      if (
        !newPatient.setUpDate.trim() ||
        !dayjs(newPatient.setUpDate).isValid() ||
        !isSetupDateInRange()
      ) {
        errors.setUpDate = true;
      }
    }
    // if it is not required field but user still entered a valid setup date, then check if it is in range
    else if (dayjs(newPatient.setUpDate).isValid()) {
      if (!isSetupDateInRange()) errors.setUpDate = true;
    }

    //here we validate the email.
    errors.email = false;
    if (!newPatient.email || !validateEmail(newPatient.email) || !this.validateBlacklistedMail(newPatient.email)) {
      if (newPatient.inviteChannel === 'email') {
        errors.email = true;
      } else {
        console.log('Ignoring email validation for non-email invite');
      }
    }

    if (newPatient.ahi && (newPatient.ahi < 0 || newPatient.ahi > 100)) {
      errors.ahi = true;
    }

    if (newPatient.pressure && (newPatient.pressure < 0 || newPatient.pressure > 100)) {
      errors.pressure = true;
    }

    if (newPatient.bmi && (newPatient.bmi <= 0 || newPatient.bmi > 999)) {
      errors.bmi = true;
    }

    errors.birthDate = false;
    if (!newPatient.birthDate || !dayjs(newPatient.birthDate).isValid()) {
      errors.birthDate = true;
    } else {
      //if birthdate is in the future, or its less than 1900-01-01, then its invalid
      if (new Date() < new Date(newPatient.birthDate) || new Date('1900-01-01') > new Date(newPatient.birthDate)) {
        errors.birthDate = true;
        toast.error('Please enter a valid date of birth.');
      }
    }

    errors.prescribed_mask = false;
    if (newPatient.isScripted && newPatient.prescribed_mask === '') {
      errors.prescribed_mask = true;
    }

    errors = { ...errors, number: this.state.errors.number }
    this.setState({ errors })

    let allKeys = Object.keys(errors);
    for (let i = 0; i < allKeys.length; i++) {
      if (allKeys[i] !== 'duplicateId' && errors[allKeys[i]]) {
        submit = false;
        this.props.store.errorMessage = 'Error adding patient. Examine the messages above.';
        console.error('Validation errors adding new patient: ', errors);
        break;
      }
    }

    //errors.contact_info = false;
    if ((!this.state.shouldSendInvite || this.state.inviteChannel === 'hold') && (!newPatient.email && !newPatient.number)) {
      submit = false;
      toast.error('Please enter either email or mobile number');
    }

    newPatient.allowDuplicate = this.state.allowDuplicate;

    if (submit && !this.state.allowDuplicate && errors.duplicateId) {
      this.setState({ confirmationModal: true });
      return;
    }

    this.props.store.masksVarietyWarning = appStore.validateMaskVariety(this.state.selectedMasksVariety)

    if (submit && this.props.store.masksVarietyWarning?.length) {
      this.setState({ showFormularyWarningModal: true });
      return;
    }

    if (this.props.store.newPatient.isScripted &&
      !(this.props.store.newPatient.referringPhysicianFullName || this.props.store.selectedRow.referring_name) &&
      !this.state.showMissingNPIWarning) {
      this.toggleMissingNPIWarningModal();
      return
    }

    if (submit) {
      appStore.addPatient(newPatient)
        .then(() => {
          this.toggleModal();
          appStore.loadPatients();
          if (this.props.store.scanPackageEnabled) {
            appStore.loadScanCounter();
          }
          this.setState({
            allowDuplicate: false,
            confirmationModal: false,
            showAddPtModal: false,
            errors: initErrors
          })
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.error;
          console.error('/rest/patient failed with error: ', errMsg);
          this.props.store.errorMessage = 'Error adding patient. ' + errMsg;
          errors.id = error?.response?.data?.patientID;
          let confirmationModal = false;
          if (error?.response?.status === 409) {
            confirmationModal = true;
          }
          this.setState({
            confirmationModal: confirmationModal,
            errors: errors
          })
          this.props.store.loading = false;
        })
    }
  };

  validateBlacklistedMail = (email) => {
    if (this.props.store.blacklistedEmails.includes(email)) {
      return false;
    }
    return true;
  }

  isOnlyOneLocation = () => {
    const locations = store.locationOptionGroups.map(loc => ({
      ...loc,
      options: loc.options.filter(opt => !opt.isHidden)
    }))
    return !this.hasSubdomains(locations) && locations?.length > 0 && locations[0]?.options?.length === 1
  }

  handleRadioButtonChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
      selectedPressure: null, // Reset the selected value of pressureDropdown
    },
      () => {
        // Call a function to update the pressure options based on the selected radio button
        this.updatePressureOptions();
      }
    );
  };

  updatePressureOptions = () => {
    const { selectedOption } = this.state;
    let pressureOption = [];

    if (selectedOption === 'pressureCPAP' || selectedOption === 'pressureBIPAP' || selectedOption === 'pressureASVST') {
      for (let i = 5; i <= 19; i++) {
        pressureOption.push({ value: i.toString(), label: i.toString() });
      }
      pressureOption.push({ value: '> 20', label: '> 20' });
    } else if (selectedOption === 'pressureCPAPAP') {
      pressureOption.push({ value: '<16', label: 'Auto with max pressure <16' });
      pressureOption.push({ value: '>=16', label: 'Auto with max pressure >=16' });
    }
    this.setState({ pressureOption });
  };

  getPapValue = () => {
    if (this.state.selectedOption === 'pressureCPAP') {
      return 'CPAP'
    } else if (this.state.selectedOption === 'pressureASVST') {
      return 'ASV/ST'
    } else if (this.state.selectedOption === 'pressureCPAPAP') {
      return 'AutoPAP'
    } else if (this.state.selectedOption === 'pressureBIPAP') {
      return 'BiPAP'
    }
  }


  render() {
    const langType = getLangType();
    const formularyNameOptions = appStore.getFormularyNameOptions();
    const physicianOptions = appStore.getPhysicianOptions();
    const requiredFields = this.getRequiredFields();

    // Classifier attributes are only disabled if patient is not scripted, and invite is also not to be sent out
    const showClassifierAttributes = this.state.shouldSendInvite || this.props.store.newPatient.isScripted

    return (
      <>
        <Modal
          isOpen={this.state.showAddPtModal}
          toggle={this.toggleModal}
          className='modal-lg'>
          <ModalHeader toggle={this.toggleModal}>
            {languageOptions.ptAddNewPatient[langType]}
          </ModalHeader>
          <ModalBody>
            {this.props.store.loading && <Progress value={100} animated />}
            <div className='outer'>
              {this.props.store.currentSubdomain.is_practice && (
                <Row className='mb-2'>
                  <Col md='6'>
                    <Label htmlFor='physicianDropdown'>
                      {languageOptions.physicianNPI[langType]} {!this.props.store.currentSubdomain.online_only_client && requiredLabel()}
                      <ToolTip id='physician-tooltip'
                        content={
                          (<span>
                            Enter the physician whom this patient belongs to.
                          </span>)
                        }
                      />
                    </Label>
                    <Select
                      isSearchable="true"
                      placeholder={languageOptions.select[langType]}
                      options={physicianOptions}
                      onChange={(e) => {
                        this.props.store.newPatient.physicianSubdomain = e.value;
                        appStore.getLocationOptions(this.props.store.subdomainNpiNumbers[e.value]['affiliates']);
                      }}
                      invalid={this.state.errors.physicianSubdomain}
                      name='physicianDropdown'
                      id='physicianDropdown'
                    />
                    {this.state.errors.location && (
                      displayFormError(languageOptions.currentLocationError[langType])
                    )}
                  </Col>
                </Row>
              )}

              <Row className='mb-2'>
                <Col md='4' className='pr-md-0'>
                  <Label htmlFor='patientTypeSwitch'>
                    {languageOptions.ptType[langType]}
                    <ToolTip id='patient-type-tooltip'
                      content={
                        (<span>
                          <b>{languageOptions.ptNewSinglular[langType]}</b>: {languageOptions.ptNewDesc[langType]}
                          <br />
                          <b>{languageOptions.ptResupplySingular[langType]}</b>: {languageOptions.ptResupplyDesc[langType]}
                        </span>)
                      }
                    />
                  </Label>
                  <label id='patientTypeSwitch' style={{ display: 'flex' }}>
                    <Switch
                      offColor="#1010f0"
                      offHandleColor="#f0f0f0"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={(isNew) => {
                        this.props.store.newPatient.isFirstTime = isNew;
                        this.props.store.selectedFormularyName = appStore.getDefaultFormulary();
                        this.setMaskListToFormulary(this.props.store.selectedFormularyName);
                      }}
                      checked={this.props.store.newPatient.isFirstTime}
                    />
                    {this.props.store.newPatient.isFirstTime && (
                      <span>
                        &nbsp; {languageOptions.ptNewSinglular[langType]}
                      </span>
                    ) || (
                        <span>
                          &nbsp; {languageOptions.ptResupplySingular[langType]}
                        </span>
                      )}
                  </label>
                </Col>
                <Col md='4' className='d-flex flex-column justify-content-center'>
                  <Label htmlFor='patientInviteSwitch'>
                    Invite patient for scan
                    <ToolTip
                      id='patientInviteTooltip'
                      content={(<span>If turned on, adding this patient will automatically send an invite link to the patient for scanning their face and recommending an ideal PAP mask. </span>)}
                    />
                  </Label>
                  <Switch
                    id='patientInviteSwitch'
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={this.handleInviteSwitch}
                    checked={this.state.shouldSendInvite}
                  />
                </Col>
                <Col md='4' className='d-flex flex-column justify-content-center'>
                  <Label htmlFor='isPatientRecall'>
                    Recall campaign
                    <ToolTip
                      id='recallTooltip'
                      content={(<span>If your manager has approved, turn this switch on if the patient is to be invited for the recall campaign.</span>)}
                      color='#900'
                    />
                  </Label>
                  <Switch
                    id='isPatientRecall'
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={this.handleIsRecallSwitch}
                    checked={this.props.store.settings.patientRecallToggleEnabled && this.state.isRecall}
                    disabled={!this.props.store.settings.patientRecallToggleEnabled}
                  />
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col md='6'>
                  <Label htmlFor='locationDropdown'>
                    {languageOptions.currentLocation[langType]} {!this.props.store.currentSubdomain.online_only_client && requiredLabel()}
                    <ToolTip id='location-tooltip'
                      content={
                        (<span>
                          Enter the branch or retail location that this patient should belong to.
                          <br />
                          This may determine the branding and contact information displayed to the patient.
                        </span>)
                      }
                    />
                  </Label>
                  <Select
                    defaultValue={this.defaultLocationOption}
                    isSearchable="true"
                    placeholder={languageOptions.select[langType]}
                    options={store.locationOptionGroups.map(loc => {
                      return {
                        ...loc,
                        options: loc.options.filter(opt => !opt.isHidden)
                      }
                    })}
                    onChange={this.handleAssignedLocChange}
                    invalid={this.state.errors.location}
                    name='locationDropdown'
                    id='locationDropdown'
                    styles={{
                      option: (styles, { data }) => {
                        return {
                          ...styles,
                          color: data.label.toLowerCase().includes('no patients') ? 'var(--color-darkred)' : 'inherit'
                        };
                      }
                    }}
                  />
                  {this.state.errors.location && (
                    displayFormError(languageOptions.currentLocationError[langType])
                  )}
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col md='6'>
                  <Label htmlFor='patientID'>
                    {languageOptions.patientId[langType]} {requiredLabel()}
                    <ToolTip id='patient-id-tooltip' content={<span>Patient ID is <b>required.</b> <br /> The ID must be <b>unique</b> for every patient. <br /> The ID should consist of <b>numbers, letters, or the following punctuation: - _ .</b></span>} />
                  </Label>
                  <Input
                    invalid={this.state.errors.id || this.state.errors.duplicateId}
                    type='text'
                    id='patientID'
                    placeholder={languageOptions.patientIdPlaceHolder[langType]}
                    onChange={(e) => {
                      this.props.store.newPatient.id = e.target.value;
                      if (!e.target.value.trim()) {
                        this.setState({
                          errors: { ...this.state.errors, id: true },
                          isPtIDEmpty: true
                        })
                      } else if (e.target.value.trim()) {
                        this.setState({
                          errors: { ...this.state.errors, id: false },
                          isPtIDEmpty: false
                        })
                      }
                    }}
                  />
                  <FormFeedback>
                    {this.state.errors.id && this.state.isPtIDEmpty ? (
                      'Patient ID is required'
                    ) : this.state.errors.id ? (
                      "Patient ID is invalid"
                    ) : this.state.errors.duplicateId ? (
                      "Patient ID already exists"
                    ) : (
                      <React.Fragment>
                        Patient ID is invalid or <strong>already exists</strong>
                      </React.Fragment>
                    )}
                  </FormFeedback>
                </Col>
                <Col md='6'>
                  <Label htmlFor='birthDate'>
                    {languageOptions.dateOfBirth[langType]} {requiredLabel()}
                  </Label>
                  <CustomDatePicker
                    slotProps={{ textField: { size: 'small', error: this.state.errors.birthDate } }}
                    onChange={(date) => {
                      this.props.store.newPatient.birthDate = date ? dayjs(date).format('MM/DD/YYYY') : '';
                    }}
                    maxDate={dayjs()}
                    minDate={dayjs('1900-01-01')}
                  />
                  {this.state.errors.birthDate && (
                    displayFormError('Please enter a valid date')
                  )}
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col md='6'>
                  <Label htmlFor='firstname'>
                    {languageOptions.firstName[langType]} {requiredLabel()}
                  </Label>
                  <Input
                    invalid={this.state.errors.firstName}
                    type='text'
                    id='firstname'
                    placeholder='John'
                    onChange={(e) => {
                      this.props.store.newPatient.firstName = e.target.value;
                    }}
                  />
                  <FormFeedback>
                    {languageOptions.firstNameError[langType]}
                  </FormFeedback>
                </Col>
                <Col md='6'>
                  <Label htmlFor='lastname'>
                    {languageOptions.lastName[langType]} {requiredLabel()}
                  </Label>
                  <Input
                    invalid={this.state.errors.lastName}
                    type='text'
                    id='lastname'
                    placeholder='Doe'
                    onChange={(e) => {
                      this.props.store.newPatient.lastName = e.target.value;
                    }}
                  />
                  <FormFeedback>
                    {languageOptions.lastNameError[langType]}
                  </FormFeedback>
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col md='6'>
                  <Label htmlFor='formname'>
                    {languageOptions.mobileCell[langType]} {this.state.shouldSendInvite && this.state.inviteChannel === 'text' && requiredLabel()}
                    <ToolTip id='patient-phone-tooltip'
                      content={
                        (<span>
                          Make sure this number can receive text messages.
                        </span>)
                      }
                    />
                  </Label>
                  <PhoneNumberInput
                    phoneNumberError={this.state.errors.number}
                    isValidPhoneNumber={this.state.isValidPhoneNumber}
                    handlePhoneInputChange={this.handlePhoneInputChange}
                    phoneInputCountry={this.state.phoneInputCountry}
                    updatePhoneInputCountry={(country) => {
                      this.setState({ phoneInputCountry: country })
                    }}
                    value={store.newPatient.number}
                  />
                  {this.state.errors.number != numberErrorEnum.success && (
                    displayFormError(
                      `Error: ${this.state.errors.number == numberErrorEnum.invalidNum ? "This is an invalid number" : this.state.errors.number == numberErrorEnum.notMobileNum ? "This is not a mobile number" : numberErrorEnum.emptyNum ? "Empty number" : "Unknown"}`
                    )
                  )}
                </Col>
                <Col md='6'>
                  <Label htmlFor='formname'>
                    {languageOptions.email[langType]} {this.state.shouldSendInvite && this.state.inviteChannel === 'email' && requiredLabel()}
                  </Label>
                  <Input
                    invalid={this.state.errors.email}
                    type='email'
                    id='email'
                    placeholder='john@example.com'
                    onChange={(e) => {
                      this.props.store.newPatient.email = e.target.value;
                    }}
                  />
                  <FormFeedback>
                    {languageOptions.emailError[langType]}
                  </FormFeedback>
                </Col>
              </Row>

              {this.state.shouldSendInvite &&
                <Row className='mb-2'>
                  <Col md='6'>
                    <Label htmlFor='messageVia'>
                      {languageOptions.msgDelivery[langType]}
                    </Label>
                    <Select
                      id='messageVia'
                      defaultValue={this.msgDeliveryOptions[0]}
                      options={this.msgDeliveryOptions}
                      onChange={(e) => {
                        this.props.store.newPatient.inviteChannel = e.value;
                        if (e.value === 'email') {
                          if (!store.blacklistedEmails || store.blacklistedEmails.length === 0) {
                            appStore.getBlacklistedEmails();
                          }
                        }
                        this.setState({ inviteChannel: e.value });
                      }}
                    />
                  </Col>
                  <Col md='6'>
                    <Label htmlFor='ptLanguage'>
                      {languageOptions.patientLang[langType]}
                      <ToolTip id='patient-language-tooltip' content={<span>The text message and information displayed to the patient will be translated into this language.</span>} />
                    </Label>
                    <Select
                      id={'ptLanguage'}
                      defaultValue={this.ptLanguageOptions[0]}
                      options={this.ptLanguageOptions}
                      onChange={(e) => this.props.store.newPatient.language = e.value}
                    />
                  </Col>
                </Row>
              }
              <Row className='mb-2'>
                <Col md='6'>
                  <Label htmlFor='setUpDate'>
                    {requiredFields.includes('setUpDate') ? requiredLabel('Set up date') : 'Set up date'}
                    <ToolTip id='setUpDate-tooltip'
                      content={
                        (<span>
                          The set up date is the date the patient saw or will see
                          <br />
                          the clinician for setup, whether remote or face-to-face.
                        </span>)}
                    />
                  </Label>
                  <CustomDatePicker
                    id='setUpDate'
                    slotProps={{ textField: { size: 'small', error: this.state.errors.setUpDate } }}
                    onChange={(date) => {
                      store.newPatient.setUpDate = date ? dayjs(date).format('YYYY-MM-DD') : ''
                    }}
                    maxDate={dayjs().add(60, 'days')}  // Use dayjs() to get the current date
                    minDate={dayjs()}
                  />
                  {this.state.errors.setUpDate && (
                    displayFormError('Valid set up date is required')
                  )}
                </Col>
                <Col md='6'>
                  <Label htmlFor='patientAssignedTo'>
                    {requiredFields.includes('patientAssignedTo') ? requiredLabel('Assigned clinician') : 'Assigned clinician'}
                    <ToolTip id='patientAssignedTo-tooltip'
                      content={
                        (<span>
                          This is the clinician the patient will have their setup with.
                        </span>)}
                    />
                  </Label>
                  <Select
                    id='patientAssignedTo'
                    isClearable
                    isSearchable
                    options={this.props.store.users ? this.props.store.users
                      .map(({ username }) => ({ label: username, value: username })) : []}
                    onChange={(e) => {
                      if (e) this.props.store.newPatient.patientAssignedTo = e.value;
                      else this.props.store.newPatient.patientAssignedTo = ''
                    }}
                    onFocus={() => !this.props.store.users?.length &&
                      appStore.loadUsers({ is_active: true })}
                    isLoading={this.props.store.usersLoading}
                  />
                  {this.state.errors.patientAssignedTo && (
                    displayFormError('Assigned Clinician is Required')
                  )}
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col md='6'>
                  <Label htmlFor='ahi'>
                    {requiredFields.includes('ahi') ? requiredLabel(languageOptions.ahi[langType]) : languageOptions.ahi[langType]}
                    <ToolTip id='patient-ahi-tooltip' content={<span>If available, AHI can improve the <b>accuracy</b> of the mask recommendation.</span>} />
                  </Label>
                  <Input
                    invalid={this.state.errors.ahi}
                    type='number'
                    onKeyDown={(e) => !validNumberInput(e) && e.preventDefault()}
                    min={0}
                    max={100}
                    id='ahi'
                    placeholder='N/A'
                    onChange={(e) => {
                      this.props.store.newPatient.ahi = e.target.value;
                    }}
                  />
                  <FormFeedback>{languageOptions.ahiError[langType]}</FormFeedback>
                </Col>
                <Col md='6'>
                  <div className='pressureMarginMobile'>
                    <Label htmlFor='pressure' style={{ marginLeft: '6px' }}>
                      {requiredFields.includes('pressure') ? requiredLabel(languageOptions.papTherapyPressure[langType]) : languageOptions.papTherapyPressure[langType]}
                      <ToolTip id='patient-pressure-tooltip'
                        content={
                          (
                            <span>
                              Choose PAP Therapy first and then Pressure according to: <br />
                              For <b>CPAP patients</b>, enter the pressure in <b>cmH2O</b>.<br />
                              For <b>AutoPAP patients</b>, select MAX {'<'}16 or {'>'}16. <br />
                              For <b>BiPAP patients</b>, enter the <b>maximum</b> of the two pressure values. <br />
                              For <b>ASV/ST</b> patients, select pressure in <b>cmH2O</b>.
                            </span>
                          )
                        }
                      />
                    </Label>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', padding: '0px 8px 0px 6px' }}>
                      <div className='col1'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input type="radio" id="pressureCPAP" name="pressure" value="pressureCPAP" onChange={this.handleRadioButtonChange} />
                          <label htmlFor="pressureCPAP" style={{ marginLeft: '2px', marginBottom: '0.2rem' }}>CPAP</label>
                        </div>
                      </div>
                      <div className='col2'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input type="radio" id="pressureCPAPAP" name="pressure" value="pressureCPAPAP" onChange={this.handleRadioButtonChange} />
                          <label htmlFor="pressureCPAPAP" style={{ marginLeft: '2px', marginBottom: '0.2rem' }}>CPAP</label>
                        </div>
                        <p style={{ margin: '0', marginTop: '-10px', fontSize: '0.8rem', paddingLeft: '14px' }}>(Auto Pressure)</p>
                      </div>
                      <div className='col3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', justifyContent: 'center', marginRight: '24px' }}>
                            <input type="radio" id="pressureBIPAP" name="pressure" value="pressureBIPAP" onChange={this.handleRadioButtonChange} />
                            <label htmlFor="pressureBIPAP" style={{ marginLeft: '2px', marginBottom: '0.2rem' }}>BiPAP</label>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <input type="radio" id="pressureASVST" name="pressure" value="pressureASVST" onChange={this.handleRadioButtonChange} />
                            <label htmlFor="pressureASVST" style={{ marginLeft: '2px', marginBottom: '0.2rem' }}>ASV/ST</label>
                          </div>
                        </div>
                        <p style={{ margin: '0', marginTop: '-10px', fontSize: '0.8rem' }}>(Inspiratory Pressure)</p>
                      </div>
                    </div>
                    {this.state.errors.pressure && (
                      displayFormError(languageOptions.pressureError[langType])
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-2'>
                {/* <Col md='6'>
                      <Label htmlFor='bmi'>
                        {requiredFields.includes('bmi') ? requiredLabel('Body Mass Index') : 'Body Mass Index (BMI)'}
                      </Label>
                      <Input
                        type='number'
                        id='bmi'
                        invalid={this.state.errors.bmi}
                        min={0}
                        max={100}
                        placeholder='N/A'
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            let bmi = parseFloat(e.target.value).toFixed(1);
                            this.props.store.newPatient.bmi = Number(bmi);
                          }
                        }}
                      />
                      <FormFeedback>BMI is required</FormFeedback>
                    </Col> */}
                {showClassifierAttributes ? (
                  <Col md={{ size: 6, order: 1 }} xs={{ size: 12, order: 2 }} className='order-md-0 order-xs-1'>
                    <Label htmlFor='sleepStudyType'>
                      {requiredFields.includes('sleepStudyType') ? requiredLabel(languageOptions.sleepStudyType[langType]) : languageOptions.sleepStudyType[langType]}
                    </Label>
                    <Select
                      placeholder={languageOptions.select[langType]}
                      isSearchable="true"
                      options={[
                        { label: languageOptions.homeSleepTest[langType], value: 'Home Sleep Test' },
                        { label: `PSG ${languageOptions.withTitration[langType]}`, value: 'PSG with Titration' },
                        { label: `PSG ${languageOptions.noTitration[langType]}`, value: 'PSG no Titration' },
                      ]}
                      onChange={(e) => {
                        this.props.store.newPatient.sleepStudyType = e.value;
                      }}
                      name='sleepStudyTypeDropdown'
                      id='sleepStudyTypeDropdown'
                    />
                    {this.state.errors.sleepStudyType && (
                      displayFormError('Sleep Study Type is required')
                    )}
                  </Col>) : (<Col md={{ size: 6, order: 1 }} xs={{ size: 12, order: 2 }} className='order-md-0 order-xs-1'></Col>)}
                <Col md={{ size: 6, order: 2 }} xs={{ size: 12, order: 1 }} className='order-md-1 order-xs-2'>
                  <div className='d-none d-md-block' style={{ height: '2rem' }}></div>
                  <Select
                    placeholder={languageOptions.selectPressure[langType]}
                    options={this.state.pressureOption}
                    onChange={(value) => {
                      this.setState({ selectedPressure: value?.value }, () => {
                        this.props.store.newPatient.pressure = this.state.selectedPressure;
                        this.props.store.newPatient.pap_therapy = this.getPapValue();
                      });
                    }}
                    value={this.state.selectedPressure ? { value: this.state.selectedPressure, label: this.state.selectedPressure } : null}
                    name='pressureDropdown'
                    id='pressureDropdown'
                  />
                </Col>
              </Row>
              <Row className='mb-2'>
                {showClassifierAttributes ? (
                  <Col md='6'>
                    <Label htmlFor='ethnicity'>
                      {requiredFields.includes('ethnicity') ? requiredLabel(languageOptions.ethnicity[langType]) : languageOptions.ethnicity[langType]}
                    </Label>
                    <Select
                      placeholder={languageOptions.select[langType]}
                      isSearchable="true"
                      options={[
                        { label: languageOptions.americanIndian[langType], value: 'american indian' },
                        { label: languageOptions.asian[langType], value: 'asian' },
                        { label: languageOptions.africanAmerican[langType], value: 'african american' },
                        { label: languageOptions.hispanic[langType], value: 'hispanic' },
                        { label: languageOptions.hawaiian[langType], value: 'pacific islander' },
                        { label: languageOptions.caucasian[langType], value: 'caucasian' },
                        { label: languageOptions.other[langType], value: 'other' },
                      ]}
                      onChange={(e) => {
                        this.props.store.newPatient.ethnicity = e.value;
                      }}
                      name='ethnicityDropdown'
                      id='ethnicityDropdown'
                    />
                    {this.state.errors.ethnicity && (displayFormError('Ethnicity is required'))}
                  </Col>) : (<Col md='6'></Col>)}

                <Col md='6'>
                  <Label htmlFor='facilityType'>
                    {requiredFields.includes('facilityType') ? requiredLabel(languageOptions.facilityType[langType]) : languageOptions.facilityType[langType]}
                  </Label>
                  <Select
                    placeholder={languageOptions.select[langType]}
                    options={[
                      { value: 'DME/HME', label: 'DME/HME' },
                      { value: 'Sleep Lab', label: languageOptions.sleepLab[langType] },
                      { value: 'Hospital', label: 'Hospital' },
                      { value: 'Medical Doctor', label: languageOptions.medicalDoctor[langType] },
                      { value: 'Skilled Nursing Facility', label: languageOptions.skilledNursingFacility[langType] },
                      { value: 'Other', label: languageOptions.other[langType] },
                    ]}
                    onChange={(e) => {
                      this.props.store.newPatient.facilityType = e.value;
                    }}
                    name='facilityType'
                    id='facilityType'
                  />
                  {this.state.errors.facilityType && (
                    displayFormError('Facility Type is required')
                  )}
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col md='12'>
                  <Label htmlFor='formmessage'>
                    {languageOptions.writeNotes[langType]}
                    <ToolTip id='patient-notes-tooltip' content={<span><b>Optional.</b> Only visible to other members of your organization.</span>} />
                  </Label>
                  <Input
                    type='textarea'
                    id='formmessage'
                    className='form-control'
                    placeholder={languageOptions.writeNotesPlaceholder[langType]}
                    rows='2'
                    onChange={(e) => {
                      this.props.store.newPatient.notes = e.target.value;
                    }}
                  />
                </Col>
              </Row>
              <Row className='mb-2' style={{ marginTop: '12px' }}>
                {!this.props.store.currentSubdomain.npi_client && (
                  <Col md='6'>
                    <Label htmlFor='refPhysNum'>
                      {requiredFields.includes('referring_name') ? requiredLabel(languageOptions.referringNPI[langType]) : languageOptions.referringNPI[langType]}
                    </Label>
                    <div className='d-flex justify-content-end gap-1'>
                      <Input
                        type='text'
                        name='refPhysNum'
                        invalid={this.state.errors.referring_name}
                        value={this.props.store.newPatient.referringPhysicianFullName}
                        readOnly
                      />
                      <Button color='primary' onClick={this.toggleRefPhysModal}>
                        <i className='fa fa-search' />
                      </Button>
                    </div>
                    {this.state.errors.referring_name && (
                      displayFormError('Referring Physician is required')
                    )}
                    {this.props.store.newPatient.isScripted && !(this.props.store.newPatient.referringPhysicianFullName || this.props.store.selectedRow.referring_name) && (
                      displayFormError('Referring Physician is not provided for scripted mask')
                    )}
                  </Col>
                )}
              </Row>
              {this.props.store.settings.sharedPatientsEnabled &&
                <Row className='mb-2' style={{ marginTop: '12px' }}>
                  <Col md='12'>
                    <Label>
                      Select providers to share patient details with:
                    </Label>
                    <Select
                      style={{ display: 'block' }}
                      options={this.props.store.locationsSharedWithOptions}
                      isMulti="true"
                      isSearchable="true"
                      onChange={(e) => this.props.store.newPatient.sharedWith = e}
                    />{' '}
                  </Col>
                </Row>
              }

              <Row className='mt-4 mb-4'>
                <Col md='2'>
                  <label id='scriptedPtSwitch'>
                    {languageOptions.hasPrescription[langType]}
                  </label>
                </Col>
                <Col md='2' >
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    value={this.props.store.newPatient.isScripted}
                    onChange={(isScripted) => {
                      this.props.store.newPatient.isScripted = isScripted;
                      this.resetPrescription();
                      if (isScripted && !this.props.store.newPatient.referringPhysicianNumber) {
                        this.toggleRefPhysModal();
                      }
                    }}
                    checked={this.props.store.newPatient.isScripted}
                  />
                </Col>
              </Row>

              {/* Scripted Form  */}
              {this.props.store.newPatient.isScripted &&
                <Col>
                  <Card>
                    <CardHeader>
                      <strong>{languageOptions.maskPrescription[langType]}</strong>
                    </CardHeader>
                    <CardBody>
                      <ScriptedMaskForm
                        handleMaskType={this.handleMaskType}
                        handleCushionSize={this.handleCushionSize}
                        handleFrameSize={this.handleFrameSize}
                        scriptedErrors={{
                          mask: this.state.errors.prescribed_mask,
                          mask_other: this.state.errors.prescribed_mask_other,
                          cushionSize: this.state.errors.prescribed_mask_cushion_size,
                          frameSize: this.state.errors.prescribed_mask_frame_size
                        }}
                        selectedMaskName={this.props.store.newPatient.prescribed_mask}
                      />
                    </CardBody>
                  </Card>
                </Col>
              }

              {/* Advanced Settings */}
              {(['super_administrator', 'administrator', 'manager', 'clinician'].includes(this.props.store.currentUser.userRole)) &&
                <>
                  <Row className='mb-2'>
                    <Col md='8'>
                      <label style={{ color: 'black', fontWeight: 'bold' }}>
                        {languageOptions.advancedSettings[langType]}
                      </label>
                    </Col>
                  </Row>

                  {this.state.shouldSendInvite &&
                    <>
                      <Row className='mb-2'>
                        <Col md='12'>
                          <Label>{languageOptions.formulary[langType]}
                            <ToolTip id='patient-formulary-tooltip'
                              content={
                                (<span>
                                  Select which formulary of mask options should be considered for this patient.
                                  <br />
                                  Your recommended formulary will be used by default.
                                </span>)
                              }
                            />
                          </Label>
                          <Select
                            options={formularyNameOptions}
                            isSearchable="true"
                            value={{
                              value: this.props.store.selectedFormularyName,
                              label: this.props.store.selectedFormularyName,
                            }}
                            onChange={
                              (e) => {
                                this.props.store.selectedFormularyName = e.value;
                                this.setMaskListToFormulary(e.value);
                                appStore.getAvailableMaskOptionGroups();
                              }
                            }
                          />
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col md='12'>
                          <Label>{languageOptions.ptAvailableMasks[langType]} {requiredLabel()}
                            <ToolTip id='patient-masks-available-tooltip'
                              content={
                                (<span>
                                  This is the list of all masks that will be considered for this patient.<br />
                                  Add more masks here to improve the variety of options available.<br />
                                  You need <b>Clinician</b>-level access or higher to edit this option.
                                </span>)
                              }
                            />
                          </Label>
                          <Select
                            options={this.props.store.availableMaskOptionGroups}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            isSearchable={true}
                            value={this.props.store.selectedMaskList}
                            isDisabled={!this.props.store.currentUser.canUseAdvancedSettings}
                            onChange={(e) => {
                              let newMaskList = [];
                              if (e && e.length > 0) {
                                e.forEach((item) => {
                                  newMaskList.push(item.value);
                                });
                              }
                              this.updateMaskList(newMaskList);
                            }}
                            styles={{
                              option: (styles, { data }) => {
                                return {
                                  ...styles,
                                  color: maskIsMagnetic(data.value) ? "var(--color-darkred)" : undefined
                                };
                              },
                              multiValueLabel: (styles, { data }) => {
                                return {
                                  ...styles,
                                  color: maskIsMagnetic(data.value) ? "var(--color-darkred)" : undefined
                                };
                              }
                            }}
                          />
                          {this.state.errors.maskList && (
                            displayFormError(
                              this.props.store.newPatient.isFirstTime
                                ? "Add Masks to Recommended New Patient Formulary"
                                : "Add Masks to Recommended Resupply Optimization Formulary"
                            )
                          )}
                        </Col>

                        <Col className='mt-2'>
                          <Label>{languageOptions.filterFormulary[langType]}</Label>
                          <Select
                            value={this.state.brands}
                            className='mb-3'
                            isMulti={true}
                            isSearchable={true}
                            isClearable={false}
                            options={this.getBrandOptions()}
                            onChange={(e, i) => {
                              this.setState({ brands: e })
                              if (i.action === 'remove-value') {
                                if (i.removedValue.value === 'Nasal' || i.removedValue.value === 'Full Face' || i.removedValue.value === 'Nasal Pillow') {
                                  this.filterByMaskType({ value: i.removedValue.value, isMaskFilter: true, addTo: false });
                                } else {
                                  this.filterByMaskType({ value: i.removedValue.value, isMaskFilter: false, addTo: false });
                                }
                              } else if (i.action === 'select-option') {
                                if (i.option.value === 'Nasal' || i.option.value === 'Full Face' || i.option.value === 'Nasal Pillow') {
                                  this.filterByMaskType({ value: i.option.value, isMaskFilter: true, addTo: true });
                                } else {
                                  this.filterByMaskType({ value: i.option.value, isMaskFilter: false, addTo: true });
                                }
                              }
                            }}
                          />
                        </Col>
                        {this.props.store.currentUser.canUseAdvancedSettings && (
                          <div style={{ width: '100%', fontSize: 'small', margin: '10px', display: 'flex', justifyContent: 'space-around' }}>
                            <Button color="light" size="sm" onClick={() => {
                              this.setState({ brands: this.getBrandOptions() })
                              this.props.store.selectedFormularyName = appStore.getDefaultFormulary();
                              this.setMaskListToFormulary(this.props.store.selectedFormularyName);
                            }}>
                              <small>Reset</small>
                            </Button>
                          </div>
                        )}
                      </Row>
                    </>
                  }
                </>
              }

            </div>
          </ModalBody>
          <ModalFooter>
            <small style={{ color: 'var(--bs-danger)' }}>{this.props.store.errorMessage}</small>
            <Button color='primary' disabled={this.props.store.loading} onClick={this.submitNewPtForm}>
              {languageOptions.ptAddNewPatient[langType]}
            </Button>{' '}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.refPhysModal}
          className='modal-lg'>
          <ModalHeader>
            {languageOptions.refPhysLookup[langType]}
          </ModalHeader>
          <ModalBody>
            <ReferringPhysicianLookup
              toggle={this.toggleRefPhysModal}
            />
          </ModalBody>
        </Modal>
        {/* Empty NPI modal */}
        <Modal
          isOpen={this.state.showMissingNPIWarning}
          size="md"
          centered
        >
          <ModalHeader>
            Empty Referring Physician information
          </ModalHeader>
          <ModalBody>
            This patient has a prescription but <b>do NOT have a referred physician</b>. Are you sure you want to continue?
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              onClick={() => {
                this.toggleMissingNPIWarningModal();
                this.toggleRefPhysModal();
              }}
            >
              {languageOptions.lookup[langType]}{' '}
              <span style={{ paddingLeft: '2px' }}>
                <i className='fa fa-search' />
              </span>
            </Button>
            <Button
              color='primary'
              onClick={() => {
                this.toggleMissingNPIWarningModal();
                this.submitNewPtForm();
              }}
            >
              {languageOptions.confirm[langType]}
            </Button>
          </ModalFooter>
        </Modal>
        {/* Confirmation modal dialog */}
        <Modal
          isOpen={this.state.confirmationModal}
          toggle={this.toggleConfirmationModal}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggleConfirmationModal}>
            {languageOptions.ptDuplicateModalHeader[langType]}
          </ModalHeader>
          <ModalBody>
            <p className="text-danger">
              {languageOptions.ptDuplicateModalBody[langType]}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggleConfirmationModal}>
              {languageOptions.close[langType]}
            </Button>
            <Button color='primary' onClick={this.doConfirm}>
              {languageOptions.confirm[langType]}
            </Button>
          </ModalFooter>
        </Modal>
        {/* Mask Variety modal */}
        <Modal
          isOpen={this.state.showFormularyWarningModal}
          toggle={this.toggleWarningModal}
          size="md"
          centered
          style={{ maxWidth: '625px', width: '80%' }}
        >
          <ModalHeader toggle={this.toggleWarningModal}>
            <i className='fa fa-exclamation-circle' style={{ color: "goldenrod", fontSize: "1.5rem", marginRight: "10px" }} />
            Formulary Configuration
          </ModalHeader>
          <ModalBody>
            <b>There may not be enough masks for an ideal recommendation:</b>
            <ul style={{ margin: '0.5rem auto', listStyleType: 'none' }}>
              {this.props.store.masksVarietyWarning && (
                this.props.store.masksVarietyWarning.map((warning, i) => (
                  <li key={warning + i}>{languageOptions.maskVarietyModalBody[warning][langType]}</li>
                )))}
            </ul>
            <span>Are you sure you want to continue?</span>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggleWarningModal}>
              {languageOptions.cancel[langType]}
            </Button>
            <Button color='primary' onClick={this.warningDoConfirm}>
              {languageOptions.confirm[langType]}
            </Button>
          </ModalFooter>
        </Modal>
        <Button
          type='button'
          color='primary'
          size='sm'
          disabled={this.props.store.currentUser.userRole === 'readonly' || this.props.store.currentSubdomain.receive_pts_only || (this.props.store.currentSubdomain.npi_client && this.props.store.currentSubdomain.no_scans && this.props.source !== 'Order')}
          onClick={this.toggleModal}
          style={{ marginBottom: '4px' }}>
          {languageOptions.ptAddNewPatient[langType]}{' '}
          <span style={{ paddingLeft: '2px' }}>
            {' '}
            <i className='fas fa-plus-square' />
          </span>
        </Button>
      </>
    );
  }
}

export default collect(NewPatientForm);
