import React, { useState, useEffect } from 'react'
import { collect } from 'react-recollect';
import { languageOptions } from '../language';
import { getLangType, maskIsMagnetic, getCushionSizesByName, getMaskFrameSize, displayFormError, requiredLabel } from '../utils/utils';
import { Button, Col, Row, Card, CardHeader, CardBody, Input, Label } from 'reactstrap';
import Select from "react-select";
import CustomDatePicker from './CustomDatePicker.js';
import { getMaskOptionGroupsWithOther } from './NewPatientForm';
import ToolTip from './ToolTip.js';
import dayjs from 'dayjs';

const PatientRefitForm = ({ store, state, checkRefitExists, handleRefitSave, handleRefitCancel, isFromOtherMask = false, masksToExclude }) => {

  const choices = {
    leaking: "Leaking occurred",
    prssure_sore: "Patient felt points of pressure or developed sores from pressure",
    irritation: "Skin irritation developed",
    congestion: "Increased incident of nasal congestion",
    discomfort: "Patient experienced general discomfort",
    air_swallowing: "Probable air swallowing and bloat developed",
    wrong_screening_answer: "Screening questions inaccurately answered",
    incorrect_frame_size: "Frame size recommended was incorrect",
    friend_suggstion: "Friend/relative recommended a different mask",
    physician: "Physician changed the prescription",
    clinician: "Clinician preference",
    stock: "Stock availability",
    repap: "Mask used previously/repap",
    study: "Mask used in sleep study",
    other: "Other"
  };

  const frameSizeDefault = [
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
    { label: "Standard", value: "Standard" },
    { label: "Other", value: "Other" },
  ]

  const langType = getLangType();
  const [selectedMaskName, setSelectedMaskName] = useState('');
  const [otherMaskName, setOtherMaskName] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [cushionSize, setCushionSize] = useState('');
  const [frameSize, setFrameSize] = useState('');
  const [setUpDate, setSetUpDate] = useState(dayjs());
  const [chinStrapDispensed, setChinStrapDispensed] = useState('');
  const [selectedChoices, setSelectedChoices] = useState(
    Object.keys(choices).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );
  const [showMaskSwitch, setShowMaskSwitch] = useState(false);
  const [showMaskReason, setShowMaskReason] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);


  const handleChoiceToggle = (e) => {
    const { name, checked } = e.target
    setSelectedChoices({ ...selectedChoices, [name]: checked })
  };

  // Mask Options
  const maskOptions = getMaskOptionGroupsWithOther();
  const recommendedMasks = state?.model_output?.masks ?? [];
  // Disable recommended masks
  for (const category in maskOptions) {
    maskOptions[category].options.forEach((option) => {
      if (recommendedMasks.includes(option.value)) {
        option.recommended = true;
        option.label = option.label + " (Recommended)";
      }
    });
  }

  useEffect(() => {
    // "Other" checkbox option is checked
    //selectedChoices.other ? setShowMaskReason(true)  : (setShowMaskReason(false), setOtherReason(''));
    if (selectedChoices.other) {
      setShowMaskReason(true);
    } else {
      setShowMaskReason(false);
      setOtherReason('');
    }

    // Required Fields
    const maskNameProvided = (selectedMaskName === "Other" && otherMaskName.trim() !== '') || (selectedMaskName !== '' && selectedMaskName !== 'Other')
    const areCheckboxesEmpty = !Object.values(selectedChoices).some(Boolean);
    // 'Other' checkbox should be filled
    const checkboxesFilled = !areCheckboxesEmpty && ((selectedChoices.other && otherReason.trim() !== '') || (!selectedChoices.other))
    const formValid = checkboxesFilled && maskNameProvided && cushionSize && frameSize && chinStrapDispensed && setUpDate !== ''
    setIsFormValid(formValid);

  }, [selectedChoices, selectedMaskName, otherMaskName, cushionSize, frameSize, chinStrapDispensed, otherReason, setUpDate]);

  return (
    <>
      <Card style={{ 'backgroundColor': '#red' }}>
        <CardHeader>
          {isFromOtherMask ? <strong>Other Mask</strong> : <strong>{languageOptions.ptRefitFeedBack[langType]}</strong>}
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm='12' id='refitForm'>
              {/* Date */}
              <div className='mb-2' style={{ maxWidth: '350px' }}>
                <span>Initial Setup Date {requiredLabel()}</span>
                <CustomDatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  minDate={dayjs('2022-06-01')}
                  maxDate={dayjs()} //current date.
                  onChange={(date) => {
                    // if (date && (date.isAfter(dayjs('2022-05-31')) && date.isBefore(dayjs())) ) {
                    const formattedDate = dayjs(date).format('MM/DD/YYYY');
                    setSetUpDate(formattedDate);
                    // } else setSetUpDate('');
                  }}
                />
                <div>
                  {!setUpDate && displayFormError("Invalid date")}
                </div>
              </div>
              {/* Mask */}
              <div className='mb-2'>
                <span>Mask {requiredLabel()}</span>
                <Select
                  name='refitFeedback'
                  options={maskOptions}
                  placeholder={languageOptions.select[langType]}
                  isOptionDisabled={(option) => option.recommended}
                  onChange={(e) => {
                    setSelectedMaskName(e.value);
                    setCushionSize('')
                    setFrameSize('')
                    setChinStrapDispensed('')
                    if (e.value === 'Other') {
                      setShowMaskSwitch(true);
                    } else { setShowMaskSwitch(false); }
                    checkRefitExists(e.value);
                  }}
                  styles={{
                    option: (styles, { data }) => {
                      if (data.recommended) { return { ...styles } }
                      else {
                        return {
                          ...styles,
                          color: maskIsMagnetic(data.value) ? "var(--color-darkred)" : undefined
                        };
                      }
                    }
                  }}
                />
                <div>
                  {state.doesRefitExist &&
                    displayFormError("A " + state.switch_reason + " with this mask has already been submitted.")
                    //displayFormError({`A ${state.switch_reason}with this mask has already been submitted`})
                  }
                </div>
                <div>
                  {maskIsMagnetic(selectedMaskName) &&
                    displayFormError("This mask includes magnetic clips.")

                  }
                </div>
              </div>
              {showMaskSwitch && (
                <div className='mb-2'>
                  <span>Other mask {requiredLabel()}</span>
                  <Input
                    type='text'
                    name='refitFeedback'
                    placeholder='Enter the mask name...'
                    onChange={(e) => { setOtherMaskName(e.target.value) }}
                  />
                </div>)}
              {/* Cuchion Size */}
              <div className='mb-2'>
                <span>Cushion Size {requiredLabel()}</span>
                <Select
                  placeholder={!selectedMaskName ? 'Select... (Options are available after selecting mask)' : 'Select...'}
                  name='cushionSize'
                  value={cushionSize}
                  options={getCushionSizesByName(selectedMaskName)}
                  onChange={(e) => {
                    setCushionSize(e);
                  }}
                />
              </div>
              {/* Frame Size */}
              <div className='mb-2'>
                <span>Frame Size {requiredLabel()}</span>
                <Select
                  name='frameSize'
                  value={frameSize}
                  options={getMaskFrameSize(selectedMaskName) || frameSizeDefault}
                  onChange={(e) => {
                    setFrameSize(e);
                  }}
                />
              </div>
              {/* Chin Strap  */}
              <div className='mb-2'>
                <span>Was a chin strap dispensed? {requiredLabel()}</span>
                <Select
                  name='chinStrapDispensed'
                  value={chinStrapDispensed}
                  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' }
                  ]}
                  onChange={(e) => {
                    setChinStrapDispensed(e);
                  }}
                />
              </div>
              {/* Reasons  */}
              <div className='mb-2'>
                <span>Reason: {requiredLabel()}</span>
                <ToolTip
                  id='refit-reason'
                  content={(<span>Select all that apply</span>)}
                />
                <div className='reason-checkbox-container' style={{ display: 'flex', flexDirection: 'column', margin: '0rem 1.4rem' }}>
                  {Object.entries(choices).map(([choiceKey, choiceValue]) => (
                    <Label key={choiceKey} check className='checkbox_container d-flex gap-2'>
                      <Input
                        type="checkbox"
                        name={choiceKey}
                        checked={selectedChoices.choiceKey}
                        onChange={(e) => { handleChoiceToggle(e) }}
                      />
                      {choiceValue}
                    </Label>
                  ))}
                </div>
              </div>
              {showMaskReason && (
                <div className='mb-2'>
                  <span>What was the reason for the mask change? {requiredLabel()}</span>
                  <Input
                    type='text'
                    name='refitFeedback'
                    onChange={(e) => { setOtherReason(e.target.value) }}
                  />
                </div>)}
            </Col>
          </Row>

          {/* Save / Cancel  */}
          <div className='d-flex justify-content-between flex-row-reverse mt-1'>
            <Button
              id='save-refit-bt'
              disabled={!isFormValid}
              color='primary'
              size='large'
              className='btn-primary'
              onClick={() => {
                const refitAnswers = {
                  "switched_to_mask_type": selectedMaskName,
                  "switch_reason_multiple": selectedChoices,
                  "initial_setup_date": setUpDate,
                  "switched_to_mask_size": cushionSize.value,
                  "switched_to_mask_frame_size": frameSize.value,
                  "chin_strap_dispensed": chinStrapDispensed.value,
                  "switch_reason": "",
                  "switch_reason_other": otherReason,
                  "switched_to_mask_type_other": otherMaskName,
                };
                handleRefitSave(refitAnswers)
              }}>
              {isFromOtherMask ? 'Complete (Dispense) With Other Mask' : languageOptions.save[langType]}
            </Button>
            <Button
              color='danger'
              size='large'
              onClick={handleRefitCancel}
            >
              {languageOptions.cancel[langType]}
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default collect(PatientRefitForm)