import React, { useState } from 'react'
import { appStore } from '../store/store';
import { collect } from 'react-recollect';
import { Button, Input, Row, Col, Label, FormFeedback, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Select from "react-select";
import ToolTip from './ToolTip';
import { DropdownIndicator } from './BranchSelector';

const LocationModal = ({ store, onLocationSave }) => {
  const [locationMovingTo, setLocationMovingTo] = useState(null);

  const closeModal = () => {
    store.showCreateLocationModal = false;
    store.showEditLocationModal = false;
    setLocationMovingTo(null);
  }

  const moveLocation = () => {
    appStore.moveLocation(store.selectedLocationId, locationMovingTo)
    setLocationMovingTo(null)
  }

  return (
    <Modal
      isOpen={store.showCreateLocationModal || store.showEditLocationModal}
      className='modal-lg'
      toggle={closeModal}
    >
      <ModalHeader toggle={closeModal}>
        {store.showCreateLocationModal ? `Add New Location - ${store.selectedLocationDBAName}` :
          store.showEditLocationModal ? `Edit ${store.selectedLocationDBAName} - ${store.selectedLocationShortName}` : ''
        }
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md='6'>
            <Label htmlFor='dbaName'>
              Business Name:
              <ToolTip id='location-business-name-tooltip' content={<span>The business, brand, or Doing Business As name that should appear to patients.<br /> This name should match the logo. Example: YourCorp Home Medical</span>} />
            </Label>
            <Input
              invalid={false}
              type='text'
              id='dbaName'
              placeholder='Enter Name..'
              value={store.selectedLocationDBAName}
              onChange={(e) => {
                store.selectedLocationDBAName = e.target.value;
              }}
            />
          </Col>
          <Col md='6'>
            <Label htmlFor='locationName'>
              Location (e.g. City, State):
              <ToolTip id='location-loc-name-tooltip' content={<span>The retail location, as it would appear in an advertisement or map listing.<br />This name should uniquely identify the location, among your branches. Example: West Springfield, CA</span>} />
            </Label>
            <Input
              invalid={false}
              type='text'
              id='locationName'
              placeholder='Enter Name..'
              value={store.selectedLocationShortName}
              onChange={(e) => {
                store.selectedLocationShortName = e.target.value;
              }}
            />
            <FormFeedback>
              Please enter a unique location name.
            </FormFeedback>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md='6'>
            <Label htmlFor='branchNumber'>
              Branch Number:
              <ToolTip id='location-branch-num-name-tooltip' content={<span><b>Optional.</b> A unique ID number that you use to identify each branch for billing purposes.</span>} />
            </Label>
            <Input
              invalid={false}
              type='text'
              id='branchNumber'
              placeholder='Branch ID # (optional)'
              value={store.selectedLocationBranchNumber}
              onChange={(e) => {
                store.selectedLocationBranchNumber = e.target.value;
              }}
            />
          </Col>
          <Col md='6'>
            <Label htmlFor='npiNumber'>
              NPI Number:
              <ToolTip id='location-npi-num-name-tooltip' content={<span>The NPI number for this location.</span>} />
            </Label>
            <Input
              type='text'
              id='npiNumber'
              value={store.selectedLocationNPINumber}
              disabled={store.showEditLocationModal && !store.currentUser.isStaff}
              onChange={(e) => {
                store.selectedLocationNPINumber = e.target.value;
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md='8'>
            <Label htmlFor='address'>
              Address:
              <ToolTip id='location-address-tooltip' content={<span>Address associated with the NPI number.</span>} />
            </Label>
            <Input
              type='text'
              id='address'
              value={store.selectedLocationAddress}
              disabled={store.showEditLocationModal && !store.currentUser.isStaff}
              onChange={(e) => {
                store.selectedLocationAddress = e.target.value;
              }}
            />
          </Col>
          <Col md='4'>
            <Label htmlFor='phoneNumber'>
              Phone Number:
              <ToolTip id='location-phone-num-name-tooltip' content={<span>Phone associated with the NPI number.</span>} />
            </Label>
            <Input
              type='text'
              id='phoneNumber'
              value={store.selectedLocationPhoneNumber}
              disabled={store.showEditLocationModal && !store.currentUser.isStaff}
              onChange={(e) => {
                store.selectedLocationPhoneNumber = e.target.value;
              }}
            />
          </Col>
        </Row>
        <br />
        <Label htmlFor='timezoneDropdown'>
          Timezone
        </Label>
        <Select
          options={[
            { label: "Default (Recommended)", value: "" },
            { label: "America/New_York", value: "America/New_York" },
            { label: "America/Los_Angeles", value: "America/Los_Angeles" },
            { label: "America/Chicago", value: "America/Chicago" },
            { label: "America/Denver", value: "America/Denver" },
            { label: "America/Anchorage", value: "America/Anchorage" },
            { label: "Pacific/Honolulu", value: "Pacific/Honolulu" },
            { label: "America/Puerto_Rico", value: "America/Puerto_Rico" },
          ]}
          onChange={(e) => {
            store.selectedLocationTimezone = e;
          }}
          value={store.selectedLocationTimezone}
          name='timezoneDropdown'
          id='timezoneDropdown'
        />
        <br />

        <Label htmlFor='newPatientFormulary'>Default New Patient Formulary
          <ToolTip id='location-new-formulary-tooltip' content={<span>Select the mask formulary to be used by default for <b>new patients</b> at this location. <br /> Users with Clinician access or higher can override this setting when appropriate.</span>} />
        </Label>
        <Select
          name='newPatientFormulary'
          id='newPatientFormulary'
          options={appStore.getFormularyNameOptions()}
          value={{
            value: store.selectedLocationNewPatientFormularyName,
            label: store.selectedLocationNewPatientFormularyName,
          }}
          isSearchable="true"
          onChange={(e) => {
            store.selectedLocationNewPatientFormularyName = e.value;
          }}
        />
        <br />

        <Label htmlFor='resupplyPatientFormulary'>Default Resupply Formulary
          <ToolTip id='location-resupply-formulary-tooltip' content={<span>Select the mask formulary to be used by default for <b>resupply patients</b> at this location. <br /> Users with Clinician access or higher can override this setting when appropriate.</span>} />
        </Label>
        <Select
          name='resupplyPatientFormulary'
          id='resupplyPatientFormulary'
          options={appStore.getFormularyNameOptions()}
          value={{
            value: store.selectedLocationResupplyFormularyName,
            label: store.selectedLocationResupplyFormularyName,
          }}
          isSearchable="true"
          onChange={(e) => {
            store.selectedLocationResupplyFormularyName = e.value;
          }}
        /><br />

        {store.showEditLocationModal && store.currentUser.isStaff &&
          <>
            <Label htmlFor='moveLocation'>
              Move Location To:
            </Label>
            <Select
              id='moveLocation'
              components={{ DropdownIndicator }}
              placeholder='Select a branch'
              options={store.customerUsernames.filter(locs => locs.value !== store.currentSubdomain.customerSubdomain)}
              isSearchable={true}
              onChange={(e) => setLocationMovingTo(e.value)}
            />
          </>
        }
      </ModalBody>
      <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color='danger'
          style={{ visibility: store.currentUser.isStaff && store.showEditLocationModal ? 'visible' : 'hidden' }}
          onClick={() => {
            appStore.archiveLocation(store.selectedLocationId, store.selectedLocationDBAName, store.selectedLocationShortName)
            store.showEditLocationModal = false;
          }}
          disabled={store.archiveLocationLoading}
        >
          <i className='fas fa-archive mr-1' />
          Archive Location
        </Button>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button color='primary' onClick={() => {
            onLocationSave();
            closeModal();
            if (locationMovingTo) moveLocation();
          }}>Save</Button>
          <Button color='secondary' onClick={closeModal}>Cancel</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default collect(LocationModal)
