/* eslint-disable */
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Row, Col, CardBody, Card, Progress, Input, Label, Button } from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
import { collect, store } from 'react-recollect';
import { appStore } from '../store/store';
import { getLangType, readCookieValue, getSingleUrlParam, getCurrentDomain, subdomain } from '../utils/utils';
import { languageOptions } from '../language';
import './Login.css';
import ToolTip from '../components/ToolTip';
import { useQuery } from 'react-query';
import ResendMfa from './ResendMfa';
import MicrosoftLoginButton from './MicrosoftLoginButton';
import { isMobile } from "react-device-detect";
import LoadingCircles from '../components/LoadingCircles';

let MicrosoftLogin;
if (isMobile) {
  MicrosoftLogin = require('./MicrosoftLogin').default;
} else {
  MicrosoftLogin = lazy(() => import('./MicrosoftLogin'));
}

const langType = getLangType();

const Login = (props) => {
  const [username, setUsername] = useState('')
  const [isValidUser, setIsValidUser] = useState(false)
  const [password, setPassword] = useState('')
  const [oneTimePass, setOneTimePass] = useState('')
  const [lazyComponentImported, setLazyComponentImported] = useState(false)

  const path = localStorage.getItem('path')

  const { data: isMicrosoftLoginEnabled } = useQuery(
    'isMicrosoftLoginEnabled',
    () => appStore.isMicrosoftLoginEnabled(),
    { refetchOnWindowFocus: false }
  )

  const handleValidSubmit = async (e) => {
    e.preventDefault()
    setIsValidUser(await appStore.login({ username, password, isValidUser }))
  };

  const handleValidVerify = () => {
    appStore.validate2fa({ one_time_pass: oneTimePass });
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      if (!isValidUser) {
        setIsValidUser(await appStore.login({ username, password, isValidUser }))
      } else {
        handleValidVerify()
      }
    }
  };

  const submitFromCookie = async (data) => {
    setIsValidUser(await appStore.login(data))
  }

  useEffect(() => {
    const { previousPath } = props.history.location
    const param = getSingleUrlParam();
    const token = readCookieValue('token');

    if (previousPath) {
      localStorage.setItem('path', param || previousPath);
    }

    if (token && param) {
      submitFromCookie({ token });
    }

    return () => {
      if (appStore.isLoggedIn() && (!store.initialApisCalled && !store.initialApisLoading)) {
        appStore.callInitialAPIs()
      }
      if (localStorage.getItem('passwordResetRequired') == 'false') {
        localStorage.removeItem('path')
      }
    }
  }, [])

  if (appStore.isLoggedIn()) {
    console.log('User is already logged in, redirecting to /dashboard or /path')
    return <Redirect to={path || '/dashboard'} />;
  }

  return (
    <div className={`account-pages my-5 pt-sm-5 ${store.mfaLoading ? 'processing' : ''}`}>
      <div className='container'>
        <Row className='justify-content-center'>
          <Col md={8} lg={6} xl={5}>
            {(!isValidUser && readCookieValue('token') && getSingleUrlParam()) ?
              (
                <>
                  <Row style={{ display: "flex", alignItems: "flex-start" }}>
                    <img
                      src={encodeURI(`/rest/customer_logo?subdomain=${subdomain}`)}
                      width='100%'
                      height='auto'
                      style={{ padding: '0 12px', backgroundColor: 'white' }}
                      alt='no-label'
                    />
                  </Row>
                  <h6 className='text-center mt-3 mb-3'> Logging in to {subdomain}.{getCurrentDomain()} ...</h6>
                </>
              )
              : (
                <Card className='overflow-hidden'>
                  <div className='background-login'>
                    <Row>
                      <Col className='col-12'>
                        <div className='login-text p-4'>
                          <h5 className='login-text'>
                            {languageOptions.welcome[langType]}
                          </h5>
                          <p>{`Sign in to access your SleepGlad dashboard`}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", alignItems: "flex-start" }}>
                      <img
                        src={encodeURI(`/rest/customer_logo?subdomain=${subdomain}`)}
                        width='100%'
                        height='auto'
                        style={{ padding: '0 12px', backgroundColor: 'white' }}
                        alt='no-label'
                      />
                    </Row>
                  </div>
                  <CardBody className='pt-2'>
                    {!isMicrosoftLoginEnabled && (
                      <div className='p-2 d-flex flex-column gap-3'>
                        {!(getSingleUrlParam() && readCookieValue('token')) && (
                          <div>
                            <Label htmlFor='username'>
                              {languageOptions.username[langType]}
                            </Label>
                            <Input
                              type='text'
                              id='username'
                              placeholder='email'
                              required
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              onKeyDown={handleKeyDown}
                              disabled={isValidUser}
                            />
                          </div>)}
                        {(!(getSingleUrlParam() && readCookieValue('token')) && subdomain !== 'login') && (
                          <div>
                            <Label htmlFor='password'>
                              {languageOptions.password[langType]}
                            </Label>
                            <Input
                              type='password'
                              id='password'
                              placeholder='password'
                              required
                              onKeyDown={handleKeyDown}
                              onChange={(e) => setPassword(e.target.value)}
                              disabled={isValidUser}
                            />
                          </div>
                        )}

                        {(subdomain !== 'login') && (isValidUser) && (
                          <div>
                            <Label htmlFor='oneTimePass'>
                              {languageOptions.oneTimePass[langType]}
                              <ToolTip
                                id={'2fa-tooltip'}
                                iconType={'fa-info-circle'}
                                content={<span>Please enter the authentication code you received <br />
                                  on your registered {store.userMfaChannel === 'email' ? 'email' : 'phone'}</span>}
                              />
                            </Label>
                            <Input
                              required
                              type='password'
                              id='oneTimePass'
                              placeholder='123456'
                              onChange={(e) => setOneTimePass(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        )}

                        {(!isValidUser) && (
                          <div className='mt-1'>
                            <Button
                              block
                              color='primary'
                              disabled={(!username || !password) && (subdomain !== 'login')}
                              onClick={handleValidSubmit}
                            >
                              {languageOptions.login[langType]}
                            </Button>
                          </div>
                        )}

                        {(isValidUser) && (
                          <div className='mt-1'>
                            <Button
                              block
                              color='primary'
                              disabled={(!oneTimePass) && (subdomain !== 'login')}
                              onClick={handleValidVerify}
                              type='submit'
                            >
                              {languageOptions.verifyOtp[langType]}
                            </Button>
                          </div>
                        )}

                        {(subdomain !== 'login') && (isValidUser) &&
                          <ResendMfa username={username} />
                        }
                      </div>
                    )}

                    {store.ssoLoginLoading || store.isCurrentVersionLoading ?
                      <LoadingCircles className='d-flex justify-content-center py-3' /> :
                      !isMobile ?
                        <div className='p-2'>
                          {isMicrosoftLoginEnabled && (
                            !lazyComponentImported ?
                              <MicrosoftLoginButton handleClick={() => setLazyComponentImported(true)} />
                              : (
                                <Suspense fallback={'Loading...'}>
                                  <MicrosoftLogin />
                                </Suspense>
                              )
                          )}
                        </div> :
                        isMicrosoftLoginEnabled && <MicrosoftLogin />
                    }

                    {(subdomain !== 'login') && !isMicrosoftLoginEnabled && (
                      <div className='mt-1 text-center'>
                        <a href='/forget-password' style={{ color: '#5cb504', fontWeight: 'bold' }}>
                          <i className='mdi mdi-lock mr-1'></i>
                          {languageOptions.forgotPassword[langType]}
                        </a>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            {store.loading && < Progress value={100} animated />}
            <div className='mt-5 text-center'>
              <p>
                ©2023 SleepGlad
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default collect(withRouter(Login));
