import React from "react";
import { appStore } from '../../store/store';
import dayjs from 'dayjs';
import Select from "react-select";
import { alert_label } from '../../pages/Alert';
import { Badge } from "reactstrap";
import { getStatusStylings } from '../../utils/utils';
import uuid from 'react-uuid';

export const PatientTableRowComponent = ({ data, isMobile }) => {
  const { location, currentMaskType, createdTimeFormatted, createdBy } =
    data;
  const { classname, color, info } = getStatusStylings(data.status, data)
  const tooltipID = uuid();
  var badgeStyle = { fontSize: '12px', marginLeft: '10px' };
  if (info.includes('Completed') && !info.includes('RX')) {
    badgeStyle = { fontSize: '12px', backgroundColor: '#253375' };
  }

  return (
    <div className="p-3">
      {isMobile && (
        <div className="row-body">
          <b>Status:</b>
          <Badge color={color} style={badgeStyle}>
            {info}
            <span style={{ paddingLeft: '2px' }}>
              {' '}
              <i className={classname} />
            </span>
          </Badge>
        </div>
      )}

      <div >
        <p style={{ margin: 0, fontSize: '1rem' }}><b>Selected Mask:</b> {currentMaskType || 'N/A'}</p>
        <p style={{ margin: 0, fontSize: '1rem' }}><b>Location:</b> {location}</p>
        {window && window.location.host.startsWith("rotech.") && (
          <p style={{ margin: 0, fontSize: '1rem' }}><b>Added By:</b> {createdBy}</p>
        )}
        <p style={{ margin: 0, fontSize: '1rem' }}><b>Created at:</b> {createdTimeFormatted}</p>
      </div>

    </div>
  );
};


export const PatientMessagesRowComponent = ({ data, isMobile, isTablet }) => {
  const { patient_id, first_name, last_name, location, pt_messages, isUnread } = data;

  return (
    <div className="p-3" style={{ color: 'var(--color-primary)', backgroundColor: isUnread ? 'rgba(251, 253, 188, 0.45)' : '' }}>
      {isMobile && (
        <p style={{ fontSize: '0.8rem', textAlign: 'end' }}>
          {dayjs(pt_messages[0].created_at).format('MM/DD/YYYY - hh:mm a')}
        </p>)
      }

      <div className="d-flex justify-content-between align-items-baseline" style={{ wordBreak: 'break-word' }}>
        <h5>{first_name} {last_name}</h5>
        <span style={{ fontSize: '1.4rem' }}>
          {
            isUnread ?
              <i className='fas fa-envelope' style={{ color: 'var(--color-gray-hover)' }} /> :
              <i className='fas fa-envelope-open' style={{ color: 'var(--color-light-gray)' }} />
          }
        </span>
      </div>

      <div >
        <p style={{ margin: 0, fontSize: '0.8rem' }}><b>ID:</b> {patient_id}</p>
        <p style={{ margin: 0, fontSize: '0.8rem' }}><b>Location:</b> {location}</p>
      </div>

      <div className={isTablet ? 'mt-4 text-right' : 'mt-4 text-center'}>
        <a
          style={{ fontSize: '1rem', color: 'var(--color-blue)' }}
          onClick={() => {
            appStore.showPatientDetails(data)
            appStore.getPatientMessagesFromThread(data.website_token, true);
          }}
        >
          View thread &rarr;
        </a>
      </div>
    </div>
  );
};


export const PatientAlertsRowComponent = ({ data, isMobile, isTablet, userOptions }) => {
  const { patient_id, first_name, last_name, location, alert_status, created_at, website_token, resolved_at, resolved_by, alert_id, assigned_to, alert_type } = data;
  const defaultValue = userOptions.filter((opt) => opt.value === assigned_to)
  const statusIcon = alert_status === 'Acknowledged' ? '📌' : alert_status === 'Resolved' ? '✅' : '';

  return (
    <div className="p-2" style={{ color: 'var(--color-primary)' }}>
      <p style={{ margin: 0 }}><b>Name: </b>{first_name} {last_name}</p>
      <p style={{ margin: 0, fontSize: '1rem' }}><b>ID: </b>{patient_id}</p>
      <p style={{ margin: 0, fontSize: '1rem' }}><b>Location: </b>{location}</p>

      <p style={{ margin: 0, fontSize: '1rem' }}>
        <b>Status: </b>
        <span style={{ margin: 0, fontSize: '1rem' }}>{statusIcon}</span>
        {alert_status === 'Acknowledged' ?
          <span> Acknowledged by </span>
          : alert_status === 'Resolved' ?
            <span> Resolved by </span>
            : <Badge color="info">New</Badge>
        }
        {resolved_by}
        <span style={{ margin: 0, fontSize: '1rem' }}>{resolved_at ? ' at ' + dayjs(resolved_at).format('MM/DD/YYYY - hh:mm a') : ''}</span>
      </p>

      <p style={{ fontSize: '1rem' }}><b>Alert Type:</b>
      {alert_label(alert_type).map((alert) => <p style={{ margin: 0, fontSize: '1rem' }}>{alert}</p>)}
      </p>

      <p style={{ fontSize: '1rem' }}><b>Assigned To:</b></p>
      <Select
        value={defaultValue}
        onChange={(e) => {
          appStore.resolveAlert({
            'alert_id': alert_id,
            'assigned_to': e.value, 'assigning': true
          })
        }}
        menuPortalTarget={document.body}
        isSearchable
        options={appStore.setAssigningOptions()}
        defaultValue={defaultValue} />

      <div className={isTablet ? 'mt-4 text-right' : 'mt-4 text-center'}>
        <a
          style={{ fontSize: '1rem', color: 'var(--color-blue)' }}
          onClick={() => {
            appStore.showPatientDetails(data)
            appStore.loadPatientAlerts(website_token)
          }}
        > Patient details &rarr;
        </a>
      </div>

    </div>
  );
};