import React from 'react';
import { Row, Col } from 'reactstrap';
import { collect } from 'react-recollect';
import { languageOptions } from '../language';
import { getMaskDisplayName, getLangType } from '../utils/utils'
import { getProductSKU } from './PatientDetails';
import ToolTip from './ToolTip';

const langType = getLangType();

const NonRecommendedOption = ({ state, selectedRow, shouldShowNROModal, setNonRecommendedOption, setRecommendedMask, isRecommendedMask }) => {

  const editionDisabled = !state.readOnly && !selectedRow.voided_at;
  const rowClassName = selectedRow.nro_enabled ? "recommendationSelectRow" : "recommendationSelectRowDisabled";
  const nonRecommendedOptions = selectedRow.refits.filter(refit => refit.switch_reason === 'non-recommended-opt');

  // Actual refit used in update
  const nonRecommendedMask = nonRecommendedOptions.length > 0 ? (({ type, submitted_by, submitted_at, ...nroDetails }) => nroDetails)(nonRecommendedOptions[0]) : null;

  // NRO for item selection and sku handling 
  const NRO = {
    name: nonRecommendedMask ? nonRecommendedMask?.switched_to_mask_type : 'Other Mask',
    cushion_size: nonRecommendedMask ? nonRecommendedMask?.switched_to_mask_size : 'N/A',
    frame_size: nonRecommendedMask ? nonRecommendedMask?.switched_to_mask_frame_size : 'N/A',
    chin_strap: nonRecommendedMask ? nonRecommendedMask?.chin_strap_dispensed : false
  }
  const isOther = nonRecommendedMask?.switched_to_mask_type === 'Other' && nonRecommendedMask?.switched_to_mask_type_other

  const setAsRecommendation = () => {
    if (selectedRow.nro_enabled && nonRecommendedMask) {
      setRecommendedMask(NRO);
      setNonRecommendedOption(nonRecommendedMask);
    }
  }

  const renderProductIds = () => {
    const { product_id, isFitpack, headgearId } = getProductSKU(NRO)
    return (
      <Row>
        <span>{languageOptions.productID[langType]}: <strong>{headgearId ? `${product_id}+${headgearId}` : product_id}</strong></span>
        {isFitpack && (
          <ToolTip
            id={'fitpack-tooltip-' + nonRecommendedMask?.switched_to_mask_type}
            content={<span>This SKU is part of a <strong>FitPack</strong>.</span>}
            iconType={'fa-info-circle'}
          />
        )}
        {nonRecommendedMask?.switched_to_mask_type === 'ResMed P10' && (
          <ToolTip
            id={'resmed-p10-tooltip-' + nonRecommendedMask?.switched_to_mask_type}
            content={<span>If you choose <strong>Pink</strong> or <strong>XS size</strong> use SKU: 62910.</span>}
            iconType={'fa-info-circle'}
          />
        )}
      </Row>
    )
  }

  return (
    <div className='recommendedMasks-container' >
      <small><strong>Non-Recommended Option</strong></small>

      <Row className={editionDisabled ? rowClassName : 'recommendationSelectRowNoHover'}>
        {nonRecommendedMask &&
          <>
            <Col xl={6} className="mask-info mb-2 mb-xl-0" onClick={() => { setAsRecommendation() }}>
              <div className="mask-check">
                {(editionDisabled) &&
                  <input type="radio"
                    disabled={!selectedRow.nro_enabled}
                    value={nonRecommendedMask?.switched_to_mask_type}
                    checked={state.model_output && (nonRecommendedMask?.switched_to_mask_type === state.selectedRecommendationMaskType)}
                  />
                }
                <strong
                  className={selectedRow.nro_enabled ? null : 'text-disabled'}
                  style={{ marginLeft: '10px' }}
                >
                  {isOther ? `Other (External): ${nonRecommendedMask?.switched_to_mask_type_other}` : getMaskDisplayName(nonRecommendedMask?.switched_to_mask_type)}
                </strong>
              </div>
            </Col>
            <Col xl={6}>
              <Row className='justify-content-between mb-2'>
                <Col lg={5} className='d-flex align-items-center'>
                  {languageOptions.cushionSize[langType]}:&nbsp;<strong>{nonRecommendedMask?.switched_to_mask_size}</strong>
                </Col>
              </Row>
              <Row className='justify-content-between mb-2'>
                <Col lg={5} className='d-flex align-items-center'>
                  {languageOptions.frameSize[langType]}:&nbsp;<strong>{nonRecommendedMask?.switched_to_mask_frame_size}</strong>
                </Col>
              </Row>
              {!isOther && (renderProductIds())}
            </Col>
          </>
        }

        {!nonRecommendedMask && (
          <Col xl={6}
            className="mask-info mb-2 mb-xl-0 pt-3 pb-3"
            onClick={() => {
              if (selectedRow.nro_enabled) {
                setRecommendedMask(NRO);
                shouldShowNROModal(true);
              }
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="mask-check">
                {(editionDisabled) &&
                  <input type="radio"
                    disabled={!selectedRow.nro_enabled}
                    value='Other'
                    checked={isRecommendedMask({ name: 'Other Mask' })}
                  />
                }
                <strong className={selectedRow.nro_enabled ? null : 'text-disabled'} style={{ marginLeft: '10px' }}> Other mask </strong>
              </div>
              <ToolTip
                id='mask_other_tooltip'
                content={<span>This is not considered best practice, and may result in decreased compliance.</span>}
                iconType={'fa-info-circle'}
              />
            </div>
          </Col>
        )}
      </Row>

    </div>
  )
}


export default collect(NonRecommendedOption);

