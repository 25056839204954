import { appStore } from "../store/store";
import { readCookieValue } from '../utils/utils';


class StatusSocket {
    static instance = null;

    static getInstance() {
        if (!StatusSocket.instance) {
            StatusSocket.instance = new StatusSocket();
        }
        return StatusSocket.instance;
    }

    constructor() {
        this.socketRef = null;
    }

    connect() {
        const prefix = (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host;
        const subdomain = window.location.host.split('.')[0];
        let token = readCookieValue('token')
        this.socketRef = new WebSocket(`${prefix}/ws/status/${subdomain}?token=${token}`);
        this.socketRef.onmessage = data => {
            let message = JSON.parse(data.data)
            if ( message?.type && message?.type === 'system_notification' ) {
                // store/update notification on localStorage
                appStore.handleSystemNotification(message)
            } else {
                appStore.updatePatientStatus(message)
            }
        };
    }

    close() {
        this.socketRef.close();
    }
}

const StatusSocketInstance = StatusSocket.getInstance();

export default StatusSocketInstance;
