import { createRoot } from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './azureAuthConfig'
import 'bootstrap/dist/css/bootstrap.min.css';

const queryClient = new QueryClient();

const pca = new PublicClientApplication(msalConfig)

pca.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    console.log('event from event callback:', event)
    pca.setActiveAccount(event.payload.account)
  }
})

createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
    <App msalInstance={pca} />
    <ReactQueryDevtools />
  </QueryClientProvider>
)