import React, { useState } from "react";
import { appStore } from '../store/store';
import { store } from "react-recollect";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toastError } from "../utils/utils";

const ResendMfa = ({ username }) => {
  const [optedIn, setOptedin] = useState(store.userMfaSmsOptIn)
  const [showModal, setShowModal] = useState(false)

  const sendMfaByText = async () => {
    // First check if user's phone number is ok.
    if (!store.userPhone) {
      toastError('You do not have a phone number set.')
      return false
    }
    const data = await appStore.twilioLookupPhone(store.userPhone, true)
    if (data) {
      const { numberLookupStatus, numberLookupCode, numberIsSMSReachable } = data
      if (numberLookupStatus !== 200) {
        toastError('You have a missing or invalid phone number.')
        return false
      } else if (!numberIsSMSReachable) {
        toastError('Your phone number is not SMS reachable.')
        return false
      } else {
        // API post req to make user opt in to receive text messages and send MFA code
        if (!store.mfaLoading) {
          appStore.sendMfaCode({ mfaChannel: 'sms', userPhone: store.userPhone })
        }
        return true
      }
    }
  }

  const onAgreeConsent = async () => {
    const status = await sendMfaByText()
    if (status) {
      setOptedin(true)
      appStore.setUserHistory({
        created_for: username,
        created_by: username,
        action: [`${username} turned on "Agree to Receive texts".`]
      })
    }
    toggleModal()
  }

  const toggleModal = () => setShowModal(!showModal)

  return (
    <>
      <div className='d-flex justify-content-between pt-1' style={{ textAlign: 'center' }}>
        <a
          className='fw-medium link-primary'
          onClick={() => {
            if (!store.mfaLoading) appStore.sendMfaCode({ mfaChannel: 'email' })
          }}
        >
          Resend Code by Email
        </a>
        <a
          className='fw-medium link-primary'
          onClick={() => {
            if (optedIn) sendMfaByText()
            else toggleModal()
          }}
        >
          Resend Code by Text
        </a>
      </div>
      <Modal centered isOpen={showModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Consent to Receive Text Messages
        </ModalHeader>
        <ModalBody>
          Do you agree to receive authentication codes as text messages to your mobile? Msg & data rates may apply. You will receive text messages only after each attempt to login. If you want to stop receiving SMS for authentication codes, you can opt out from it in your profile settings.{' '}
            <a
              href={'https://www.sleepglad.com/terms'}
              target="_blank" rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
        </ModalBody>
        <ModalFooter>
          <Button className='px-3' size='sm' color='secondary' onClick={toggleModal}>
            No
          </Button>
          <Button size='sm' color='primary' onClick={onAgreeConsent} >
            Yes, I agree
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
};

export default ResendMfa;
