import React, { Component } from 'react';
import { Alert } from 'reactstrap'
import { appStore } from '../store/store';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error or perform any additional error handling 
    console.log(error);
    this.setState({ error, errorInfo });
    appStore.postFrontEndError(error.toString(), errorInfo.componentStack)
  }

  render() {
    if (this.state.hasError) {
      // Render the error message and componentStack information
      return (
        <Alert color='danger' className='d-flex align-items-center m-3'>
          <i className="fas fa-exclamation-triangle mr-3" style={{ fontSize: '35px' }}></i>
          <div>
            <h3>Something went wrong!</h3>
            Please refresh the page or try again. <br />
            {this.state.error && this.state.error.toString()}.
            <br />
            {/* <details
            className='mt-2'
            style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details> */}
          </div>
        </Alert>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
