import React from 'react'
import Button from '@mui/material/Button'
import MicrosoftIcon from '@mui/icons-material/Microsoft'

const MicrosoftLoginButton = ({ handleClick }) => {
  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      startIcon={<MicrosoftIcon style={{ fontSize: '23px' }} />}
      sx={{ textTransform: 'none', fontFamily: 'Roboto, Arial, sans-serif', marginTop: '1rem', width: '100%' }}
    >
      Log in with Microsoft
    </Button>
  )
}

export default MicrosoftLoginButton