/* eslint-disable */
import React, { Component } from 'react';
import { collect } from 'react-recollect';
import { Row, Col, Label, Input, Alert, Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import ToolTip from './ToolTip';
import { Tooltip } from 'react-tooltip';
import uuid from 'react-uuid';
import { getLangType } from '../utils/utils';
import { languageOptions } from '../language';
import { appStore } from '../store/store';
import Loading from './Loading';

const langType = getLangType();

class ReferringPhysicianLookup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      provider: {
        npiNumber: '',
        firstName: '',
        lastName: '',
        city: '',
        state: '',
        //postalCode: '',
      },
      suggestions: [],
      found: false,
      message: '',
      messageType: '',
      loading: false,
      confirmationModal: false,
      missingPhysician: false,
      hadLookedUp: false
    };
  }

  onChange = (paramName, value) => {
    const provider = Object.assign(this.state.provider);
    provider[paramName] = value;
    let found = false;
    if (paramName === 'npiNumber') {
      found = true;
    }
    this.setState({
      provider,
      found,
    });
  };

  save = () => {
    if (!this.state.hadLookedUp && !this.state.confirmationModal && this.state.provider.firstName && this.state.provider.lastName) {
      this.toggleConfirmationModal();
    } else if (this.props.isEdit) {
      appStore.editData(
        {
          referring_npi: this.state.provider.npiNumber,
          referring_name: `${this.state.provider.firstName} ${this.state.provider.lastName}`,
          patient_subdomain: this.props.selectedRow.subdomain,
          website_token: this.props.selectedRow.website_token,
        },
        'referring_name',
        'referring_name',
      );
      this.props.store.selectedRow.referring_name = `${this.state.provider.firstName} ${this.state.provider.lastName}`
      this.props.toggle();
    } else {
      this.props.store.newPatient.referringPhysicianNumber = this.state.provider.npiNumber;
      this.props.store.newPatient.referringPhysicianFullName = `${this.state.provider.firstName} ${this.state.provider.lastName}`;
      this.props.toggle();
    }
    if(this.props.shouldCompletePatient){
      this.props.completeCallBack()
    }
  };

  lookup = () => {
    this.setState({ loading: true })
    // make sure all values are URL safe, remove any empty white space
    Object.keys(this.state.provider).map(field => this.state.provider[field] = this.state.provider[field].trim());
    appStore
      .lookupHealthCareProvider(this.state.provider)
      .then((results) => {
        let provider = Object.assign({}, this.state.provider);
        if (results.provider)
          provider = results.provider;
        const suggestions = results.suggestions;
        const found = results.found;
        const message = results.message;
        const messageType = results.messageType;

        this.setState({
          provider,
          suggestions,
          found,
          message,
          messageType,
          loading: false,
          hadLookedUp: true,
        });
      });
  };

  suggestionsColumns = [
    {
      name: 'NPI Number',
      selector: row => row.npiNumber,
      sortable: true,
      cell: (provider) => {
        const tooltipID = uuid();
        const message = '';
        return (
          <>
            <a
              className='link-primary'
              data-tooltip-id={tooltipID}
              data-tooltip-content={'Click to select physician'}
              onClick={() => {
                const found = true;
                this.setState({
                  provider,
                  found,
                  message,
                });
              }}
            >
              {provider.npiNumber}
            </a>
            <Tooltip id={tooltipID} position='bottom' />
          </>
        );
      },
    },
    {
      name: 'First Name',
      selector: row => row.firstName,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row.lastName,
      sortable: true,
    },
    {
      name: 'City',
      selector: row => row.city,
    },
    {
      name: 'State',
      selector: row => row.state,
    },
    {
      name: 'Address Type',
      selector: row => row.addressType,
    },
  ];

  toggleConfirmationModal = () => this.setState({ confirmationModal: !this.state.confirmationModal });

  close = () => {
    if (this.props.store.newPatient.isScripted && !(this.props.store.newPatient.referringPhysicianFullName || this.props.store.selectedRow.referring_name)) {
      this.setState({ missingPhysician: true })
      this.toggleConfirmationModal();
    } else {
      this.props.toggle();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='outer'>
          <div className='form-group'>
            <Row className='mb-2'>
              <Col md='4'>
                <Label htmlFor='formname'>
                  NPI Number
                  <ToolTip id='npi-number-tooltip' content={<span>The unique 10-digit National Provider Identifier assigned to the provider.</span>} />
                </Label>
                {!this.props.store.currentSubdomain.npi_client ?
                  <Input
                    type='text'
                    id='number'
                    placeholder='10 digit NPI number...'
                    value={this.state.provider.npiNumber}
                    onChange={(e) => this.onChange('npiNumber', e.target.value)}
                  />
                  :
                  <Input
                    type='select'
                    placeholder='10 digit NPI number...'
                    onChange={(e) => this.onChange('npiNumber', e.target.value)}
                  >
                    <option value={this.props.store.currentSubdomain.npi_number} selected disabled>10 digit NPI number...</option>
                  </Input>
                }
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md='6'>
                <Label htmlFor='formname'>
                  First Name
                  <ToolTip id='npi-first-name-tooltip' content={<span>Physician's first name. Applies only to individual providers.</span>} />
                </Label>
                <Input
                  disabled={this.props.store.currentSubdomain.npi_client}
                  type='text'
                  id='firstName'
                  value={this.state.provider.firstName}
                  placeholder='First Name..'
                  onChange={(e) => this.onChange('firstName', e.target.value)}
                />
              </Col>
              <Col md='6'>
                <Label htmlFor='formname'>
                  Last Name
                  <ToolTip id='npi-last-name-tooltip' content={<span>Physician's last name. Applies only to individual providers.</span>} />
                </Label>
                <Input
                  disabled={this.props.store.currentSubdomain.npi_client}
                  type='text'
                  id='lastName'
                  placeholder='Last Name..'
                  value={this.state.provider.lastName}
                  onChange={(e) => this.onChange('lastName', e.target.value)}
                />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md='4'>
                <Label htmlFor='formname'>
                  City
                  <ToolTip id='npi-city-tooltip' content={<span>The City associated with the provider's address. To search for a Military Address enter either APO or FPO.</span>} />
                </Label>
                <Input
                  disabled={this.props.store.currentSubdomain.npi_client}
                  type='text'
                  id='city'
                  placeholder='City...'
                  value={this.state.provider.city}
                  onChange={(e) => this.onChange('city', e.target.value)}
                />
              </Col>
              <Col md='2'>
                <Label htmlFor='formname'>
                  State
                  <ToolTip id='npi-state-tooltip' content={<span>The state abbreviation associated with the provider's address. This field cannot be used as the only input criterion.</span>} />
                </Label>
                <Input
                  disabled={this.props.store.currentSubdomain.npi_client}
                  type='text'
                  id='state'
                  placeholder='ST'
                  value={this.state.provider.state}
                  onChange={(e) => this.onChange('state', e.target.value)}
                />
              </Col>
              {/* <Col md='4'>
                <Label htmlFor='formname'>
                  Postal Code
                  <ToolTip id='npi-postal-code-tooltip' content={<span>The postal code associated with the provider's address. 5 digit postal codes match any appropriate 9 digit codes.</span>} />
                </Label>
                <Input
                  disabled={this.props.store.currentSubdomain.npi_client}
                  type='text'
                  id='postalCode'
                  placeholder='12345...'
                  value={this.state.provider.postalCode}
                  onChange={(e) => this.onChange('postalCode', e.target.value)}
                />
              </Col> */}
            </Row>
          </div>
          {this.state.message && (
            <Alert color={this.state.messageType}>
              {this.state.message}
            </Alert>
          )}
          <div>
            <Row>
              <Col md='2'>
                <Button color='primary'
                  onClick={this.lookup}
                  disabled={
                    (Object.values(this.state.provider).every(value => value === '')) || // empty fields
                    (Object.values(this.state.provider).filter(value => value !== '').length === 1 && this.state.provider.state !== '')} // Only state provided
                >
                  {languageOptions.lookup[langType]}{' '}
                  <span style={{ paddingLeft: '2px' }}>
                    <i className='fa fa-search' />
                  </span>
                </Button>
              </Col>
              <Col md='2'>
                <Button color='info'
                  onClick={() => {
                    this.setState({
                      provider: {
                        npiNumber: '',
                        firstName: '',
                        lastName: '',
                        city: '',
                        state: '',
                        //postalCode: '',
                      },
                      suggestions: [],
                      found: false,
                      message: '',
                      messageType: '',
                    });
                  }}>
                  {languageOptions.clear[langType]}{' '}
                  <span style={{ paddingLeft: '2px' }}>
                    <i class="fa fa-eraser"></i>
                  </span>
                </Button>
              </Col>
              <Col md='8'>
                <div className='d-flex justify-content-end gap-2'>
                  <Button color='secondary' onClick={this.close}>
                    {languageOptions.close[langType]}
                  </Button>
                  <Button color='success'
                    onClick={this.save}
                    disabled={
                      (this.props.isEdit && (!this.state.found || !this.state.provider.npiNumber || this.state.provider.npiNumber.length != 10)) ||
                      !(this.state.provider.firstName && this.state.provider.lastName)
                    }
                  >
                    {languageOptions.save[langType]}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col sm='12'>
                {this.state.loading ?
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                    <Loading />
                  </div> :
                  <DataTable
                    columns={this.suggestionsColumns}
                    noHeader
                    data={this.state.suggestions}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    defaultSortField='firstName'
                    noDataComponent=''
                  />
                }
              </Col>
            </Row>
          </div>
        </div>

        <Modal
          isOpen={this.state.confirmationModal}
          toggle={this.toggleConfirmationModal}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggleConfirmationModal}>
            {this.state.missingPhysician ? (
              <><b>Physician information was not provided</b>. Are you sure you want to continue?</>
            ) : (
              <>Would you like to lookup the physician before saving?</>
            )}

          </ModalHeader>
          <ModalFooter className='justify-content-right'>
            {this.state.missingPhysician ? (
              <div className='d-flex gap-2'>
                <Button
                  color='primary'
                  onClick={this.toggleConfirmationModal}>
                  {languageOptions.lookup[langType]}{' '}
                  <span style={{ paddingLeft: '2px' }}>
                    <i className='fa fa-search' />
                  </span>
                </Button>
                <Button
                  color='secondary'
                  onClick={() => {
                    this.toggleConfirmationModal()
                    this.props.toggle()
                  }}
                >
                  {languageOptions.confirm[langType]}
                </Button>
              </div>
            ) : (
              <div className='d-flex gap-2'>
                <Button
                  color='success'
                  onClick={() => {
                    this.toggleConfirmationModal()
                    this.save()
                  }}
                >
                  {languageOptions.save[langType]}
                </Button>

                <Button color='primary' onClick={() => {
                  this.toggleConfirmationModal()
                  this.lookup()
                }}>
                  {languageOptions.lookup[langType]}{' '}
                  <span style={{ paddingLeft: '2px' }}>
                    <i className='fa fa-search' />
                  </span>
                </Button>
              </div>
            )}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default collect(ReferringPhysicianLookup);
