import React, { useState } from "react";

import { Input, FormGroup, Label } from 'reactstrap';
import { collect } from "react-recollect";
import { Progress, Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { languageOptions } from "../language";
import { getLangType } from "../utils/utils";
import { appStore } from "../store/store";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CustomDatePicker from './CustomDatePicker.js';

const Filters = ({ components }) => {
    const langType = getLangType();
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const handleFilterClick = () => {
        setIsFilterOpen(!isFilterOpen)
    }

    const renderComponents = ({ type, label, renderProps }) => {
        return (
            <div key={renderProps.id} className="filter-input">
                <FormGroup className="w-100">
                    <Label>{label}</Label>
                    {(() => {
                        switch (type) {
                            case 'datePicker':
                                return <CustomDatePicker {...renderProps} />
                            case 'select':
                                return <Select {...renderProps}
                                />
                            case 'input':
                                return <Input {...renderProps} />
                            default:
                                return null
                        }
                    })()}
                </FormGroup>
            </div>
        )
    }

    return (
        <Card>
            <CardHeader className="card-header-custom" onClick={handleFilterClick}>
                <h5 className="card-title-custom" >Filter</h5>
                <button type="button" className="btn btn-tool" >
                    {isFilterOpen ?
                        <i style={{ color: 'var(--color-primary)' }} className="fas fa-caret-up"></i> :
                        <i style={{ color: 'var(--color-primary)' }} className="fas fa-caret-down"></i>}
                </button>
            </CardHeader>
            <Collapse isOpen={isFilterOpen}>
                <CardBody className="filter-box">
                    {components.map((comp) => (
                    <span key={comp.renderProps.id} className="filter-element">
                        {renderComponents(comp)}
                    </span>
                    ))}
                </CardBody>
            </Collapse>
        </Card>
    )
}

export default collect(Filters);
