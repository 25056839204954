import React, { useEffect, useState } from 'react';
import { collect } from 'react-recollect';
import DowntimeNotification from './systemNotification/DowntimeNotification';
import InformationNotification from './systemNotification/InformationNotification';
import NoticeNotification from './systemNotification/NoticeNotification';
import AlertNotification from './systemNotification/AlertNotification';
import WarningNotification from './systemNotification/WarningNotification';

// System Notification Banner
const SystemNotificationAlert = () => {
  const notificationComponentMap = {
    'downtime': DowntimeNotification,
    'information': InformationNotification,
    'notice': NoticeNotification,
    'alert' : AlertNotification,
    'warning': WarningNotification
  }  
  const [notification, setNotification] = useState(JSON.parse(localStorage.getItem('systemNotification')));
  const NotificationComponent = notificationComponentMap[notification?.notificationType] || null;
  
  useEffect(() => {
    const handleStorageChange = () => {
      // Update the component
      setNotification(JSON.parse(localStorage.getItem('systemNotification')));
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); 

  function shouldShow(){
    // Banner is shown if notification is not expired
    if (notification?.dueDate){
      const dueDate = new Date(notification.dueDate)
      const now = new Date();
      const difference = dueDate - now;
      return difference > 0;
    }
    return false;
  }

  const handleCountdownEnds = () => {
    localStorage.removeItem('systemNotification');
    const storageChangeEvent = new Event('storage');
    window.dispatchEvent(storageChangeEvent);
  }

  let show = shouldShow();

  return ( show ?
    <>
      { NotificationComponent && <NotificationComponent notification={notification} handleCountdownEnds={handleCountdownEnds}/> }
    </>
    : null
  )

}

export default collect(SystemNotificationAlert);
