import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom/'
import { store } from 'react-recollect';
import { appStore } from '../store/store';
import { subdomain, writeCookie, getCurrentDomain, toastError } from '../utils/utils';
import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { AppApiTokenRequest } from '../azureAuthConfig';
import axios from 'axios';
import MicrosoftLoginButton from './MicrosoftLoginButton';
import { isMobile } from "react-device-detect";

const MicrosoftLogin = () => {
  const { instance } = useMsal()
  const [activeAccount, setActiveAccount] = useState({})
  const [graphData, setGraphData] = useState(null)
  const { result, error } = useMsalAuthentication(
    isMobile ? InteractionType.Redirect : InteractionType.Popup,
    AppApiTokenRequest
  )

  useEffect(() => {
    const currentAccount = instance.getActiveAccount()
    console.log('currentAccount:', currentAccount)
    if (currentAccount) setActiveAccount(currentAccount)
  }, [instance])

  const handleMicrosoftLogin = async () => {
    try {
      if (isMobile) {
        localStorage.setItem('sso_mobile_logout', false)
        await instance.loginRedirect(AppApiTokenRequest)
      } else await instance.loginPopup(AppApiTokenRequest)
    } catch (error) {
      console.log(error)
    }
  }

  // const handleMicrosoftLogout = () => {
  //   instance.logoutPopup()
  //     .catch(err => console.log(err))
  // }

  const handleLogin = async () => {
    try {
      store.ssoLoginLoading = true
      const { data } = await axios.get(`rest/sso_login?subdomain=${subdomain}&access_token=${result?.accessToken}`)
      if (data?.redirect_to) {
        window.location.href = encodeURI(data.redirect_to)
      } else {
        writeCookie('token', data.token, getCurrentDomain())
        localStorage.setItem('login_type', 'microsoft')
        verifyAndRedirectUser();
      }
      store.ssoLoginLoading = false
    } catch (error) {
      console.log(error)
      toastError(error?.response?.data?.error)
    }
  }

  useEffect(() => {
    if (result?.accessToken) {
      // Since mobile browsers act differently, we had to do this workaround to make logout work for mobile browsers.
      if (!isMobile) handleLogin()
      else {
        if (localStorage.getItem('sso_mobile_logout') !== 'true') handleLogin()
      }
    }
  }, [result?.accessToken])

  const verifyAndRedirectUser = async () => {
    localStorage.setItem('isVerified', true)
    await appStore.getCurrentVersion()
    if (!store.initialApisCalled && !store.initialApisLoading) await appStore.callInitialAPIs()
    return <Redirect to='/dashboard' />
  }

  return (
    <>
      <MicrosoftLoginButton handleClick={handleMicrosoftLogin} />
    </>
  )
}

export default MicrosoftLogin