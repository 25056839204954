import React, { useState } from "react";
import { languageOptions } from '../language';
import { appStore } from '../store/store';
import { store } from 'react-recollect';
import { getLangType } from '../utils/utils';
import { Button, Input, Row, Col } from 'reactstrap';

const langType = getLangType();

const PatientNote = ({ readOnly, noteType }) => {
  const [notes, setNotes] = useState(store.selectedRow.notes)
  const [notesEdit, setNotesEdit] = useState(false)

  const handleNotesChange = (e) => setNotes(e.target.value)

  const handleSaveNote = async () => {
    let editField = 'notes'
    let updateType = 'clinic_notes'
    if (noteType === 'survey_notes') {
      editField = 'surveyNotes'
      updateType = 'survey_notes'
    }
    // Conditionally edit the correct note (survey or patient (clinic) note)
    await appStore.editData(
      {
        [updateType]: notes,
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
      },
      editField,
      updateType,
    )
    setNotesEdit(false)
  }

  return <Row style={{ marginBottom: '20px' }}>
    <Col sm='3'>
      <span>
        {noteType === 'clinic_notes' ?
          languageOptions.pdNotesOnPatient[langType] :
          'Survey Notes:'}
      </span>
    </Col>
    <Col sm='9'>
      {!notesEdit && (
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            {noteType === 'clinic_notes' ? store.selectedRow?.notes : store.selectedRow?.surveyNotes}
          </span>
          {(!readOnly && !store.selectedRow.voided_at) &&
            <span
              className='details-padding'
              onClick={() => setNotesEdit(true)}>
              <i className='fas fa-edit' />
              {languageOptions.edit[langType]}
            </span>
          }
        </span>
      )}
      {notesEdit && (
        <div>
          <Input
            type='textarea'
            onChange={handleNotesChange}
            defaultValue={
              noteType === 'clinic_notes' ? store.selectedRow?.notes : store.selectedRow?.surveyNotes
            }
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
            <Button
              color='primary'
              style={{ width: '5rem' }}
              disabled={store.editDataLoading}
              onClick={handleSaveNote}>
              {store.editDataLoading ?
                <div className='spinner-border spinner-border-sm text-white ' role='status'>
                </div> :
                languageOptions.save[langType]}
            </Button>
            <Button
              style={{ marginLeft: '0.5rem' }}
              color='danger'
              onClick={() => setNotesEdit(false)}>
              {languageOptions.cancel[langType]}
            </Button>
          </div>
        </div>
      )}
    </Col>
  </Row>;
};

export default PatientNote;
