import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { collect } from 'react-recollect';
import { Input, Label } from 'reactstrap';
import Select from "react-select";
import { appStore } from '../store/store';
import { languageOptions } from '../language';
import { getLangType, displayFormError } from '../utils/utils';
import { statusesMap } from './PatientsTable'
import "react-datepicker/dist/react-datepicker.css";
import "./FilterComponent.css"
import CustomDatePicker from './CustomDatePicker.js';
import dayjs from 'dayjs';

export const NameFilter = collect(({ store, label, placeholder, type, onKeyDown }) => {
  const [value, setValue] = useState(store.filters.nameSearch);

  useEffect(() => {
    (!store.filters.nameSearch) && setValue('')
  }, [store.filters.nameSearch])

  const onChangeFn = (event) => {
    setValue(event.target.value);
    store.filters.nameSearch = event.target.value;
    store.patientsPageNumber = 1;
  };

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label className='filter-labels'>{label}</Label>
        <Input
          value={value}
          autoComplete='new-password'
          type='nameSearch'
          name='nameSearch'
          id='nameSearch'
          onChange={onChangeFn}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const LocationsFilter = collect(({ store, subdomainLocations, label, type, onKeyDown }) => {
  const [values, setValues] = useState(store.filters.locations);
  const langType = getLangType();

  useEffect(() => {
    (store.filters.locations && store.filters.locations.length === 0) && setValues([])
  }, [store.filters.locations])

  const onChangeFn = (e) => {
    let filterLocations = [];
    if (e && e.length > 0) {
      e.forEach((selectedLocation) => {
        filterLocations.push(selectedLocation.value[1]);
      });
    }
    setValues(e);
    store.filters.locations = filterLocations;
    store.patientsPageNumber = 1;
  };

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{label}</Label>
        <Select
          value={values}
          id='locationSearch'
          placeholder={languageOptions.select[langType]}
          onChange={onChangeFn}
          options={store.locationOptionGroups}
          isMulti="true"
          isSearchable="true"
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const RegionsFilter = collect(({ store, label, onKeyDown }) => {
  const [values, setValues] = useState(store.filters.regions);
  const langType = getLangType();
  const options = store.currentSubdomain?.regions?.map(region => {
    return { label: region.name_formatted, value: region.username }
  })

  useEffect(() => {
    (store.filters.regions && store.filters.regions.length === 0) && setValues([])
  }, [store.filters.regions])

  const onChangeFn = (e) => {
    let filterRegions = [];
    if (e && e.length > 0) {
      e.forEach((selectedRegion) => {
        filterRegions.push(selectedRegion.value);
      });
    }
    setValues(e);
    store.filters.regions = filterRegions;
    store.patientsPageNumber = 1;
  };

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{label}</Label>
        <Select
          value={values}
          id='regionSearch'
          placeholder={languageOptions.select[langType]}
          onChange={onChangeFn}
          options={options}
          isMulti="true"
          isSearchable="true"
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const StatusFilter = collect(({ store, statuses, label, type, onKeyDown }) => {
  const langType = getLangType();
  const [values, setValues] = useState(store.filters.status);

  useEffect(() => {
    (store.filters.status && store.filters.status.length === 0) && setValues([])
  }, [store.filters.status])

  const onChangeFn = (e) => {
    let filterStatuses = [];
    if (e && e.length > 0) {
      e.forEach((selectedStatus) => {
        filterStatuses.push(selectedStatus.value)
      });
    }
    setValues(e)
    store.filters.status = filterStatuses;
    store.patientsPageNumber = 1;
  };

  let options = [];
  if (statuses && statuses.length) {
    for (const status of statuses) {
      options.push({
        value: status,
        label: languageOptions[statusesMap[status]][langType],
      });
    }
  }
  if (!languageOptions['Unsubscribed']) {
    options.push({ value: 'Unsubscribed', label: 'Unsubscribed' })
  }

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{label}</Label>
        <Select
          value={values}
          name='statusesList'
          id='statusesList'
          onChange={onChangeFn}
          options={options}
          isMulti="true"
          isSearchable="true"
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const FacilityTypeFilter = collect(({ store, label, type, onKeyDown }) => {
  const langType = getLangType();
  const [values, setValues] = useState(store.filters.facilityType);

  useEffect(() => {
    (store.filters.facilityType && store.filters.facilityType.length === 0) && setValues([])
  }, [store.filters.facilityType])

  const onChangeFn = (e) => {
    let filterFacilityTypes = [];
    if (e && e.length > 0) {
      e.forEach((selectedFacilityType) => {
        filterFacilityTypes.push(selectedFacilityType.value)
      });
    }
    setValues(e)
    store.filters.facilityType = filterFacilityTypes;
    store.patientsPageNumber = 1;
  };

  let facilities = [
    { value: 'DME/HME', label: 'DME/HME' },
    { value: 'Sleep Lab', label: languageOptions.sleepLab[langType] },
    { value: 'Hospital', label: 'Hospital' },
    { value: 'Medical Doctor', label: languageOptions.medicalDoctor[langType] },
    { value: 'Skilled Nursing Facility', label: languageOptions.skilledNursingFacility[langType] },
    { value: 'Other', label: languageOptions.other[langType] },
  ]

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{label}</Label>
        <Select
          value={values}
          name='facilityList'
          id='facilityList'
          onChange={onChangeFn}
          options={facilities}
          isMulti='true'
          isSearchable='true'
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const InvitationModeFilter = collect(({ store, label, type, onKeyDown }) => {
  //const langType = getLangType();
  const [values, setValues] = useState(store.filters.invitationMode);

  useEffect(() => {
    (store.filters.invitationMode && store.filters.invitationMode.length === 0) && setValues([])
  }, [store.filters.invitationMode])

  const onChangeFn = (e) => {
    let filterInvitationMode = [];
    if (e && e.length > 0) {
      e.forEach((selectedInvitaionMode) => {
        filterInvitationMode.push(selectedInvitaionMode.value)
      });
    }
    setValues(e)
    store.filters.invitationMode = filterInvitationMode;
    store.patientsPageNumber = 1;
  };

  let channels = [
    { value: 'invite_email', label: 'Email' },
    { value: 'invite_text', label: 'Text Message' },
    { value: 'in-person', label: 'In-Person' },
  ]

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{label}</Label>
        <Select
          value={values}
          name='InvitationMode'
          id='InvitationMode'
          onChange={onChangeFn}
          options={channels}
          isMulti='true'
          isSearchable='true'
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const WasRefitFilter = collect(({ store, label, type, onKeyDown }) => {
  const [value, setValue] = useState(store.filters.wasRefit);
 
  useEffect(() => {
    if (store.filters.wasRefit === '') {
      setValue('');
    }
  }, [store.filters.wasRefit]);
 
  const onChangeFn = (selectedOption) => {
    setValue(selectedOption.value);
    store.filters.wasRefit = selectedOption.value;
    store.patientsPageNumber = 1;
  };
 
  // Find the corresponding option for the current value
  const selectedOption = useMemo(() => {
    return [
      { label: 'All', value: '' },
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ].find(option => option.value === value);
  }, [value]);
 
  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{label}</Label>
        <Select
          value={selectedOption}
          name='WasRefit'
          id='WasRefit'
          onChange={onChangeFn}
          defaultValue={{ label: 'All', value: '' }}
          options={[
            { label: 'All', value: '' },
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const DateTypeFilter = collect(({ store, type, onKeyDown }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (store.filters.isDateRangeSet){
      setValue({ value: 'processed_at', label: 'Processed' })
      store.filters.dateType = 'processed_at';
    } else {
      setValue('')
      store.filters.dateType = 'created_at';
    }
  }, [store.filters.isDateRangeSet])

  const onChangeFn = (date_type) => {
    setValue(date_type)
    store.filters.dateType = date_type.value;
    store.patientsPageNumber = 1;
  };
  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>Filter Date Type</Label>
        <Select
          value={value}
          name='facilityList'
          id='facilityList'
          onChange={onChangeFn}
          options={[
            { value: 'processed_at', label: 'Processed' },
            { value: 'created_at', label: 'Created' },
            { value: 'invited_at', label: 'Invited' },
            { value: 'completed_at', label: 'Completed' },
            { value: 'date_of_service', label: 'Billing DOS' },
            { value: 'survey_completed_at', label: 'Survey Completion' },
            { value: 'set_up_date', label: 'Set Up Date' },
          ]}
          isSearchable="true"
          defaultValue={''}
          onKeyDown={onKeyDown}
          isDisabled={!store.filters.isDateRangeSet}
        />
      </div>
    </div>
  );
});

export const StartDateFilter = collect(({ store, type, onKeyDown }) => {
  const langType = getLangType();
  const [dateError, setDateError] = useState(false);
  
  const onChangeFn = (date) => {
    const jsDate = date ? date : null;
    if (!jsDate || !dayjs(jsDate).isValid()) {
      store.filters.startDate = null;
      return;
    }

    if (store.filters.endDate && dayjs(store.filters.endDate).isBefore(jsDate)){
      store.filters.startDate = null;
      setDateError("Start date should be before End date");
      return;
    }
    
    store.filters.startDate = jsDate;
    // Enable dateType optn
    store.filters.isDateRangeSet = true;
    //store.filters.dateType = store.filters.dateType || 'processed_at';
    store.patientsPageNumber = 1;
    if (dateError) setDateError(false);
  };
  const maxDate = (store.filters.endDate && dayjs(store.filters.endDate)) ?? new Date(2050,1,1)
  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{languageOptions.startDate[langType]}</Label>
        <CustomDatePicker
          maxDate={dayjs(maxDate)}
          slotProps={{ textField: { size: 'small',error:false }}}
          value={store.filters.startDate}
          onChange={onChangeFn}
          onKeyDown={onKeyDown}
          />
        {dateError && displayFormError(dateError)}
      </div>
    </div>
  );
});

export const EndDateFilter = collect(({ store, type, onKeyDown }) => {
  const langType = getLangType();
  const [dateError, setDateError] = useState('');
  
  const onChangeFn = (date) => {
    const jsDate = date ? date : null;
    if (!jsDate || !dayjs(jsDate).isValid()) {
      store.filters.endDate = null;
      return;
    }

    if (store.filters.startDate && dayjs(store.filters.startDate).isAfter(jsDate)){
      store.filters.endDate = null;
      setDateError("End date should be after the Start date");
      return;
    }
    store.filters.endDate = jsDate;
    store.patientsPageNumber = 1;
    // Enable dateType optn
    store.filters.isDateRangeSet = true;
    //store.filters.dateType = store.filters.dateType || 'processed_at';
    if (dateError) setDateError(false);
  };

  const minDate = (store.filters.startDate && dayjs(store.filters.startDate)) ?? new Date(2000,1,1)
  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{languageOptions.endDate[langType]}</Label>
        <CustomDatePicker
          minDate={dayjs(minDate)}
          slotProps={{ textField: { size: 'small',error:false }}}
          value={store.filters.endDate}
          onChange={onChangeFn}
          onKeyDown={onKeyDown}
        />
        {dateError && displayFormError(dateError)}
      </div>
    </div>
  );
});

export const UserEmailFilter = collect(({ store, label, placeholder, type, onKeyDown }) => {
  const [values, setValues] = useState(store.filters.userEmail);

  useEffect(() => {
    (store.filters.userEmail && store.filters.userEmail.length === 0) && setValues([])
  }, [store.filters.userEmail])

  const onChangeFn = (e) => {
    let filterClinicians = [];
    if (e && e.length > 0) {
      e.forEach((selectedClinicians) => {
        filterClinicians.push(selectedClinicians.value)
      });
    }
    setValues(e);
    store.filters.userEmail = filterClinicians;
    store.patientsPageNumber = 1;
    //appStore.loadPatients();
  };

  let options = store.users ? store.users.map(({ username }) => ({ label: username, value: username })) : [];

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label className='filter-labels'>{label}</Label>
        <Select
          value={values}
          id='userSearch'
          onChange={onChangeFn}
          options={options}
          isMulti={true}
          isSearchable={true}
          onFocus={() => !store.users?.length &&
            appStore.loadUsers({ is_active: true })}
          isLoading={store.usersLoading}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const PhysicianFilter = collect(({ store, label, placeholder, type, onKeyDown }) => {
  const [value, setValue] = useState(store.filters.physician);

  useEffect(() => {
    (!store.filters.physician) && setValue('')
  }, [store.filters.physician])

  const onChangeFn = (event) => {
    setValue(event.target.value);
    store.filters.physician = event.target.value;
    store.patientsPageNumber = 1;
  };
  return (
    <div className="filter-input">
      <div className="w-100">
        <Label className='filter-labels'>{label}</Label>
        <Input
          value={value}
          type="physicianSearch"
          name="physicianSearch"
          id="physicianSearch"
          onChange={onChangeFn}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const CustomerFilter = collect(({ store, label, onKeyDown }) => {
  const [values, setValues] = useState(store.filters.customers)
  const { isStaff, customerSubdomain } = store.currentUser
  const options = store.allParentCustomers?.map(cust => {
    return { label: cust.name_formatted, value: cust.username }
  })

  useEffect(() => {
    if (isStaff && customerSubdomain === 'admin') {
      appStore.getAllParentCustomers()
    }
  }, [])

  const onChangeFn = (e) => {
    let filterCustomers = [];
    if (e && e.length > 0) {
      e.forEach((selectedLocation) => {
        filterCustomers.push(selectedLocation.value);
      });
    }
    setValues(e);
    store.filters.customers = filterCustomers;
    store.patientsPageNumber = 1;
    //appStore.loadPatients();
  };

  return (
    <div className='filter-input'>
      <div className='w-100'>
        <Label>{label}</Label>
        <Select
          value={values}
          id='customerSearch'
          placeholder='Select...'
          onChange={onChangeFn}
          options={options}
          isMulti={true}
          isSearchable={true}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  )
});

export const RecallPtNotesFilter = collect(({ store, label, onKeyDown }) => {
  const [value, setValue] = useState(store.filters.recallPtNotesExists);

  useEffect(() => {
    if (store.filters.recallPtNotesExists === '') {
      setValue('');
    }
  }, [store.filters.recallPtNotesExists]);

  const onChangeFn = (selectedOption) => {
    setValue(selectedOption.value);
    store.filters.recallPtNotesExists = selectedOption.value;
    store.patientsPageNumber = 1;
  };

  // Find the corresponding option for the current value
  const selectedOption = useMemo(() => {
    return [
      { label: 'All', value: '' },
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ].find(option => option.value === value);
  }, [value]);

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label>{label}</Label>
        <Select
          value={selectedOption}
          name='RecallPtNotes'
          id='RecallPtNotes'
          onChange={onChangeFn}
          defaultValue={{ label: 'All', value: '' }}
          options={[
            { label: 'All', value: '' },
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export const RecallPtNotesSearchFilter = collect(({ store, label, placeholder, onKeyDown }) => {
  const [value, setValue] = useState(store.filters.recallPtNotesSearch);

  useEffect(() => {
    (!store.filters.recallPtNotesSearch) && setValue('')
  }, [store.filters.recallPtNotesSearch])

  const onChangeFn = (event) => {
    setValue(event.target.value);
    store.filters.recallPtNotesSearch = event.target.value;
    store.patientsPageNumber = 1;
  };
  return (
    <div className="filter-input">
      <div className="w-100">
        <Label className='filter-labels'>{label}</Label>
        <Input
          value={value}
          type='recallPtNotesSearch'
          name='recallPtNotesSearch'
          id='recallPtNotesSearch'
          onChange={onChangeFn}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  )
});

export const PatientAssignedToFilter = collect(({ store, label, onKeyDown }) => {
  const [values, setValues] = useState(store.filters.patientAssignedTo);

  useEffect(() => {
    (store.filters.patientAssignedTo && store.filters.patientAssignedTo.length === 0) && setValues([])
  }, [store.filters.patientAssignedTo])

  const onChangeFn = (e) => {
    let users = [];
    if (e && e.length > 0) {
      e.forEach((selectedClinicians) => {
        users.push(selectedClinicians.value)
      });
    }
    setValues(e);
    store.filters.patientAssignedTo = users;
    store.patientsPageNumber = 1;
  };

  let options = store.users ? store.users.map(({ username }) => ({ label: username, value: username })) : [];

  return (
    <div className="filter-input">
      <div className="w-100">
        <Label className='filter-labels'>{label}</Label>
        <Select
          value={values}
          id='userSearch'
          onChange={onChangeFn}
          options={options}
          isMulti={true}
          isSearchable={true}
          onFocus={() => !store.users?.length &&
            appStore.loadUsers({ is_active: true })}
          isLoading={store.usersLoading}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});