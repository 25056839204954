import React, { useState, lazy, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import SystemNotificationAlert from '../components/SystemNotificationAlert';
import TopNav from '../components/TopNav';
import { appStore } from '../store/store';
import { Progress } from 'reactstrap';
import { isTokenActive } from '../App';
import { isMicrosoftRedirecting } from '../utils/utils';
const ChangePassword = lazy(() => import('../pages/ChangePassword'))

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const [isSideBarToggled, setIsSideBarToggled] = useState(false)

  const getIsSideBarToggled = (val) => setIsSideBarToggled(val)

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (appStore.isLoggedIn() && isTokenActive()) {
          if (localStorage.passwordResetRequired == 'true') {
            console.log('Password reset required');
            Component = ChangePassword;
          }
          return (
            <div id="wrapper" className={isSideBarToggled ? 'toggled' : undefined}>
              <TopNav getIsSideBarToggled={getIsSideBarToggled} />
              <SystemNotificationAlert />
              <div style={{ marginTop: '12px' }}>
                {(appStore.isLoaded && <>
                  <ErrorBoundary>
                    <Suspense fallback={<Progress value={100} animated />}>
                      <Component {...props} />
                    </Suspense>
                  </ErrorBoundary>
                </>
                ) || (
                    <div>Loading...</div>
                  )}
              </div>
            </div >
          )
        }
        else {
          if (!isMicrosoftRedirecting()) return <Redirect
            to={{
              previousPath: otherProps.path,
              pathname: '/login',
            }}
          />
        }
      }}
    />
  )
};

export default PrivateRoute