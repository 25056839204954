import { appStore } from "../store/store";

class PatientChatSocket {
  static instance = null;

  static getInstance() {
    if (!PatientChatSocket.instance) {
      PatientChatSocket.instance = new PatientChatSocket();
    }
    return PatientChatSocket.instance;
  }

  constructor() {
    this.socketRef = null;
  }

  connect(website_token, username) {
    const prefix = (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host;
    const api_path = `${prefix}/ws/chat/patient_${website_token}/placeholder`;
    this.socketRef = new WebSocket(api_path);

    this.socketRef.onmessage = e => this.socketNewMessage(e);
    this.socketRef.onopen = () => console.log('Pt chat WebSocket open');
    this.socketRef.onerror = e => console.log("Pt chat socket ERROR: ", e);
    this.socketRef.onclose = () => console.log("Pt chat websocket closed");
    console.log(`Connected to ${api_path}`);
  }

  socketNewMessage(data) {
    const message = JSON.parse(data.data)
    appStore.updatePatientMessages(message)
  }

  initChatUser(username) {
    this.sendMessage({ command: 'init_chat', username });
  }

  fetchMessages(username) {
    this.sendMessage({ command: 'fetch_messages', username });
  }

  newChatMessage(message) {
    this.sendMessage({
      ...message, command: 'new_message', chatType: 'patient'
    });
  }

  sendMessage(data) {
    try {
      this.socketRef.send(JSON.stringify({ ...data }));
    }
    catch (err) {
      console.log(err.message);
    }
  }

  state() {
    return this.socketRef.readyState;
  }

  close() {
    this.socketRef.close();
  }
}

const PatientChatSocketInstance = PatientChatSocket.getInstance();

export default PatientChatSocketInstance;
