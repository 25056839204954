import React from 'react';

function Footer() {
    return (
        <footer className="footer" style={{fontSize: '12px', paddingTop: '20px'}}>
            <div className='container d-flex justify-content-center mb-3'>
                <span>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.maskfittings.com/terms-of-use" className='p-2'>Terms & Conditions</a>
                    |
                    <a target="_blank" rel="noopener noreferrer" href="https://www.maskfittings.com/privacy-policy" className='p-2'>Privacy Policy</a>
                </span>
            </div>
            <div className="container d-flex justify-content-center">
                <span className="text-muted">                                    
                    © 2023 Baxter Tech
                </span>
            </div>
        </footer>
    );
}

export default Footer;