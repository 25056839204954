import React, { useEffect, useState, useRef } from "react";
import { store } from "react-recollect";
import { appStore } from '../store/store';
import { Label } from "reactstrap";
import Select from 'react-select'

const sortedByOptions = [
  { label: 'Default (Date)', value: 'created_at' },
  { label: 'Patient Id', value: 'patient_id' },
  { label: 'Name', value: 'name' },
  { label: 'Status', value: 'status' },
  { label: 'Location', value: 'location' },
]

const SortPatientList = () => {
  const [sortedBy, setSortedBy] = useState(sortedByOptions[0])
  const isInitialRender = useRef(true);

  const onChange = (e) => {
    setSortedBy(e)
    store.sortBy = e.value
  }

  useEffect(() => {
    // Check if it's the initial render (to prevent calling it for the second time)
    if (!isInitialRender.current) appStore.loadPatients();
    // It's the initial render, set the ref to false
    else isInitialRender.current = false;
  }, [sortedBy.value])

  return (
    <div className='d-flex align-items-center mb-1' style={{ gap: '1rem' }}>
      <Label className="m-0">
        Sort By:
      </Label>
      <div style={{ width: '10.5rem' }}>
        <Select
          options={sortedByOptions}
          onChange={onChange}
          value={sortedBy}
        />
      </div>
    </div>
  )
};

export default SortPatientList;
