import React, { useEffect, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { collect, store } from 'react-recollect';
import { appStore } from './store/store';
import PatientOnboardingForm from './pages/PatientOnboardingForm';
import './font-awesome/font-awesome.css';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StatusSocketInstance from './websockets/StatusSockets';
import RouteWithSuspense from './Routes/RouteWithSuspense';
import PrivateRoute from './Routes/PrivateRoute';
import 'antd/dist/reset.css';
import { readCookieValue } from './utils/utils';
import { MsalProvider } from '@azure/msal-react';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
const TradeShowMode = lazy(() => import('./pages/TradeShowMode'))
const Survey = lazy(() => import('./pages/Survey'))
const PatientMessages = lazy(() => import('./pages/PatientMessages'))
const Analytics = lazy(() => import('./pages/NewAnalytics'))
const Settings = lazy(() => import('./pages/settings/Settings'))
const Alerts = lazy(() => import('./pages/Alert'))
const PatientMessagesPage = lazy(() => import('./pages/PatientMessagesPage'))
const KnowledgeBase = lazy(() => import('./pages/KnowledgeBase'))
const KnowledgeBaseUpdateDetail = lazy(() => import('./pages/KnowledgeBaseUpdateDetail'))
const AdHoc = lazy(() => import('./pages/AdHoc'))
const ManageUsers = lazy(() => import('./pages/ManageUsers'))
const MyProfile = lazy(() => import('./pages/MyProfile'))
const PrintPatientDetails = lazy(() => import('./pages/PrintPatientDetails'))
const PatientInfo = lazy(() => import('./pages/PatientInfo'))
const ForgetPassword = lazy(() => import('./pages/ForgetPassword'))
const Emailed = lazy(() => import('./pages/Emailed'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))
const PatientImporter = lazy(() => import('./pages/PatientImporter'))
const OnboardNewClient = lazy(() => import('./pages/newClient/OnboardNewClient'))
const Billing = lazy(() => import('./pages/billing/Billing'))
const Downloads = lazy(() => import('./pages/Downloads'))

export const isTokenActive = () => readCookieValue('token')

function App({ msalInstance }) {
  useEffect(() => {
    if (appStore.isLoggedIn() && !!isTokenActive()) {
      if (!store.initialApisCalled && !store.initialApisLoading) appStore.callInitialAPIs()
    }
    updateDocumentTitle()
    StatusSocketInstance.connect();

    return () => {
      StatusSocketInstance.close();
    }
  }, [])

  const updateDocumentTitle = () => {
    const url = window.location.href;
    if (url.includes('maskfittings')) document.title = 'Mask Fit';
    else document.title = 'SleepGlad';
  };

  return (
    <div>
      <Router>
        <ToastContainer
          hideProgressBar={true}
          position='top-right'
          limit={2}
          theme='colored'
        />
        <MsalProvider instance={msalInstance}>
          <Switch>
            <RouteWithSuspense path='/survey/:website_token' component={Survey} />
            <RouteWithSuspense path='/patient-messages/:website_token' component={PatientMessages} />
            <Route path='/scan' component={PatientOnboardingForm} />
            <Route path='/kiosk' render={() => <PatientOnboardingForm kiosk={true} />} />
            <RouteWithSuspense path='/tradeshow' component={TradeShowMode} />

            <Route path='/login' component={Login} exact />
            <RouteWithSuspense path='/forget-password' component={ForgetPassword} exact />
            <RouteWithSuspense path='/user/change' component={ResetPassword} exact />
            <RouteWithSuspense path='/user/emailed' component={Emailed} exact />
            <PrivateRoute path='/analytics' component={Analytics} exact />
            <PrivateRoute path='/alerts' component={Alerts} exact />
            <PrivateRoute path='/profile/:username' component={MyProfile} exact />
            <PrivateRoute path='/settings/users' component={ManageUsers} exact />
            <PrivateRoute path='/settings' component={Settings} exact />
            <PrivateRoute path='/patient/:website_token' component={PatientInfo} exact />
            <PrivateRoute path='/print/patient' component={PrintPatientDetails} exact />
            <PrivateRoute path='/help' component={KnowledgeBase} exact />
            <PrivateRoute path='/help/knowledge-base/:id' component={KnowledgeBaseUpdateDetail} exact />
            <PrivateRoute path='/ad-hoc' component={AdHoc} exact />
            <PrivateRoute path='/patientMessages' component={PatientMessagesPage} exact />
            <PrivateRoute path='/patient-importer' component={PatientImporter} exact />
            <PrivateRoute path='/client-onboarding' component={OnboardNewClient} exact />
            <PrivateRoute path='/billing' component={Billing} exact />
            <PrivateRoute path='/downloads' component={Downloads} exact />
            <PrivateRoute path='/' component={Dashboard} />
          </Switch>
        </MsalProvider>
      </Router>
    </div>
  );
}

export default collect(App);
