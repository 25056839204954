import React, { useState, useEffect, useCallback } from "react";
import { languageOptions } from '../language';
import { appStore } from '../store/store';
import { store } from 'react-recollect';
import { getLangType, validNumberInput } from '../utils/utils';
import { Button, Input, Row, Col, Label } from 'reactstrap';
import Select from "react-select";

const langType = getLangType();

const complianceMachineOptions = [
  { label: 'Resmed', value: 'resmed' },
  { label: 'React', value: 'react' },
  { label: 'Other', value: 'other' }
]

const PatientCompliance = ({ readOnly }) => {
  
  const initialComplianceState = {
    isCompliant: store.selectedRow.isCompliant,
    compliancePercentage: store.selectedRow.compliancePercentage,
    complianceDay: store.selectedRow.complianceDay,
    complianceMachine: null,
    complianceMachineOther: null,
  };

  const [complianceData, setComplianceData] = useState(initialComplianceState);
  const [complianceEdit, setComplianceEdit] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

  const resetFields = useCallback(() => {
    setComplianceData({
      ...initialComplianceState,
      complianceMachine: null,
      complianceMachineOther: null,
    });
    setComplianceEdit(false);
  }, [initialComplianceState]);

  const { isCompliant, compliancePercentage, complianceDay, complianceMachine, complianceMachineOther } = complianceData;

  const updateCompliance = useCallback(() => {
    store.selectedRow.isCompliant = isCompliant;
    store.selectedRow.compliancePercentage = isCompliant ? compliancePercentage : 0;
    store.selectedRow.complianceDay = isCompliant ? complianceDay : 0;
    store.selectedRow.complianceMachine = complianceMachine?.value === 'other' ? complianceMachineOther : complianceMachine?.value;
  }, [complianceData]);

  useEffect(() => {
    const complianceDayInvalid = isNaN(complianceDay) || (complianceDay < 1 || complianceDay > 999);
    const compliancePercentageInvalid = isNaN(compliancePercentage) || (compliancePercentage < 1 || compliancePercentage > 100);
    const complianceMachineInvalid = (!complianceMachine || (complianceMachine?.value === 'other' && !complianceMachineOther));

    const missingFields = !complianceDay || !compliancePercentage || !complianceMachine;
    const invalidFields = complianceDayInvalid || compliancePercentageInvalid || complianceMachineInvalid;

    setSaveBtnDisabled(isCompliant && (missingFields || invalidFields));
  }, [complianceData]);

  return (
    <>
      <Row style={{ marginBottom: '20px' }}>
        <Col sm='3'>
          <span>{languageOptions.isCompliant[langType]}:</span>
        </Col>
        <Col sm='9'>
          {!complianceEdit && (
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                {store.selectedRow.isCompliant ? 'Yes' : 'No'}
              </span>
              {(!readOnly && !store.selectedRow.voided_at) &&
                <span
                  className='details-padding'
                  onClick={() => setComplianceEdit(true)}>
                  <i className='fas fa-edit' />
                  {languageOptions.edit[langType]}
                </span>
              }
            </span>
          )}
          {complianceEdit && (
            <div>
              <Select
                isSearchable="false"
                options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                value={{ label: isCompliant ? 'Yes' : 'No', value: isCompliant }}
                onChange={(e) => {
                  if (!e) return;
                  setComplianceData({
                    isCompliant: e.value,
                    compliancePercentage: e.value ? compliancePercentage : 0,
                    complianceDay: e.value ? complianceDay : 0,
                    complianceMachine: e.value ? complianceMachine : null,
                    complianceMachineOther: e.value ? complianceMachineOther : null
                  });
                }}
              />
              {isCompliant && (
                <Row>
                  <Col md='5'>
                    <Row className='d-flex align-items-center mt-2'>
                      <Col md='8'><Label>Compliance Day:</Label></Col>
                      <Col md='4'>
                        <Input
                          style={{ display: 'block' }}
                          type='number'
                          onKeyDown={(e) => !validNumberInput(e) && e.preventDefault()}
                          min={1}
                          max={999}
                          name='complianceDay'
                          onChange={(e) => {
                            setComplianceData({
                              ...complianceData,
                              complianceDay: parseInt(e.target.value),
                            });
                          }}
                          defaultValue={store.selectedRow.complianceDay}
                        />
                      </Col>
                    </Row>
                    <Row className='d-flex align-items-center mt-2'>
                      <Col md='8'><Label>Compliance Percentage:</Label></Col>
                      <Col md='4'>
                        <Input
                          style={{ display: 'block' }}
                          type='number'
                          onKeyDown={(e) => !validNumberInput(e) && e.preventDefault()}
                          min={1}
                          max={100}
                          name='compliancePercentage'
                          onChange={(e) => {
                            setComplianceData({
                              ...complianceData,
                              compliancePercentage: parseInt(e.target.value),
                            });
                          }}
                          defaultValue={store.selectedRow.compliancePercentage}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col md='7'>
                    <Row className='d-flex align-items-center mt-2'>
                      <Col md='4'><Label>Machine type:</Label></Col>
                      <Col md='8'>
                        <Select
                          isSearchable="false"
                          options={complianceMachineOptions}
                          value={complianceMachine}
                          onChange={(e) => {
                            if (!e) return;
                            setComplianceData({
                              ...complianceData,
                              complianceMachine: e,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    {complianceMachine?.value === 'other' && (
                      <Row className='d-flex align-items-center mt-2'>
                        <Col md='4'><Label>Other:</Label></Col>
                        <Col md='8'>
                          <Input
                            type='text'
                            name='complianceMachineOther'
                            onChange={(e) => {
                              setComplianceData({
                                ...complianceData,
                                complianceMachineOther: e.target.value,
                              });
                            }}
                            placeholder="Please, enter other machine type"
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              )}

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                <Button
                  disabled={saveBtnDisabled}
                  color='primary'
                  onClick={() => {
                    if ([null, undefined].includes(isCompliant)) return;
                    setComplianceEdit(false);
                    appStore.editData(
                      {
                        isCompliant: isCompliant,
                        compliancePercentage: isCompliant ? compliancePercentage : 0,
                        complianceDay: isCompliant ? complianceDay : 0,
                        complianceMachine: complianceMachine?.value === 'other' ? complianceMachineOther : complianceMachine?.value,
                        patient_subdomain: store.selectedRow.subdomain,
                        website_token: store.selectedRow.website_token,
                      },
                      'isCompliant',
                      'isCompliant',
                    );
                    appStore.loadPatients();
                    updateCompliance();
                  }}>
                  {languageOptions.save[langType]}
                </Button>
                <Button
                  style={{ marginLeft: '0.5rem' }}
                  color='danger'
                  onClick={resetFields}>
                  {languageOptions.cancel[langType]}
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>

      {(isCompliant && !complianceEdit) && (
        <>
          <Row style={{ marginBottom: '20px' }}>
            <Col sm='3'>
              <span>{languageOptions.compliancePercentage[langType]}:</span>
            </Col>
            <Col sm='9'>
              <span>
                {store.selectedRow.compliancePercentage}%
              </span>
            </Col>
          </Row>

          <Row style={{ marginBottom: '20px' }}>
            <Col sm='3'>
              <span>{languageOptions.complianceDay[langType]}:</span>
            </Col>
            <Col sm='9'>
              <span>
                {store.selectedRow.complianceDay}
              </span>
            </Col>
          </Row>

          <Row style={{ marginBottom: '20px' }}>
            <Col sm='3'>
              <span>{languageOptions.complianceMachine[langType]}:</span>
            </Col>
            <Col sm='9'>
              <span>
                {store.selectedRow.complianceMachine}
              </span>
            </Col>
          </Row>
        </>
      )}

    </>
  )
}

export default PatientCompliance;