import React from 'react';
import { subdomain } from '../utils/utils'

const PatientSideMainComponent = () => {
  return (
    <div className='d-flex justify-content-center mt-1'>
      <img src={encodeURI(`/rest/customer_logo?subdomain=${subdomain}`)} height='100' alt="customer logo" />
    </div>
  )
}

export default PatientSideMainComponent