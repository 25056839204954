import React from 'react';
import { collect } from 'react-recollect';
import { Button } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';
import { appStore } from '../store/store';
import LocationModal from './LocationModal';
import { components } from 'react-select';

export const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchOutlined />
      </components.DropdownIndicator>
    )
  );
};

const BranchSelector = ({ store }) => {

  const selectLocation = (loc) => {
    store.selectedLocationId = loc.id;
    store.selectedLocationShortName = loc.short_name;
    store.selectedLocationDBAName = loc.dba_name;
    store.selectedLocationBranchNumber = loc.branch_number;
    store.selectedLocationNPINumber = loc.npi_number;
    store.selectedLocationPhoneNumber = loc.phone_number;
    store.selectedLocationAddress = loc.address;
    store.selectedLocationResupplyFormularyName = loc.resupply_formulary;
    store.selectedLocationNewPatientFormularyName = loc.new_patient_formulary;
    if (!loc.timezone) {
      store.selectedLocationTimezone = { value: "", label: "Default (Recommended)" }
    } else {
      store.selectedLocationTimezone = { value: loc.timezone, label: loc.timezone };
    }
    store.showEditLocationModal = true;
  }

  const onLocationSave = () => {
    appStore.saveLocationDetails().then(() => appStore.getFormulary());
  }

  let currentBranch, otherBranches;
  if (store.currentSubdomain.branches && store.currentSubdomain.branches.length) {
    currentBranch = store.currentSubdomain.branches[0];
    otherBranches = store.currentSubdomain.branches.filter((item) => {
      return item.subdomain !== store.currentUser.customerSubdomain;
    });
  }

  if (!store.currentUser || !store.currentSubdomain) {
    return (<div>Loading...</div>)
  }
  return (
    <React.Fragment>

      {(store.showEditLocationModal || store.showCreateLocationModal) && (
        <LocationModal key={store.selectedLocationId} onLocationSave={onLocationSave} />
      )}

      <div style={{ height: '100vh', overflowY: 'auto', border: '1px solid rgba(0,0,0, 0.07)' }}>
        <h5>Locations</h5>
        <Button
          color="primary"
          size="large"
          style={{ width: "100%", height: "128px", marginBottom: "2px" }}>
          <span>
            <img
              style={{ maxWidth: "90%", maxHeight: "120px", width: "auto" }}
              src={store.currentSubdomain.isParent ? store.currentSubdomain.customerImageURL : currentBranch?.logo_url} // Use currentSubdomain's imgUrl if parent else first branch
              alt="logo"
            >
            </img>
          </span>
        </Button>
        <div className='d-flex flex-column mt-1'>
          {store.currentSubdomain.locationDetails?.map((loc) => (
            <div
              key={loc.name + loc.id}
              className='btn mouseoverdark'
              style={{ backgroundColor: '#f0f0f0' }}
              onClick={() => selectLocation(loc)}
            >
              {loc.name}
            </div>
          ))}
          {(!store.currentSubdomain.npi_client || store.currentSubdomain.is_facility) && (
            <div className='btn mouseoverdark mt-1'
              style={{ backgroundColor: '#f0f0f0', color: 'blue' }}
              onClick={appStore.initiateNewLocation}>
              + Add New
            </div>
          )}
        </div>
        <br />
        {otherBranches.length > 0 && (
          <h5>Subsidiary Branches</h5>
        )}
        {otherBranches.map((branch, i) => (
          <a key={branch.name_formatted + i} href={branch.settings_url} target="_blank" rel="noopener noreferrer">
            <Button
              color="secondary"
              size="large"
              style={{ width: "100%", height: "128px", marginBottom: "32px", marginTop: "32px" }}>
              <span>
                <img
                  style={{ maxWidth: "90%", maxHeight: "120px", width: "auto" }}
                  src={branch.logo_url}
                  alt="logo"
                >
                </img>
              </span>
            </Button>
          </a>
        ))}
      </div>
    </React.Fragment>
  );
}


export default collect(BranchSelector);
