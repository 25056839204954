import React, { useState } from "react";
import { store } from "react-recollect";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const PressureInput = ({ getPapTherapy }) => {
  const [papTherapy, setPapTherapy] = useState(store.selectedRow.patient_attributes.pap_therapy)

  const onRadioChange = (e) => {
    setPapTherapy(e.target.value)
    getPapTherapy(e.target.value) // Set the parent state
  }

  return <div>
    <FormControl>
      <RadioGroup
        row
        className='pressure-radio-buttons'
        aria-labelledby="pressure-radio-buttons"
        name="pressure-radio-buttons-group"
        value={papTherapy}
        onChange={onRadioChange}
      >
        <FormControlLabel
          className="mb-0"
          value="CPAP"
          control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18, }, }} />}
          label={<span style={{ fontSize: '13px' }}>CPAP</span>}
        />
        <FormControlLabel
          className="pressure-radio-btn-label mb-0"
          value="AutoPAP"
          control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18, }, }} />}
          label={
            <span style={{ fontSize: '13px' }}>CPAP <br />
              <span style={{ fontSize: '12px' }}>(Auto Pressure)</span>
            </span>
          }
        />
        <FormControlLabel
          className="mb-0"
          value="BiPAP"
          control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18, }, }} />}
          label={<span style={{ fontSize: '13px' }}>BiPAP</span>}
        />
        <FormControlLabel
          className="pressure-radio-btn-label mb-0"
          value="ASV/ST"
          control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 18, }, }} />}
          label={
            <span style={{ fontSize: '13px' }}>ASV/ST <br />
              <span style={{ fontSize: '12px' }}>(Inspiratory Pressure)</span>
            </span>
          }
        />
      </RadioGroup>
    </FormControl>
  </div>;
};

export default PressureInput;
