import React, { useEffect, useState } from 'react';
import { collect } from 'react-recollect';
import { appStore } from '../../store/store';
import { Col, Row, Button, Input } from 'reactstrap';
import { Button as ButtonANTD } from 'antd';
import { languageOptions } from '../../language';
import { getLangType } from '../../utils/utils';
import './RecallPatientNotes.scss';
import dayjs from 'dayjs';

const langType = getLangType();

const RecallPatientNotes = ({ store }) => {
  const [note, setNote] = useState('')
  const [showNewNoteForm, setShowNewNoteForm] = useState(false)

  const { website_token: websiteToken } = store.selectedRow

  useEffect(() => {
    appStore.loadRecallPatientNotes(websiteToken)
  }, [])

  const onNoteInputChange = (e) => setNote(e.target.value)

  const handleNewNoteClick = () => setShowNewNoteForm(true)

  const handleSaveNote = () => {
    setShowNewNoteForm(false)
    appStore.createRecallPatientNotes({ websiteToken, note })
  }

  const handleCancelBtn = () => {
    setNote('')
    setShowNewNoteForm(false)
  }

  return (
    <>
      {store.recallPatientNotes.length ? (
        <div>
          <Row className='mb-1'>
            <Col xs={4} lg={6}><h6>Note</h6></Col>
            <Col xs={4} lg={3}><h6>Created By</h6></Col>
            <Col xs={4} lg={3}><h6>Created At</h6></Col>
          </Row>
          {store.recallPatientNotes.map(note => (
            <Row
              key={note.note_id + note.created_at}
              className='recallNotes'
              style={{
                backgroundColor: 'rgb(240,240,255, .7)',
                marginBottom: '10px',
                borderRadius: '5px'
              }}
            >
              <Col xs={4} lg={6} className='recallNotes__column'>
                {note.note}
              </Col>
              <Col xs={4} lg={3} className='recallNotes__column'>
                {note.created_by_formatted}
              </Col>
              <Col xs={4} lg={3} className='recallNotes__column'>
                {dayjs(note.created_at).format('MM/DD/YYYY - hh:mm a')}
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <div>There are no records to display</div>
      )}
      {(store.currentUser.isStaff || store.currentUser.isIntervention) && (
        <>
          <div className='d-flex justify-content-end my-3'>
            <Button
              type='button'
              color='primary'
              size='sm'
              onClick={handleNewNoteClick}>
              Add New Note{' '}
              <span style={{ paddingLeft: '2px' }}>
                {' '}
                <i className='fas fa-plus-square' />
              </span>
            </Button>
          </div>
          {showNewNoteForm && (
            <div>
              <Input
                type='textarea'
                style={{ maxHeight: '200px' }}
                onChange={onNoteInputChange}
              />
              <div className='d-flex justify-content-end mt-2'>
                <ButtonANTD
                  ghost
                  type='primary'
                  disabled={!note.trim()}
                  onClick={handleSaveNote}
                >
                  {languageOptions.save[langType]}
                </ButtonANTD>
                <ButtonANTD
                  type='primary'
                  danger
                  ghost
                  style={{ marginLeft: '0.5rem' }}
                  onClick={handleCancelBtn}>
                  {languageOptions.cancel[langType]}
                </ButtonANTD>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default collect(RecallPatientNotes);
