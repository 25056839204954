import React from 'react'
import './LoadingCircles.scss'

function LoadingCircles({ className }) {
  return (
    <div className={"circle-loader" + (className ? " " + className : "")}>
      <div className="circle-loader__dot"></div>
      <div className="circle-loader__dot"></div>
      <div className="circle-loader__dot"></div>
      <div className="circle-loader__dot"></div>
      <div className="circle-loader__dot"></div>
    </div>
  )
}

export default LoadingCircles