import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { Progress } from 'reactstrap';
import ErrorBoundary from '../components/ErrorBoundary';

const RouteWithSuspense = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ErrorBoundary>
          <Suspense fallback={<Progress value={100} animated />}>
            <Component {...props} />
          </Suspense>
        </ErrorBoundary>
      )}
    />
  )
}

export default RouteWithSuspense;