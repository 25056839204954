import React from 'react';
import { Alert } from 'reactstrap';
import { collect } from 'react-recollect';
import ConstructionIcon from '@mui/icons-material/Construction';
import Timer from './Timer';

const DowntimeNotification = ({notification, handleCountdownEnds}) => {
    return (
      <Alert color='warning' className='d-flex align-items-center gap-2 mx-3 my-2' >
        <ConstructionIcon />
        <div>
          <span>We are working to improve your experience at SleepGlad. A brief downtime is expected in:</span>
          <Timer dueDate={notification?.dueDate} handleCountdownEnds={handleCountdownEnds}/>
          <span>. We appreciate your patience. The system will be back shortly.</span>
        </div>
      </Alert>
    );
  };

export default collect(DowntimeNotification);