import React from 'react';
import { Container, Row, Col, Progress } from 'reactstrap';
import PatientsTable from '../components/PatientsTable';
import { collect } from 'react-recollect';
import { appStore } from '../store/store';
import './Dashboard.css';
import "react-datepicker/dist/react-datepicker.css";
import Filters from '../components/Filters';
import { withRouter } from 'react-router';
// import ErrorBoundary from '../components/ErrorBoundary';
// const DashboardChartView = lazy(() => import('../components/DashboardCharts.js'))
const Dashboard = ({ store }) => {

  if (!appStore.isLoaded()) {
    return (
      <div style={{ marginBottom: '20px' }}>
        <Progress value={100} animated />
      </div>
    )
  }

  return (
    <>
      <div className='mx-3 mb-2 d-flex justify-content-between align-items-center'>
        <h5 className='mb-0' >Patient List</h5>
      </div>
      <Container fluid>
        <Filters tab='dashboard' />

        {/* {['clinician', 'tech'].includes(store?.currentUser?.userRole) &&
              <ErrorBoundary>
                <Suspense>
                  <DashboardChartView />
                </Suspense>
              </ErrorBoundary>
            } */}

        {store.loading && <div style={{ height: '20px' }}>
          <Progress value={100} animated />
        </div>}
        <Row>
          <Col lg='12'>
            <PatientsTable />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default collect(withRouter(Dashboard));
