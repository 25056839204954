import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { collect } from 'react-recollect';
import InfoIcon from '@mui/icons-material/Info';
import Timer from './Timer';

const InformationNotification = ({notification, handleCountdownEnds}) => {
    const [visible, setVisible] = useState(true);
    const onDismiss = () => {
      if (notification.dismissible) {
        setVisible(false);
        handleCountdownEnds();
      }
    };
    const toggleFunction = notification.dismissible ? onDismiss : undefined;
  
    return (
      <Alert color='primary' className='d-flex align-items-center gap-2 mx-3 my-2' isOpen={visible} toggle={toggleFunction}>
        <InfoIcon/>
        <div>
          <span>{notification?.customMessage}</span>
          {notification?.showCountdown && <Timer dueDate={notification?.dueDate} handleCountdownEnds={handleCountdownEnds}/>}
        </div>
      </Alert>
    );
  };

export default collect(InformationNotification);