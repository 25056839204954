import React from 'react'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Box from '@mui/material/Box';

const CustomDatePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
      <Box sx={{
        '& .MuiTextField-root': { width: '100%' },
      }}>
        <DesktopDatePicker
          slotProps={{ textField: { size: 'small' } }}
          size="small"
          {...props}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default CustomDatePicker;