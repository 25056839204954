import React, { useEffect, useState } from 'react';
import { collect } from 'react-recollect';

const Timer = ({ dueDate, handleCountdownEnds }) => {
  let interval;
  const timerDueDate = new Date(dueDate)
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    interval = setInterval(() => {
      const calculatedTimeLeft = calculateTimeLeft();
      if (calculatedTimeLeft.difference_secs > 0) {
        setTimeLeft(calculatedTimeLeft);
      } else {
        clearInterval(interval);
        handleCountdownEnds();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const difference = timerDueDate - now;
    if (difference <= 1000) {
      return { hours: 0, minutes: 0, seconds: 0, difference_secs: 0 };
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    let difference_secs = difference / 1000
    return { hours, minutes, seconds, difference_secs };
  }

  return (
    <span> {`${timeLeft.hours.toString().padStart(2, '0')}:${timeLeft.minutes.toString().padStart(2, '0')}:${timeLeft.seconds.toString().padStart(2, '0')}`}</span>
  )
}

export default collect(Timer);
