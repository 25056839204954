import React, { useState } from "react";
import { collect } from "react-recollect";
import { Progress, Card, CardHeader, CardBody, Collapse, Button, CardFooter } from 'reactstrap'
import { NameFilter, LocationsFilter, StatusFilter, StartDateFilter, EndDateFilter, FacilityTypeFilter, DateTypeFilter, UserEmailFilter, PhysicianFilter, CustomerFilter, RecallPtNotesFilter, RecallPtNotesSearchFilter, RegionsFilter, PatientAssignedToFilter, InvitationModeFilter, WasRefitFilter } from "./FilterComponent";
import { languageOptions } from "../language";
import { getLangType } from "../utils/utils";
import { appStore } from "../store/store";
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const Filters = ({ store, tab }) => {
	const langType = getLangType();
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [isFilteringEnable, setIsFilteringEnable] = useState(true);

	const { isStaff, customerSubdomain, isIntervention } = store.currentUser

	const handleFilterClick = () => {
		setIsFilterOpen(!isFilterOpen)
	}

	const handleNewFilters = () => {
		
		// dates out of range validation
		if ( dayjs(store.filters.startDate).isBefore('2000-01-01','year') || dayjs(store.filters.startDate).isAfter('2050-01-01','year') ) {
			store.filters.startDate = null;
			toast.error("The Start Date is outside the allowed range. Please try again.");
			return;
		}
		if ( dayjs(store.filters.endDate).isBefore('2000-01-01','year') || dayjs(store.filters.endDate).isAfter('2050-01-01','year') ) {
			store.filters.endDate = null;
			toast.error("The End Date is outside the allowed range. Please try again.");
			return;
		}
		
		console.log("New filters applied")
		appStore.applyFilters();
		appStore.loadPatients();
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleNewFilters();
		}
	}

	return (
		<Card>
			<CardHeader className="card-header-custom" onClick={handleFilterClick}>
				<h5 className="card-title-custom" >Filter</h5>
				<button type="button" className="btn btn-tool" >
					{isFilterOpen ?
						<i style={{ color: 'var(--color-primary)' }} className="fas fa-caret-up"></i> :
						<i style={{ color: 'var(--color-primary)' }} className="fas fa-caret-down"></i>}
				</button>
			</CardHeader>
			<Collapse isOpen={isFilterOpen}>
				<CardBody className="filter-box">
					<span className="filter-element">
						<NameFilter
							name='nameSearch'
							label={languageOptions.search[langType] + ' By'}
							placeholder='Patient ID, Name, Email or Phone'
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<LocationsFilter
							label={languageOptions.filterLocation[langType]}
							subdomainLocations={store.currentSubdomain.subdomainLocations}
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					{(isStaff || isIntervention) &&
						store.currentSubdomain.isParent &&
						store.currentSubdomain.regions.length > 0 &&
						<span className="filter-element">
							<RegionsFilter 
								label='Search By Region' 
								onKeyDown={handleKeyPress}
							/>
						</span>}
					<span className="filter-element">
						<StatusFilter
							label={languageOptions.filterStatus[langType]}
							statuses={store.statuses}
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<UserEmailFilter
							name='userSearch'
							label='User'
							placeholder={languageOptions.searchClinicianEmail[langType]}
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<FacilityTypeFilter
							label={languageOptions.facilityType[langType]}
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<StartDateFilter
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<EndDateFilter
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<DateTypeFilter
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<PhysicianFilter
							label={"Search by Physician"}
							placeholder={"Enter Physician NPI Number or Name"}
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					{isStaff &&
						<span className="filter-element">
							<RecallPtNotesFilter
								label={"Has Intervention Notes"}
								type={tab}
								onKeyDown={handleKeyPress}
							/>
						</span>}
					{isStaff &&
						<span className="filter-element">
							<RecallPtNotesSearchFilter
								label={'Search By Intervention Note'}
								placeholder={'Enter a part of intervention note'}
								onKeyDown={handleKeyPress}
							/>
						</span>}

					{isStaff && customerSubdomain === 'admin' &&
						<span className="filter-element">
							<CustomerFilter
								label={"Search by Customer"}
								onKeyDown={handleKeyPress}
							/>
						</span>}
					<span className="filter-element">
						<PatientAssignedToFilter
							label='Assigned Clinician'
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<InvitationModeFilter
							label="Invitation Mode"
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
					<span className="filter-element">
						<WasRefitFilter
							label='Was Refit'
							type={tab}
							onKeyDown={handleKeyPress}
						/>
					</span>
				</CardBody>
				<CardFooter style={{ backgroundColor: 'inherit' }}>
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
						<Button
							style={{ marginRight: '0.5rem' }}
							color='secondary'
							size='sm'
							onClick={() => {
								appStore.resetFilters();
								appStore.loadPatients();
							}}>
							{languageOptions.clear[langType]}
						</Button>
						<Button
							color='primary'
							size='large'
							disabled={!isFilteringEnable}
							onClick={handleNewFilters}>
							{languageOptions.applyFilters[langType]}
						</Button>
					</div>
				</CardFooter>
			</Collapse>
		</Card>
	)
}

export default collect(Filters);
