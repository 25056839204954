import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import {
  CardBody, Card, TabContent, Nav, NavItem, NavLink as NavLinkRS, TabPane
} from 'reactstrap';
import { debounce } from 'lodash';
import { collect } from 'react-recollect';
import { appStore } from '../store/store';
import DataTable, { createTheme } from 'react-data-table-component';
import "./Alert.scss";
import Filter from '../components/Filter';
import { dataTableThemeConfig } from '../utils/utils';
import PatientDetailsModal from '../components/PatientDetailsModal'
import { PatientAlertsRowComponent } from '../components/common/PatientTableRowComponent';
import Select from "react-select";
import { Tooltip } from 'react-tooltip';
import { languageOptions } from '../language';
import { getLangType } from '../utils/utils';
import uuid from 'react-uuid';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import axios from 'axios';
import dayjs from 'dayjs';

createTheme('blueish', dataTableThemeConfig, 'dark');
const langType = getLangType();

const statuses = [
  { value: "Resolved", label: "Resolved", name: "status" },
  { value: "Acknowledged", label: "Acknowledged", name: "status" },
]
const alerts = {
  "did_receive_pap": "Device NOT Received",
  "did_switch_mask": "Switch Mask",
  "had_new_symptoms": "New Symptoms",
  "did_switch_pressure": "Switch Pressure",
  "hour_spent_estimate": "Hours per Night",
  "night_spent_estimate": "Night per Week",
  "had_unscheduled_visit": "Unschedule Appointment with Doctor"
}

const alertOptions = Object.keys(alerts).map(key => ({ value: key, label: alerts[key], name: "alertType" }));

export const alert_label = (answers) => {
  return Object.keys(alerts)
    .filter(key => Object.keys(answers).includes(key))
    .map(key => alerts[key]);
}

const Alert = ({ store, history }) => {
  // const [showAssignConfModal, setShowAssignConfModal] = useState(false);
  // const [alertId, setAlertId] = useState('')
  // const [assignedEmail, setAssignedEmail] = useState('')
  const [activeTab, setActiveTab] = useState("all")
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const userOptions = store.users ? store.users.map(({ username }) => ({ label: username, value: username, name: "createdBy" })) : [];
  const userResolvedBy = store.users ? store.users.map(({ username }) => ({ label: username, value: username, name: "resolvedBy" })) : [];

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (!store.users?.length) appStore.loadUsers({ is_active: true }, cancelToken)
    appStore.resetAlertFilters();
    appStore.getSettings(cancelToken);
    appStore.loadAlerts(true, cancelToken);
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      cancelToken.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const handleTabClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      appStore.resetAlertFilters();
      if (tab != 'all') store.alertFilters.status = [tab];
      appStore.loadAlerts(true);
    }
  }

  const handleChange = (event, name) => {
    if (event) {
      if (event.target) {
        store.alertFilters[event.target.name] = event.target.value;
      } else if (event.name) {
        store.alertFilters[event.name] = event.value;
      } else if (Array.isArray(event)) {
        if (name === 'locations') {
          const values = event.map(e => e.value[1]);
          store.alertFilters[name] = values
        }
        else {
          const values = event.map(e => e.value);
          store.alertFilters[event[0].name] = values
        }
      }
    } else {
      appStore.resetAnAlertFilter(name);
    }
    appStore.loadAlerts(true);
  }

  let debouncedFn = null;
  const handleSearchChange = (e) => {
    e.persist();
    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        store.alertFilters.patientId = e.target.value;
        appStore.loadAlerts();
      }, 1000);
    }
    debouncedFn();
  };

  const onChangeEndDate = (date) => {
    if (date && (date.format('YYYY-MM-DD') !== 'Invalid Date')) {
      store.alertFilters.endDate = date.toDate();
    } else {
      store.alertFilters.endDate = null;
    }
    appStore.loadAlerts(true);
  };

  const onChangeStartDate = (date) => {
    if (date && (date.format('YYYY-MM-DD') !== 'Invalid Date')) {
      store.alertFilters.startDate = date.toDate();
    } else {
      store.alertFilters.startDate = null;
    }
    appStore.loadAlerts(true);
  };

  const components = [
    {
      type: "input",
      label: "Search",
      renderProps: {
        autoComplete: 'new-password',
        type: 'patientId',
        name: 'patientId',
        id: 'patientId',
        onChange: handleSearchChange,
        placeholder: "Search By Patient ID or Name",
      },
    },
    {
      type: "select",
      label: "Alert Status",
      renderProps: {
        name: 'status',
        id: 'alert_statuses',
        isClearable: true,
        isMulti: true,
        onChange: (e) => handleChange(e, 'status'),
        options: statuses,
        isSearchable: "true"
      }
    },
    {
      type: "select",
      label: "Assigned to",
      renderProps: {
        name: 'assignedTo',
        id: 'alert_assignedTo',
        isMulti: true,
        isClearable: true,
        defaultValue: null,
        onChange: (e) => handleChange(e, 'assignedTo'),
        options: appStore.setAssigningOptions(),
        isSearchable: "true"
      }
    },
    {
      type: "select",
      label: "Alert Resolved by",
      renderProps: {
        name: 'resolvedBy',
        id: 'alert_resolvedBy',
        isMulti: true,
        isClearable: true,
        defaultValue: store.alertFilters.resolvedBy,
        onChange: (e) => handleChange(e, 'resolvedBy'),
        options: userResolvedBy,
        isSearchable: "true"
      }
    },
    {
      type: "select",
      label: "Location",
      renderProps: {
        name: 'locations',
        id: 'alert_location',
        isMulti: true,
        isClearable: true,
        defaultValue: null,
        onChange: (e) => handleChange(e, 'locations'),
        options: store.locationOptionGroups,
        isSearchable: "true"
      }
    },
    {
      type: "select",
      label: "Alert Type",
      renderProps: {
        name: 'alertType',
        id: 'alert_types',
        isMulti: true,
        isClearable: true,
        defaultValue: '',
        onChange: (e) => handleChange(e, 'alertType'),
        options: alertOptions,
        isSearchable: "true"
      }
    },
    {
      type: "select",
      label: "Patient Created or Completed By",
      renderProps: {
        name: 'createdBy',
        id: 'createdBy',
        defaultValue: store.alertFilters.createdBy,
        isMulti: true,
        isClearable: true,
        onChange: (e) => handleChange(e, 'createdBy'),
        options: userOptions,
        isSearchable: "true"
      }
    },
    {
      type: "datePicker",
      label: "Start Date",
      renderProps: {
        name: "createdAt",
        id: 'startDate',
        onChange: onChangeStartDate,
        maxDate: dayjs(store.alertFilters.endDate)
      },
    },
    {
      type: "datePicker",
      label: "End Date",
      renderProps: {
        name: "resolvedAt",
        id: 'endDate',
        onChange: onChangeEndDate,
        minDate: dayjs(store.alertFilters.startDate),
      },
    }
  ]

  const columns = [
    {
      name: 'Patient ID',
      selector: row => row.patient_id,
      sortable: true,
      cell: row => <span>{row.patient_id}</span>
    },
    {
      name: 'Name',
      selector: row => `${row.last_name}, ${row.first_name}`,
      sortable: true,
      cell: (row) => (
        <>
          <Tooltip id="ptName"><span>Click to view Patient Details</span></Tooltip>
          <span data-tip data-tooltip-id='ptName'>
            <a
              className='link-primary'
              onClick={() => {
                appStore.showPatientDetails(row)
                appStore.loadPatientAlerts(row.website_token)
              }}
            > {row.last_name}, {row.first_name}
            </a>
          </span>
        </>
      )
    },
    {
      name: 'Type',
      selector: row => row.alert_type,
      sortable: true,
      cell: (row) => {
        const alrts = alert_label(row.alert_type)
        return <div className="AlertTypeContainer">
          {row.expend ?
            alrts.map((label) => <span key={uuid()}>{label}</span>)
            : alrts[0]}
        </div>
      }
    },
    {
      name: 'Status',
      selector: row => row.alert_status,
      sortable: true,
      minWidth: '180px',
      cell: (row) => (
        <div className='msgStatus'>
          {
            row.alert_status === "Acknowledged" ?
              <div>📌 Acknowledged by {row.resolved_by} {dayjs(row.resolved_at).format('MM/DD/YYYY - hh:mm a')}</div> :
              row.alert_status === "Resolved" ?
                <div className="ResolvedStatus">✅ Resolved by {row.resolved_by} {dayjs(row.resolved_at).format('MM/DD/YYYY - hh:mm a')}</div> : "New"
          }
        </div>
      )
    },
    {
      name: "Assigned To",
      cell: (row) => {
        const defaultValue = userOptions.filter((opt) => opt.value === row.assigned_to)
        return !row.is_resolved &&
          <div style={{ width: '300px' }}>
            <Select
              value={defaultValue}
              onChange={(e) => {
                // setShowAssignConfModal(true)
                // setAlertId(row.alert_id)
                // setAssignedEmail(e.value)
                appStore.resolveAlert({
                  'alert_id': row.alert_id,
                  'assigned_to': e.value, 'assigning': true
                })
              }}
              menuPortalTarget={document.body}
              style={{ width: '290px' }}
              isSearchable
              options={appStore.setAssigningOptions()}
              defaultValue={defaultValue} /></div>
      }
    },
    {
      name: 'Location',
      selector: row => row.location,
      sortable: true,
      cell: row => <span>{row.location}</span>
    },
    {
      name: 'Date Created',
      selector: row => row.created_at,
      cell: (row) => {
        const date = dayjs(row.created_at).format('MM/DD/YYYY - hh:mm a')
        return (
          <div className="AlertTypeContainer">
            {row.expend ?
              Object.keys(row.alert_type).map(() => <span>{date}</span>)
              : date}
          </div>
        )
      },
      sortable: true,
      compact: true,
      center: true

    },
    {
      width: "50px",
      cell: (row) => {
        return <Button
          type="text"
          disabled={Object.keys(row.alert_type).length === 1}
          onClick={() => appStore.expendAlertRow(row)}>{row.expend ? <i className="fas fa-sort-up CaretDown"></i> : <i className="fas fa-sort-down CaretDown"></i>}
        </Button>
      },
      compact: true,
    }
  ];

  const handleResize = () => {
    const { clientWidth } = document.documentElement
    if (clientWidth < 959 && clientWidth > 599) setIsTablet(true);
    else setIsTablet(false);
    if (clientWidth <= 599) setIsMobile(true);
    else setIsMobile(false);
  }

  const alertRowComponent = (props) => <PatientAlertsRowComponent {...props} isMobile={isMobile} isTablet={isTablet} userOptions={userOptions} />

  return (
    <>
      <div className='px-sm-1 px-xl-3'>
        <h1 className='fs-5 mt-0'>Manage Users</h1>
        <Filter components={components} />
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLinkRS
                  className={classnames({ active: activeTab === "all" })}
                  onClick={() => handleTabClick('all')}
                >
                  All
                </NavLinkRS>
              </NavItem>
              <NavItem>
                <NavLinkRS
                  className={classnames({ active: activeTab === "New" })}
                  onClick={() => handleTabClick("New")}
                >
                  New
                </NavLinkRS>
              </NavItem>
              <NavItem>
                <NavLinkRS
                  className={classnames({ active: activeTab === "Acknowledged" })}
                  onClick={() => handleTabClick("Acknowledged")}
                >
                  Pending
                </NavLinkRS>
              </NavItem>
              <NavItem>
                <NavLinkRS
                  className={classnames({ active: activeTab === "Resolved" })}
                  onClick={() => handleTabClick("Resolved")}
                >
                  Resolved
                </NavLinkRS>
              </NavItem>
            </Nav>
            <TabContent activeTab="alertTable">
              <TabPane tabId="alertTable">
                <DataTable
                  responsive
                  data={store.alerts}
                  columns={isMobile ? [columns[1], columns[6]] : isTablet ? [columns[1], columns[5], columns[6]] : columns}
                  expandableRows={isMobile || isTablet}
                  expandOnRowClicked={isMobile || isTablet}
                  expandableRowsComponent={alertRowComponent}
                  noHeader
                  pagination
                  progressPending={store.alertsLoading}
                  progressComponent='Loading alerts...'
                  theme="blueish"
                  striped={true}
                  paginationPerPage={50}
                  paginationServer={true}
                  paginationTotalRows={store.alertsTotalRows}
                  onChangePage={(pageNumber) => {
                    store.alertsPageNumber = pageNumber;
                    appStore.loadAlerts(true);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                />
                {store.alerts.length > 0 && <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <a
                    href={appStore.downloadAlertAsCSVLink()}>
                    <i
                      className='fas fa-file-excel'
                      style={{ color: 'green', paddingRight: '3px' }}
                    />
                    {languageOptions.ptDownloadCSV[langType]}
                  </a>
                </div>}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
        <PatientDetailsModal activeTab="alerts" />
        {/* <Modal
        isOpen={showAssignConfModal}
        centered
        toggle={() => setShowAssignConfModal(!showAssignConfModal)}
      >
        <ModalBody>
          <h6>Are you sure you want to assign the alert and send a notification email to this user?</h6>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-end'>
          <Button
            onClick={() => {
              setShowAssignConfModal(false)
            }}>
            {languageOptions.cancel[langType]}
          </Button>
          <Button
            onClick={(e) => {
              appStore.resolveAlert({
                'alert_id': alertId,
                'assigned_to': assignedEmail, 'assigning': true
              })
              setShowAssignConfModal(false)
            }}
          >
            {languageOptions.save[langType]}
          </Button>
        </ModalFooter>
      </Modal> */}
      </div>
    </>
  );
}

export default collect(withRouter(Alert));
