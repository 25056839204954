import React, { useState } from "react";
import Select from "react-select";
import { languageOptions } from '../language';
import { collect } from "react-recollect";
import { requiredLabel, getLangType, displayFormError, getCushionSizesByName } from '../utils/utils';
import { getMaskOptionGroupsWithOther } from './NewPatientForm';
import { Input } from "reactstrap";

const ScriptedMaskForm = ({ store, handleMaskType, handleCushionSize, handleFrameSize, scriptedErrors, selectedMaskName }) => {

  const langType = getLangType();
  const [showMaskOther, setShowMaskOther] = useState(false);
  const [cushionValue, setCushionValue] = useState('');
  const [frameValue, setFrameValue] = useState('');
  const maskOptions = getMaskOptionGroupsWithOther();
  const errors = { ...scriptedErrors };

  return (
    <>
      <div className='mb-3'>
        {languageOptions.ptMaskPrescribed[langType]} {requiredLabel()}
        <Select
          options={maskOptions}
          placeholder={languageOptions.select[langType]}
          onChange={(e) => {
            if (e.value === 'Other') {
              setShowMaskOther(true);
              handleMaskType(e.value, true);
            } else { 
              setShowMaskOther(false); 
              handleMaskType(e.value);
            }
            handleCushionSize();
            setCushionValue('Select...');
            setFrameValue('');
            handleFrameSize();
          }}
        />
        {errors.mask && displayFormError('Prescribed Mask is required for scripted patients.')}
      </div>
      { showMaskOther && (
        <div className='mb-2'>
          <span>Other mask {requiredLabel()}</span>
          <Input
            type='text'
            placeholder='Enter the mask name...'
            onChange={(e) => {
              handleMaskType(e.target.value, true);
            }}
          />
          {errors.mask_other && displayFormError('A name for mask type Other is required for scripted patients.')}
        </div>
        )}
      
      <div className='mb-3'>
        {languageOptions.ptCushionsizePrescribed[langType]}
        <Select
          placeholder={!selectedMaskName?
            'Select... (Options are available after selecting mask)' : 'Select...'}
          value={cushionValue}
          options={getCushionSizesByName(selectedMaskName)}
          isClearable
          onChange={(e) => {
            handleCushionSize(e);
            setCushionValue(e);
          }}
        />
        {errors.cushionSize && displayFormError('Prescribed Mask Cushion Size is required for scripted patients.')}
      </div>
      <div className='mb-3'>
        {languageOptions.ptFrameSizePrescribed[langType]}
        <Select
          placeholder={languageOptions.select[langType]}
          value={frameValue}
          options={[
            { value: 'Small', label: 'Small' },
            { value: 'Medium', label: 'Medium' },
            { value: 'Large', label: 'Large' },
            { value: 'Standard', label: 'Standard' }
          ]}
          isClearable
          onChange={(e) => {
            handleFrameSize(e);
            setFrameValue(e);
          }}
        />
        {errors.frameSize && displayFormError('Prescribed Mask Frame Size is required for scripted patients.')}
      </div>
    </>
  )
}

export default collect(ScriptedMaskForm);
