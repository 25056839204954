import React from 'react';
import { Alert } from 'reactstrap';
import { collect } from 'react-recollect';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Timer from './Timer';

const AlertNotification = ({notification, handleCountdownEnds}) => {
    return (
      <Alert color='danger' className='d-flex align-items-center gap-2 mx-3 my-2' >
        <ReportGmailerrorredIcon />
        <div>
          <span>{notification?.customMessage}</span>
          {notification?.showCountdown && <Timer dueDate={notification?.dueDate} handleCountdownEnds={handleCountdownEnds}/>}
        </div>
      </Alert>
    );
  };

  export default collect(AlertNotification);