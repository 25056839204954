// FlagCheckbox.js
import React from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import ToolTip from './ToolTip';

const ReactHealthCheckBox = ({ checked, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={
          <span style={{ fontSize: '14px', color: 'var(--color-blue)' }}>
            Submit patient to React-Connect on complete
            <ToolTip
              id='reactHealthTooltip'
              content='Integrate with React Health on complete'
              type='info'
            />
          </span>
        }
      />
    </FormGroup>
  );
};

export default ReactHealthCheckBox;