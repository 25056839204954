import { LogLevel } from "@azure/msal-browser";

/**
   * Configuration object to be passed to MSAL instance on creation. 
   * For a full list of MSAL.js configuration parameters, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
  auth: {
    clientId: '2607076e-0e83-4eac-895f-8651a670d3a9', // SleepGlad
    // authority: 'https://login.microsoftonline.com/98176d85-0697-45e3-b445-e1fd9ddcf5b7', // SG - single-tenant
    authority: 'https://login.microsoftonline.com/organizations', // SG - multi-tenant
    redirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default: return;
        }
      }
    }
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
export const loginRequest = {
  scopes: ["User.Read"]  // GraphAPI scope
};

export const AppApiTokenRequest = {
  scopes: ["api://2607076e-0e83-4eac-895f-8651a670d3a9/user.read"]  // SG - Scope for the registered App in AZ
}

/**
  * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
*/
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};