import React from 'react';

const CustomPagination = ({ totalPages, currentPage, onPageChange, patientsTotalRows, itemsPerPage }) => {
  const maxButtonsToShow = 5;
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  const getVisiblePages = () => {
    if (totalPages <= maxButtonsToShow) {
      return pages;
    }
    const halfMaxButtons = Math.floor(maxButtonsToShow / 2);
    if (currentPage <= halfMaxButtons) {
      return pages.slice(0, maxButtonsToShow);
    }
    if (currentPage >= totalPages - halfMaxButtons) {
      return pages.slice(totalPages - maxButtonsToShow);
    }
    return pages.slice(
      currentPage - halfMaxButtons - 1,
      currentPage + halfMaxButtons
    );
  };

  const paginationButtonStyle = {
    border: '1px solid #ddd',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    transition: 'background-color 0.3s',
    width: '2.5rem',
    height: '2.5rem', 
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  };

  const paginationStyle = {
    margin: '1px 5px',
    padding: '8px 12px',
    fontSize: '0.7rem',
    color: '#808080'
  };

  const startRow = (currentPage - 1) * itemsPerPage + 1;
  const endRow = Math.min(currentPage * itemsPerPage, patientsTotalRows);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '10px'}}>
      <div style={{...paginationStyle}}>
        <span>{startRow}-{endRow}</span> of <span>{patientsTotalRows}</span>
      </div>
      <div>
        <button
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
          style={{
            ...paginationButtonStyle, ...paginationStyle,
            color: 'inherit'
          }}
        >
          {'<<'}
        </button>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            ...paginationButtonStyle, ...paginationStyle,
            color: 'inherit'
          }}
        >
          {'<'}
        </button>
        {getVisiblePages().map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            style={{
              ...paginationButtonStyle, ...paginationStyle,
              backgroundColor: currentPage === page ? '#007bff' : '#fff',
              color: currentPage === page ? '#fff' : 'inherit',
            }}
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          style={{
            ...paginationButtonStyle, ...paginationStyle,
            color: 'inherit'
          }}
        >
          {'>'}
        </button>
        <button
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
          style={{
            ...paginationButtonStyle, ...paginationStyle,
            color: 'inherit'
          }}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;