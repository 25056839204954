import React, { useState, useRef } from 'react';
import { collect } from 'react-recollect'
import { appStore } from '../store/store';
import { Form, Input, Button, Row, Col, FormFeedback, Alert, Label } from 'reactstrap';
import { Button as ButtonAntd } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import Select from "react-select";
import { displayFormError } from '../utils/utils';
import ReCAPTCHA from "react-google-recaptcha";
import { useQuery } from 'react-query';
import keyData from '../../src/credentials.json'
import CustomDatePicker from '../components/CustomDatePicker';
import { DropdownIndicator } from '../components/BranchSelector';
import PatientSideMainComponent from './PatientSideMainComponent';
import PatientFooter from '../components/common/PatientFooter';
import dayjs from 'dayjs';
import Loading from '../components/Loading';

const sitekeyTest = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

const PatientOnboardingForm = ({ store, kiosk }) => {
  const initialPtData = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    phone: '',
    inviteChannel: kiosk ? null : 'invite_text'
  }
  const [patientData, setPatientData] = useState(initialPtData)
  const [errors, setErrors] = useState({
    invalidNumber: false,
    notMobileNumber: false
  })
  const [dobError, setDobError] = useState(false)
  const [scanUrlError, setScanUrlError] = useState(false)
  const [reCaptcha, setReCaptcha] = useState('')
  const [branch, setBranch] = useState('')
  const [subdomain, setSubdomain] = useState('')

  const recaptchaRef = useRef()
  const resetRecaptcha = () => recaptchaRef.current.reset()

  const { data: subdomainInfo, isFetching } = useQuery(
    'subdomainInfo',
    appStore.getCurrentSubdomainName,
    { refetchOnWindowFocus: false }
  )

  const handleRecaptchaChange = (value) => setReCaptcha(value)

  const handleInputChange = (e) => {
    setPatientData({ ...patientData, [e.target.name]: e.target.value })
  }

  const handleInviteChannelChange = (e) => setPatientData({ ...patientData, inviteChannel: e.value })

  const inviteChannelOptions = [
    { label: 'Send Text Message (Recommended)', value: 'invite_text' },
    { label: 'Send Email', value: 'invite_email' }
  ]

  const isEmptyFieldError = () => {
    const { firstName, lastName, dateOfBirth, email, phone, inviteChannel } = patientData
    return !firstName || !lastName || !dateOfBirth || !email || !phone || (!kiosk ? !inviteChannel : false)
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    let errorsObj = {}
    const data = await appStore.twilioLookupPhone(patientData.phone, true)
    if (data) {
      const { numberLookupStatus, numberLookupCode, numberIsSMSReachable } = data
      if (numberLookupStatus != 200) {
        setErrors({ ...errors, invalidNumber: true })
        errorsObj.invalidNumber = true
      } else {
        setErrors({ ...errors, invalidNumber: false })
        errorsObj.invalidNumber = false
      }
      if (!numberIsSMSReachable) {
        setErrors({ ...errors, notMobileNumber: true })
        errorsObj.notMobileNumber = true
      } else {
        setErrors({ ...errors, notMobileNumber: false })
        errorsObj.notMobileNumber = false
      }
    }

    //if patient dateOfBirth is null, before 1900-01-01 or after today, we set error.
    const dob = dayjs(patientData.dateOfBirth)
    if (
      !dob ||
      !dob.isValid() ||
      dob.isBefore('1900-01-01') ||
      dob.isAfter(dayjs())
    ) {
      setDobError(true)
      errorsObj.dobError = true
    } else {
      setDobError(false)
      errorsObj.dobError = false
    }

    const recaptchaVerification = await appStore.verifyRecaptcha(reCaptcha)
    if (
      !isEmptyFieldError() &&
      !errorsObj.invalidNumber &&
      !errorsObj.notMobileNumber &&
      !errorsObj.dobError &&
      recaptchaVerification?.success
    ) {
      let data = patientData
      if (branch) data.location = branch
      if (subdomain) data.subdomain = subdomain
      appStore.onboardPatient(data)
    }
  }

  const reset = () => {
    setPatientData(initialPtData)
    store.onboardPatientSuccess = false
    setErrors({ invalidNumber: false, notMobileNumber: false })
  }

  // Allow only dev env, staging and maskfittings for self onboard page.
  const url = window.location.href
  if (!kiosk &&
    (process.env.NODE_ENV !== 'development') &&
    (!url.includes('maskfittings') && !url.includes('staging'))
  ) {
    return <p>Not authorized.</p>
  }

  if (kiosk && !localStorage.locationOptionGroups) {
    return (
      <div className='d-flex justify-content-center'>
        <Alert color="info" style={{ maxWidth: '40rem' }} className='mt-5'>
          Please note that you need to first login and then use the designated "Kiosk Mode" button on the main page. Thank you.
        </Alert>
      </div>
    )
  }

  return (
    <>
      {!store.onboardPatientSuccess ? <div
        stlye={{ color: 'var(--color-primary' }}
        className={`${store.twilioLookupLoading ||
          store.onboardPatientLoading ||
          store.verifyRecaptchaLoading ?
          'processing-loading' : ''
          }`}
      >
        <PatientSideMainComponent />

        {isFetching && <Loading />}

        {kiosk && !branch && subdomainInfo?.kioskModeEnabled && !isFetching &&
          <div className='d-flex justify-content-center mt-5'>
            <div style={{ width: '20rem' }}>
              <Label>Please select the branch:</Label>
              <Select
                isClearable
                isSearchable
                components={{ DropdownIndicator }}
                options={JSON.parse(localStorage.locationOptionGroups)}
                onChange={(e) => {
                  setBranch(e.value[1])
                  setSubdomain(e.value[0])
                }}
              />
            </div>
          </div>
        }

        {store.existingPtError &&
          <Row>
            <Col className='d-flex justify-content-center'>
              <Alert color="danger" style={{ maxWidth: '55rem' }} className='mb-1 mt-3'>
                It appears you already exist in the system. Please reach out to your local branch.
              </Alert>
            </Col>
          </Row>}

        {/* If path is "kiosk", render the form only after branch is selected
        If path is "scan", then there is no branch selection */}

        {(
          (!isFetching) &&
          (kiosk && branch && subdomainInfo?.kioskModeEnabled) ||
          (!kiosk && !branch && subdomainInfo?.selfOnboardModeEnabled)
        ) ?
          <div>
            <h4 className='text-center mt-3 mb-3' style={{ padding: '0', color: 'var(--color-primary' }}>Sleep Therapy Setup</h4>
            <div className='d-flex justify-content-center'>
              <Form style={{ maxWidth: '600px' }} onSubmit={onFormSubmit}>
                <Row>
                  <Col>
                    <p
                      className='font-weight-light m-0 mb-2 p-0'
                      style={{ lineHeight: 1.1, fontSize: '0.8rem', color: 'var(--color-primary' }}>
                      This step is critical for ensuring the proper mask is <br /> ready for your therapy.
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '0.75rem' }}>
                  <Col>
                    <Input
                      required
                      style={{ width: '20rem' }}
                      type='text'
                      id='firstName'
                      name='firstName'
                      placeholder='First Name'
                      value={patientData.firstName}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '0.75rem' }}>
                  <Col>
                    <Input
                      required
                      style={{ width: '20rem' }}
                      type='text'
                      id='lastName'
                      name='lastName'
                      placeholder='Last Name'
                      value={patientData.lastName}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '0.75rem' }}>
                  <Col>
                    <div stlye={{ color: 'var(--color-primary' }}>Date Of Birth:</div>
                    <div style={{ width: '20rem' }}>
                      <CustomDatePicker
                        slotProps={{ textField: { size: 'small', error: dobError } }}
                        maxDate={dayjs()} //current date.
                        minDate={dayjs('1900-01-01')}
                        onChange={(date) => {
                          setPatientData(prev => ({
                            ...prev,
                            dateOfBirth: date ? dayjs(date).format('MM/DD/YYYY') : ''
                          }))
                        }}
                      />
                      {dobError &&
                        <div>{displayFormError('Please enter a valid date of birth!')}</div>}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '0.75rem' }}>
                  <Col>
                    <Input
                      required
                      style={{ width: '20rem' }}
                      type='email'
                      id='email'
                      name='email'
                      placeholder='Email'
                      value={patientData.email}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '0.75rem' }}>
                  <Col>
                    <Input
                      required
                      invalid={errors.invalidNumber || errors.notMobileNumber}
                      style={{ width: '20rem' }}
                      type='tel'
                      id='phone'
                      name='phone'
                      placeholder='Phone Number'
                      value={patientData.phone}
                      onChange={handleInputChange}
                    />
                    <FormFeedback>
                      Error: {errors.invalidNumber ? 'Invalid number!' : errors.notMobileNumber ? 'This is not a mobile number!' : 'Incorrect or empty number!'}
                    </FormFeedback>
                  </Col>
                </Row>
                {!kiosk &&
                  <Row style={{ marginBottom: '1.1rem' }}>
                    <Col>
                      <div stlye={{ color: 'var(--color-primary' }}>Message Delivery:</div>
                      <div style={{ width: '20rem' }}>
                        <Select
                          defaultValue={inviteChannelOptions[0]}
                          id='inviteChannel'
                          name='inviteChannel'
                          placeholder='Invite Channel'
                          options={inviteChannelOptions}
                          onChange={handleInviteChannelChange}
                        />
                        <small
                          id='inviteChannel'
                          className='form-text text-muted font-italic m-0'
                          style={{ fontSize: '13px' }}
                        >
                          Text messages will only be sent 8:00am to 8:00pm.
                        </small>
                      </div>
                    </Col>
                  </Row>
                }
                <div className='d-flex flex-column'>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={
                      process.env.NODE_ENV == 'development' ?
                        sitekeyTest : keyData.RECAPTCHA_SITE_KEY
                    }
                    onChange={handleRecaptchaChange}
                  />
                  <ButtonAntd
                    className='d-flex alignt-items-center justify-content-center'
                    icon={<ReloadOutlined style={{ padding: '2.5px' }} />}
                    type='primary'
                    ghost
                    onClick={resetRecaptcha}
                  >
                    RESET reCAPTCHA
                  </ButtonAntd>
                </div>
                <p
                  className='d-flex flex-column mt-3'
                  style={{ color: 'red', lineHeight: 1.1, fontSize: '0.8rem' }}>
                  By clicking submit, you consent to receiving <br />
                  messages from {subdomainInfo?.customerFormattedName}.
                </p>
                <Button
                  className='mt-3'
                  color='primary'
                  size='sm'
                  type='submit'
                  disabled={store.twilioLookupLoading ||
                    store.onboardPatientLoading ||
                    store.verifyRecaptchaLoading ||
                    !reCaptcha}
                  block
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div> :
          (!isFetching) &&
          (
            (kiosk && !subdomainInfo?.kioskModeEnabled) ||
            (!kiosk && !subdomainInfo?.selfOnboardModeEnabled)
          ) && (
            <div className='d-flex justify-content-center'>
              <Alert color="danger" style={{ maxWidth: '40rem' }} className='mt-5'>
                This feature is not enabled for {subdomainInfo?.customerFormattedName}.
              </Alert>
            </div>
          )
        }
      </div> :
        <div>
          <h4 className='text-center p-5 mb-2' stlye={{ color: 'var(--color-primary' }}>Thank YOU!</h4>
          <div className='d-flex justify-content-center mx-2' >
            <div className='d-flex flex-column align-items-center' style={{ maxWidth: '400px' }}>
              {!kiosk &&
                <p>
                  Click the button below to start your mask fitting with a brief questionnaire and facial scan.
                </p>}
              <p>
                This step is critical for ensuring the proper mask is ready for your therapy. We do not take a photo, and the secure process will only capture information for your mask recommendation.
              </p>
              <div>
                <Button
                  color='primary'
                  size='large'
                  className='d-flex align-items-center justify-content-center gap-1'
                  onClick={() => {
                    setScanUrlError(false)
                    if (store.onboardPatientURL) {
                      window.open(store.onboardPatientURL)
                      reset()
                    }
                    else setScanUrlError(true)
                  }}>
                  <i className='fas fa-camera' />
                  Scan Now
                </Button>
                {scanUrlError && !store.onboardPatientLoading &&
                  <div>{displayFormError('Error: Scan URL cannot be found!')}</div>}
              </div>
              {!kiosk && <p style={{ color: 'grey', fontSize: '14px', marginTop: '1rem' }}>
                If you elect not to proceed right now, you will receive an invitation by text or email within a few minutes.
              </p>}
            </div>
          </div>
        </div>}
      <PatientFooter />
    </>
  )
}

export default collect(PatientOnboardingForm)